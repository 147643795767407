import axios from "axios";
import Config from "./../../axios/config";
import { logErrorToGrayLogs } from "./GrayLogActions";

const { REST_API } = Config;
export const RegisterProfile = (filters) => (dispatch) => {
  return axios
    .post(REST_API.AppActions.RegisterProfile, filters, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (!baseModel.success) {
        logErrorToGrayLogs(
          `function: RegisterProfile, platform: Web, client error msg: ${response.message},Enter Details are ${filters} `
        );
      }
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: RegisterProfile, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const getUserProfileById = (userid) => (dispatch) => {
  return axios
    .get(
      `${REST_API.ProfileActions.getProfile}?userId=${userid}`,
      { headers: { "Content-Type": "application/json; charset=utf-8" } }
    )
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        return baseModel;
      }
    })
    .catch((error) => {
      return {
        success: false,
      };
    });
};

export const EditProfile = (data) => (dispatch) => {
  return axios
    .put(REST_API.ProfileActions.editProfile, data, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: EditProfile, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};
