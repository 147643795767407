import React, { useState } from "react";
import { Container } from "react-bootstrap";
import BannerStrip from "./BannerStrip";
import Hero from "./Hero";
import Navigation from "./Navigation";
import InputField from "./InputField";
import { useNavigate, useLocation } from "react-router-dom";
import UserProfileCart from "../ProfileCart/UserProfileCart";
import { AiOutlineMenu, AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import OutsideClickHandler from "react-outside-click-handler";
import * as CloudSearchActions from "../../redux/actions/CloudSearchActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MainLogo } from "./SVGIcons";
import { sendAmplitudeData } from "../../../utils/amplitude";
import PropTypes from "prop-types";
import config from "../../axios/config";

const Header = (props) => {
  const { params } = props;
  const location = useLocation();
  let queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const q = searchParams.get("q");
  const [isMobile, setIsMobile] = useState(false);
  const [searchText, setSearchText] = useState(q);
  const navigate = useNavigate();

  const ChangeHandler = () => {
    props.CloudSearchActions.setSearchString(searchText);
    props.CloudSearchActions.setSearchParams({ start: 0, size: params.size });
    props.CloudSearchActions.getProductsBySearchTerm({
      q: searchText,
      start: 0,
      size: params.size,
    }).then(() => {
      searchText ? navigate("/search?q=" + searchText) : navigate("/allrentals");
      sendAmplitudeData("User Search", {
        "Event": "User Search from the Search bar",
        "Search-term": searchText,
      });
    });
  };

  const onEnterPress = (event) => {
    if (event.keyCode === 13 && event.key === "Enter") {
      event.preventDefault();
      ChangeHandler();
    }
  };

  return (
    <>
      <BannerStrip />
      <Container className="headerWrapper">
        <span className="burgerMenu">
          {isMobile ? (
            <AiOutlineClose onClick={() => setIsMobile(false)} />
          ) : (
            <AiOutlineMenu onClick={() => setIsMobile(true)} />
          )}
        </span>
        <div className="mainLogo headerelementMobile" onClick={() => navigate("/explore")}>
          <MainLogo />
        </div>
        <div className="headerIcons">
          <div className="logoSearchWrapper">
            <span className="searchInner">
              <AiOutlineSearch onClick={ChangeHandler} />
              <InputField
                id="searchField"
                placeholder="Search for brands, styles ..."
                type="text"
                value={searchText}
                onKeyDown={onEnterPress}
                onchange={(e) => setSearchText(e.target.value)}
                disabled={config.ENV === "development"}
              />
            </span>
          </div>
          <span className="headerelementMobile">
            <UserProfileCart />
          </span>
        </div>
      </Container>
      {location.pathname !== "/checkout" && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsMobile(null);
          }}
        >
          <span className={`${isMobile ? "showmobileNav" : "noMobileNav"}`}>
            <Navigation onclick={() => setIsMobile(false)} />
          </span>
        </OutsideClickHandler>
      )}
      {location.pathname === "/explore" && <Hero />}
    </>
  );
};

const mapStateToProps = ({ search }) => {
  const { params } = search;
  return { params };
};

const mapDispatchToProps = (dispatch) => {
  return {
    CloudSearchActions: bindActionCreators(CloudSearchActions, dispatch),
  };
};

Header.propTypes = {
  params: PropTypes.any,
  CloudSearchActions: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
