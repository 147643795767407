import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

const CollectionCard = (props) => {
  return (
    <Card className="collection-carousel border-0">
      <Card.Img variant="top" className="pb-0 mb-0 rounded-3" src={props.imgSrc} />
      <Card.Body className="p-0">
        <Card.Title>{props.title}</Card.Title>
      </Card.Body>
    </Card>
  );
};

CollectionCard.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
}
export default CollectionCard;
