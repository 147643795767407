import * as types from '../actionType';

const INITIAL_STATE = {
    deliveryOptions: [],
};
export default (state, action) => {
    if (!state) state = INITIAL_STATE;

    switch (action.type) {
        case types.DELIVERY_OPTIONS.GET_DELIVERY_OPTIONS:
            {
                return {
                    ...state,
                    deliveryOptions: action.payload,
                };
            }
        case 2:
        case 3:
        default:
            return state;
    }
};