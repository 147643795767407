import React, { useEffect } from "react";
import ProductCarousel from "./ProductCarousel";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ExploreActions from "../../redux/actions/ExploreActions";
import PropTypes from "prop-types";

const Hero = ({ ExploreActions, heroProducts }) => {
  useEffect(() => {
    ExploreActions.fetchHeroCarousel();
  }, []);

  return (
    <div className="heroWrapper">
      <ProductCarousel data={heroProducts} />
    </div>
  );
};

const mapStateToProps = ({ explore }) => {
  return explore;
};

const mapDispatchToProps = (dispatch) => {
  return {
    ExploreActions: bindActionCreators(ExploreActions, dispatch),
  };
};

Hero.propTypes = {
  ExploreActions: PropTypes.any,
  heroProducts: PropTypes.any,
}
export default connect(mapStateToProps, mapDispatchToProps)(Hero);
