import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const ListFAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contentBodyInner termsOfServicesWrapper">
      <Container>
        <Row>
          <Col>
            <h1> FAQs for Listers</h1>

            <h3>How do I list my items?</h3>
            <p>
              It's really quick and easy! All you have to do is to download the Ownish app and set
              up an account. Then, simply choose the 'List' tab, upload some pictures - ideally of
              you wearing the item - answer a few questions and set your rental and resale price.
            </p>

            <h3>What items can I list?</h3>
            <p>
              We build Ownish to make renting affordable and accessible for everyone, so we don't
              place limits on the brands or cost of an outfit that can be listed. We do ask however
              that you only list items that are in good condition and don't list items with real
              fur, or those that just aren't appropriate for lending to others, for example
              underwear and swimwear.{" "}
            </p>

            <h3>Can I list items for sale or just for rental?</h3>
            <p>
              Ownish is primarily a rental platform, but you can also set a 'Resale' price which
              will then allow people interested in your item to send you a rental or a resale
              request. Whether they want to rent or buy your item, you are always in control and can
              accept or decline the request.{" "}
            </p>

            <h3>How much money will I make from each rental?</h3>
            <p>
              Ownish fees are capped at 10% + 50p of the rental or resale price you choose for your
              item, including any postage costs paid by the renter or buyer. We charge these fees to
              cover the cost of running Ownish, and advertising your items on search and social
              media platforms. Our payment provider Stripe also charges 1.5% + 20p for processing
              the payment. All fees are itemised in your payouts dashboards for each transaction.{" "}
            </p>

            <h3>How do I get my rental / resale revenue?</h3>
            <p>
              When a renter or buyer puts in a request for one of your items, you can decide whether
              to accept or decline the request. If you accept the request, the renters/buyers
              payment will be held by Stripe on your behalf until the completion of the rental. You
              can see all your transaction in your payouts dashboard. When the rental has completed
              or the sale is agreed, Ownish will release your payout minus the Ownish and Stripe
              fees for payment to your chosen bank account.
            </p>

            <h3>Who pays for postage?</h3>
            <p>
              The cost of posting the item to the renter using a standard tracked service should be
              included in the rental or resale price you set. If the renter/buyer chooses for the
              item to be posted using an express next day guaranteed service, they will pay towards
              the additional costs either £7.99 or £9.99 for Saturday delivery. Please ensure you
              get tracking and proof of postage when sending the item.
            </p>

            <h3>Who is responsible for cleaning?</h3>
            <p>
              As the owner of the item you are responsible for cleaning. You know the item best and
              how to care for it, so we always recommend you clean the item.{" "}
            </p>

            <h3>What happens if my item comes back dirty?</h3>
            <p>
              Given it's been worn, it's going to come back needing a really good clean, that's
              completely normal. Some marks and minor signs of wear should also be expected.
            </p>
            <p>
              If on the rare occasion there are stains you don't think a dry clean will resolve, or
              there is damage that's more than can reasonably be expected with normal wear, then
              please let Ownish know as soon as you receive the item back and we will help you
              resolve it.
            </p>

            <h3>What happens if my item does not arrive on time?</h3>
            <p>
              You should always ensure that the rental is received by the renter on or before the
              rental start date. If the item is not delivered by the rental start date and the
              renter is unable to wear the item, then they will be due a full refund. If your item
              is not delivered on time due to postal delays, you may be able to claim your postal
              costs from the postal company depending on the service you used.{" "}
            </p>
            <h3>What happens if my item is returned late?</h3>
            <p>
              Ownish reminds renters when it is time to post their item back, and we ask them to
              message you with tracking and if there are any issues. If your item is late, in the
              first instance message the renter and try and resolve it between you and the renter.
            </p>
            <p>
              If you are unable to agree a resolution, let Ownish know within 5 days of the rental
              end date and we can open a late return case. When we open a case we will look at all
              the circumstances to decide what a fair resolution is. By opening a case you agree to
              accept our final decision. In the event that we decide that the renter should be
              charged for extending the rental, we will use best endeavours to recover that amount
              from the renter.
            </p>

            <h3>What happens if my item is damaged beyond repair?</h3>
            <p>
              It is very rare that an item will be damaged beyond repair by a renter. Some small
              signs of wear are expected and if an item has been rented a few times, if you would
              like to continue renting the item, it may be necessary to carry out minor repairs such
              as re-fixing buttons or repairing a fraying hem or seam.
            </p>
            <p>
              If an item is damaged beyond repair whilst with a renter, then Ownish will calculate
              what the replacement value is for the item, given the number of wears it has had, and
              any Resale price you set and use best endeavours to recover that amount from the
              renter to reimburse you for the damage.{" "}
            </p>

            <h3>What happens if someone doesn't return my item?</h3>
            <p>
              In the very unlikely event that this happens, Ownish will contact the renter to find
              out what happened. If the item cannot be tracked or the renter does not respond,
              Ownish will use best endeavours to recover the replacement value amount from the
              renter to reimburse you for the loss.{" "}
            </p>

            <h3>What happens in between rentals?</h3>
            <p>
              As soon as you receive an item back you should inspect it and update the listing if
              required. You are responsible for making sure the item is properly cleaned between
              rentals so the next renter is ready to wear it in a clean and good condition.{" "}
            </p>

            <h3>Why have I been asked to provide photo and address ID?</h3>
            <p>
              The safety of our community is our top priority, and so it's important that we know
              who is renting or listing an item. On some occasions therefore, we may ask you to
              provide us with your ID to continue to use the Ownish platform. All identification
              documents are stored security and once verified are permanently deleted.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListFAQ;
