import { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as AppActions from "../../../redux/actions/AppActions";
import * as MessagingActions from "../../../redux/actions/messagingActions";
import * as S3Actions from "../../../redux/actions/S3Actions";
import { BsChevronLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Timestamp, arrayUnion, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import Config from "./../../../axios/config";
import { CloseIcon, Envalop, ImageIcon } from "../../common/SVGIcons";
import { TimestampDisplay } from "../../../../utils/dateUtilityFuctions";
import SpinnerComponent from "../../common/Spinner";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import S3 from "react-aws-s3";
import { isEmpty } from "lodash";
import { getFCMTokenFromApi } from "../../../../utils/firebaseUtils";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import PropTypes from "prop-types";

const ChatMessage = (props) => {
  const { email, messaging, name } = props;
  const chatCollection = Config.REST_API.Messages.chatCollection;
  const location = useLocation();
  const [isShow, setIsShow] = useState(false);
  const [typedMessage, setTypedMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState("");
  const [hasInteracted, setHasInteracted] = useState(false);
  const [selectedChatName, setSelectedChatName] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [usersChatRoomsList, setUsersChatRoomsList] = useState([]);
  const [otherUserId, setOtherUserId] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const ref = useRef();
  const functions = getFunctions();

  const scrollToRecentMessage = () => {
    const container = ref.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleImageSelection = (event) => {
    const files = event.target.files;
    if (files) {
      setSelectedImages([...selectedImages, ...files]);
    }
  };

  const removeImage = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  const renderSelectedImages = () => {
    return selectedImages.map((file, index) => (
      <div className="selectedImage" key={URL.createObjectURL(file)}>
        <img src={URL.createObjectURL(file)} alt="productImage" width={100} height={100} />
        <button
          onClick={(e) => {
            e.preventDefault();
            removeImage(index);
          }}
          className="removeImage"
        >
          <CloseIcon />
        </button>
      </div>
    ));
  };

  const uploadMessageImages = async () => {
    let imageUrls = [];
    const maxFileSize = 2097152;

    try {
      const response = await props.S3Actions.getS3Keys();
      const promises = [];

      for (const img of selectedImages) {
        if (img.size > maxFileSize) {
          toast.error("File size limit is 2MB for images.");
          continue;
        }

        const config = {
          bucketName: response.data.bucketName,
          dirName: response.data.keyFolder,
          region: response.data.awsRegion,
          accessKeyId: response.data.awsAccessKeyID,
          secretAccessKey: response.data.awsSecretAccessKey,
          s3Url: Config.S3_URL,
        };

        const ReactS3Client = new S3(config);
        const promise = ReactS3Client.uploadFile(img, img.name)
          .then((result) => ({
            status: "fulfilled",
            value: result.location,
          }))
          .catch((error) => ({
            status: "rejected",
            reason: error,
          }));
        promises.push(promise);
      }

      const results = await Promise.allSettled(promises);

      for (const result of results) {
        if (result.status === "fulfilled") {
          imageUrls.push(result.value);
        } else {
          console.error("Image upload failed:", result.reason);
          logErrorToGrayLogs(
            `function: S3ImageUpload, platform: Web,Feature: Message, client error msg: ${JSON.stringify(
              result.reason
            )}`
          );
        }
      }
    } catch (error) {
      console.error("Failed to upload images:", error);
      logErrorToGrayLogs(
        `function: S3ImageUpload, platform: Web,Feature: Message, client error msg: ${JSON.stringify(
          error
        )}`
      );
    }
    return imageUrls.map((item) => ({ src: item.value }));
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToRecentMessage();
    }, 10);
  }, [isSendingMessage, hasInteracted]);

  useEffect(() => {
    if (location?.state) {
      let nameFirstLetter = location?.state?.otherUser?.name.substring(0, 1);
      let fullName = location?.state?.otherUser?.name;
      let userId = location?.state?.otherUser?.userId;
      setIsShow(true);
      setSelectedChatId(location?.state?.id);
      setSelectedChatName(nameFirstLetter);
      setSelectedUserName(fullName);
      setOtherUserId(userId);
    }
  }, []);

  const sortInboxMsgs = (msgList) => {
    return msgList.sort((a, b) => {
      let dateA = new Date(a?.lastUpdated?.toDate());
      let dateB = new Date(b?.lastUpdated?.toDate());
      return dateB - dateA;
    });
  };

  const id = location?.state?.id || selectedChatId;
  const navigate = useNavigate();

  useEffect(() => {
    const chatRoomsList = sortInboxMsgs(messaging.chatRooms)
      .filter((chat) => {
        if (location?.state?.id === chat.chatId) {
          return chat;
        } else {
          return chat && chat.messages.length > 0;
        }
      })
      .sort((chatA, chatB) => {
        if (location?.state?.id === chatA.chatId) {
          return -1; // chatA should come first
        } else if (location?.state?.id === chatB.chatId) {
          return 1; // chatB should come first
        } else {
          return 0; // no change in order
        }
      });
    setUsersChatRoomsList(chatRoomsList);
  }, [messaging.chatRooms, location?.state?.id, messaging.chatRooms.length]);

  let chat = messaging.chatRooms.find((chatRoom) => chatRoom.chatId === id);
  let messages, unseenMessageCount;
  if (chat) {
    messages = chat.messages;
    unseenMessageCount = chat.unseenMessageCount;
  }

  const handleClick = (chatId, nameFirstLetter, fullName, userId) => {
    location.state = {};
    setSelectedChatId(chatId);
    setSelectedChatName(nameFirstLetter);
    setSelectedUserName(fullName);
    setOtherUserId(userId);
    setTypedMessage("");
    setIsShow(true);
    setHasInteracted(true);
    setSelectedImages([]);
  };

  useEffect(() => {
    if (hasInteracted && id) {
      setHasInteracted(false);
      let updatedMessageCount = unseenMessageCount?.map((item) =>
        item.email === email ? { ...item, count: 0 } : item
      );

      updateDoc(doc(collection(db, chatCollection), id), {
        unseenMessageCount: updatedMessageCount,
      });
    }
  }, [hasInteracted]);

  const getFCMTokenOfOtherUser = async () => {
    if (!isEmpty(otherUserId)) {
      try {
        let response = await getFCMTokenFromApi({
          userId: otherUserId,
        });
        if (isEmpty(response)) {
          logErrorToGrayLogs(
            `user: ${email}, function: getFCMTokenOfOtherUser (from API), client error msg: received empty array of FCM tokens for userId ${otherUserId}`
          );
          return [];
        } else {
          return response.map((item) => item.fcmToken);
        }
      } catch (error) {
        logErrorToGrayLogs(
          `user: ${email}, function: getFCMTokenOfOtherUser (from API), client error msg: ${
            error?.response?.status || JSON.stringify(error)
          }`
        );
        return [];
      }
    } else {
      return [];
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    setIsSendingMessage(true);
    let fcmToken = await getFCMTokenOfOtherUser();

    let trimedMessage = typedMessage.trim();
    if (trimedMessage.length <= 0 && selectedImages.length <= 0) {
      setTypedMessage("");
      return;
    }
    let updatedMessageCount = unseenMessageCount?.map((item) =>
      item.email !== email ? { ...item, count: item.count + 1 } : item
    );

    let imageUrls = [];
    if (selectedImages.length > 0) {
      imageUrls = await uploadMessageImages();
    }

    const now = new Date();
    updateDoc(doc(db, chatCollection, id), {
      lastUpdated: Timestamp.fromDate(now),
      messages: arrayUnion({
        messageContent: trimedMessage,
        time: Timestamp.fromDate(now),
        senderId: email,
        images: imageUrls,
      }),
      unseenMessageCount: updatedMessageCount,
    })
      .then(() => {
        setIsSendingMessage(false);
        setTypedMessage("");
        setSelectedImages([]);
        let payload = {
          notification: {
            title: "New message Received",
            body: trimedMessage,
          },
          data: {
            type: "new_msg",
            chatId: id,
            senderId: email,
          },
        };
        const data = { fcmToken, payload };
        httpsCallable(
          functions,
          "sendNotification"
        )(data)
          .then(() => {})
          .catch((error) => {
            logErrorToGrayLogs(
              `user: ${email}, function: sendMessage, Error sending notification, client error msg: ${
                error.code
              }, Receiver userId: ${otherUserId}, Receiver FCM token: ${JSON.stringify(fcmToken)}`
            );
          });
      })
      .catch(() => {
        setIsSendingMessage(false);
      });

    sendAmplitudeData("Message-User sends message", {
      Event: "User sends a message to another user",
      Sender: `${email} | ${name}`,
      Receiver: `${otherUserId} | ${selectedUserName}`,
    });
  };

  const renderMessageImages = (message) => {
    if (message.images && message.images.length > 0) {
      return (
        <Gallery>
          {message.images.map((image) => (
            <Item
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="768"
              key={image.src}
            >
              {({ ref, open }) => (
                <div key={image.src} className={`chatWindowBody`}>
                  <div
                    className={message.senderId !== email ? "receiverMessageImage" : "messageImage"}
                  >
                    <img
                      ref={ref}
                      onClick={open}
                      src={image.src}
                      alt="productImage"
                      width={"50%"}
                    />
                  </div>
                </div>
              )}
            </Item>
          ))}
        </Gallery>
      );
    }
  };

  const styles = {
    noChatAvailable: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      paddingTop: "50px",
      fontWeight: 600,
    },
    svg: {
      width: "30px !important",
      height: "30px !important",
    },
  };

  return (
    <div className="chatMessagingWrapper">
      <Container>
        <Row>
          <Col>
            <span className="newMessage">Messages</span>
          </Col>
        </Row>
      </Container>
      <Container>
        {usersChatRoomsList && usersChatRoomsList.length > 0 ? (
          <Row>
            <Col xs={12} md={4} lg={4} className="usersList">
              {usersChatRoomsList?.map((item) => {
                let unseenCounts = item.unseenMessageCount.reduce((acc, item) => {
                  if (item.email === email) {
                    return item.count;
                  }
                  return acc;
                }, 0);

                let otherUser = item.users.filter((user) => user.email !== email);
                let nameFirstLetter = otherUser[0]?.name.substring(0, 1);
                let fullName = otherUser[0]?.name;
                return (
                  <span
                    className={`${
                      selectedChatId === item.chatId
                        ? "activeNav active-select-option"
                        : "active-select-option"
                    }`}
                    onClick={() =>
                      handleClick(item.chatId, nameFirstLetter, fullName, otherUser[0]?.userId)
                    }
                    key={item.chatId}
                  >
                    {unseenCounts > 0 && <span className="unSeenMsg" />}
                    <i className="initial">{nameFirstLetter}</i>
                    <p> {otherUser[0]?.name}</p>
                    {item?.product && (
                      <i
                        className="userPicture"
                        onClick={() => navigate("/products/" + item.product.id)}
                      >
                        <img src={item?.product?.image} alt="productImage" />
                      </i>
                    )}
                  </span>
                );
              })}
            </Col>

            <Col
              xs={12}
              md={8}
              lg={8}
              className={`${isShow ? "showMessage" : "hideMessage"} chatWindow select-option`}
            >
              <div className="mobileBackBtn" onClick={() => setIsShow(false)}>
                <BsChevronLeft />
                <i>{selectedUserName}</i>
              </div>
              <span className="chatInnerWrapper" ref={ref}>
                {messages?.map((message) => {
                  return (
                    <>
                      {message?.messageContent && message?.messageContent?.length > 0 ? (
                        <div
                          key={message.time}
                          className={`${
                            message.senderId !== email ? "receiverBubbleInitial" : ""
                          } chatWindowBody`}
                        >
                          {message.senderId !== email && <span>{selectedChatName}</span>}
                          <i
                            className={
                              message.senderId === email ? "senderBubble" : "receiverBubble"
                            }
                          >
                            {message.messageContent}
                            <div className="timestamp">{TimestampDisplay(message.time)}</div>
                          </i>
                        </div>
                      ) : null}
                      {renderMessageImages(message)}
                    </>
                  );
                })}
              </span>
              <div>
                <form onSubmit={sendMessage}>
                  <input
                    value={typedMessage}
                    onFocus={() => setHasInteracted(true)}
                    onChange={(e) => setTypedMessage(e.target.value)}
                    type="text"
                    name="usermessage"
                    autoComplete="off"
                    placeholder="Message"
                  />
                  <label htmlFor="image-upload" className="imageIconLabel">
                    <ImageIcon />
                    <input
                      id="image-upload"
                      type="file"
                      multiple
                      onChange={handleImageSelection}
                      style={{ display: "none" }}
                    />
                  </label>
                  <button disabled={isSendingMessage} type="submit">
                    Send
                  </button>
                </form>
                <div className="selectedImages">{renderSelectedImages()}</div>
              </div>
            </Col>
          </Row>
        ) : (
          <span style={styles.noChatAvailable}>
            {messaging?.loading ? (
              <>
                <SpinnerComponent />
                Fetching chats....
              </>
            ) : (
              <>
                <Envalop style={styles.svg} />
                No Conversations
              </>
            )}
          </span>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app, messaging }) => ({
  email: app.email,
  name: app.userName,
  messaging: messaging,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AppActions: bindActionCreators(AppActions, dispatch),
    MessagingActions: bindActionCreators(MessagingActions, dispatch),
    S3Actions: bindActionCreators(S3Actions, dispatch),
  };
};

ChatMessage.propTypes = {
  email: PropTypes.any,
  messaging: PropTypes.any,
  name: PropTypes.any,
  S3Actions: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatMessage);
