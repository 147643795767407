import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import FloatingLabelInput from "../common/FloatingLabelInput";
import AppButton from "../common/AppButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import * as AppActions from "../../redux/actions/AppActions";
import { toast } from "react-toastify";
import { sendAmplitudeData } from "../../../utils/amplitude";
import PropTypes from "prop-types";

const ResetPassword = (props) => {
  const location = useLocation();
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isRepeatPassword, setIsRepeatPassword] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    code: "",
    newPassword: "",
    repeatPassword: "",
  });

  const onResetPasswordClick = () => {
    props.AppActions.otpVerification(location.state.email, state.code).then((response) => {
      if (response.success) {
        props.AppActions.resetPassword({
          email: location.state.email,
          newPassword: state.newPassword,
          repeatPassword: state.repeatPassword,
        }).then((response) => {
          if (response.success) {
            sendAmplitudeData("Forgot Password-Reset Password-Success", {
              Event: 'User enters new passwords and clicks "reset password"',
              Email: location.state.email,
            });
            navigate("/");
          } else {
            toast.error("something went wrong please try again!", {
              className: "toastError",
              position: toast.POSITION.TOP_CENTER,
            });
            sendAmplitudeData("Forgot Password-Reset Password-Fail", {
              "Event": 'User enters new passwords and clicks "reset password"',
              "Reason for failure": "something went wrong please try again!",
              "Email": location.state.email,
            });
          }
        });
      } else {
        toast.error("invalid code", {
          className: "toastError",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  return (
    <div className="signinWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="loginWrapper">
              <h3>Reset Password</h3>
              <FloatingLabelInput
                type={"text"}
                label="Code"
                name="code"
                value={state.code}
                onchange={handleChange}
                autoComplete="off"
                error=" "
              />
              <span className="loginPasswordEyeWrapper">
                {isNewPassword ? (
                  <AiFillEye onClick={() => setIsNewPassword(!isNewPassword)} />
                ) : (
                  <AiOutlineEye onClick={() => setIsNewPassword(!isNewPassword)} />
                )}
                <FloatingLabelInput
                  type={`${isNewPassword ? "text" : "password"}`}
                  label="New Password"
                  name="newPassword"
                  value={state.newPassword}
                  onchange={handleChange}
                  autoComplete="off"
                  error=" "
                />
              </span>
              <span className="loginPasswordEyeWrapper">
                <FloatingLabelInput
                  type={`${isRepeatPassword ? "text" : "password"}`}
                  label="Repeat Password"
                  name="repeatPassword"
                  value={state.repeatPassword}
                  onchange={handleChange}
                  autoComplete="off"
                  error=" "
                />
                {isRepeatPassword ? (
                  <AiFillEye onClick={() => setIsRepeatPassword(!isRepeatPassword)} />
                ) : (
                  <AiOutlineEye onClick={() => setIsRepeatPassword(!isRepeatPassword)} />
                )}
              </span>

              <span className="appBtnWrapperInner" style={{ display: "block" }}>
                <AppButton text="Reset Password" onclick={() => onResetPasswordClick()} />
              </span>
              <span className="noAccountbtnWrapper">
                Don’t have an account? <Link to="/register">Sign up</Link>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return { AppActions: bindActionCreators(AppActions, dispatch) };
};

ResetPassword.propTypes = {
  AppActions: PropTypes.any,
}
export default connect(null, mapDispatchToProps)(ResetPassword);
