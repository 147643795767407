import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Gallery from "./Gallery";
import ProductDetailHeader from "./ProductDetailHeader";
import StarRating from "../../common/StarRating";
import { AiOutlineHeart, AiOutlineUpload } from "react-icons/ai";
import ProductDetailBody from "./ProductDetailBody";
import OrderDates from "./OrderDates";
import FaqsAccordians from "./FaqsAccordians";
import "react-datepicker/dist/react-datepicker.css";
import * as productActions from "../../../redux/actions/productActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  getDateAfterDays,
  getDatesBetween,
  checkDatesClash,
  getMonthName,
} from "../../../../utils/dateUtilityFuctions";
import * as constants from "./../../../../constants/constants";
import { colors } from "../../../../styles/colors";
import Calendar from "./../../common/Calendar";
import { toast } from "react-toastify";
import { getCategorySlug, removeZeroInDecimal } from "../../../utilities/helpers";
import { FillHeart, Heart } from "./../../common/SVGIcons";
import BuyProduct from "./BuyProduct";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import { useWishlist } from "../../../../services/useWishlist";
import moment from "moment";
import { getProductById } from "../../../../services/getProductService";
import { convertTitleToSlug } from "../../../../utils/utilities";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import PropTypes from "prop-types";

const data = [
  {
    heading: "When will my item arrive, and when do I send it back?",
    description:
      "You have the item until the rental end date, which is determined by the length of the rental you chose. You can choose from one of the preset periods; 4, 8, 12 and 18 days. Or, just ask the lister if you want to rent it for a different period. On the final day of your rental, you must return the item(s) to the address provided.",
  },
  {
    heading: `What if the item doesn't fit?`,
    description: `We advise messaging the lister in advance of requesting your dates to ask them about fit. If when it turns up you can't wear it, then let the lender know immediately and see if there is anything they can do. Each lender sets their own refund policy. If the lister agrees a refund, please send the item back within 24 hours using a guaranteed signed for service, not on the rental end date.`,
  },
  {
    heading: "How do I post it back to the lister?",
    description:
      "Please post it back on the last day of your rental to the address provided in the app using a guaranteed signed for service. This is to ensure that you are covered in case of loss by the delivery company. If there are any issues sending it back in time, please message the lister in the app as soon as possible.",
  },
  {
    heading: "Who cleans the item after I wear it?",
    description:
      "The lender is responsible for cleaning the item before and after a rental, so please do not try and clean the item in any way.",
  },
];

const {
  BOOKING_STATUS,
  CALENDAR_RESERVATIONS: { TOTAL_BOOKING_DAYS, TOTAL_CLEANING_DAYS },
  RENTAL_PRICE_VARIANTS: {
    EIGHT_DAYS_PERCENTAGE,
    TWELVE_DAYS_PERCENTAGE,
    EIGHTEEN_DAYS_PERCENTAGE,
  },
} = constants;

const defaultPriceVariants = [
  {
    threshold: 8,
    percentage: EIGHT_DAYS_PERCENTAGE,
  },
  {
    threshold: 12,
    percentage: TWELVE_DAYS_PERCENTAGE,
  },
  {
    threshold: 18,
    percentage: EIGHTEEN_DAYS_PERCENTAGE,
  },
];

const ProductDetail = (props) => {
  const { userId, isLoggedIn, bearerToken } = props;
  const [reservations, setReservations] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [mySelectedDates, setMySelectedDates] = useState({});
  const [apiPayload, setApiPayload] = useState({});
  const [message, setMessage] = useState("");
  const [pdpProduct, setPdpProduct] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState({
    price: 0,
    threshold: 4,
  });

  if (bearerToken) {
    const base64Part = bearerToken.split(".")[1];
    const decodedToken = Buffer.from(base64Part, "base64").toString("utf-8");
    const tokenData = JSON.parse(decodedToken);
    if (tokenData.email) {
      localStorage.setItem("userEmail", tokenData.email);
    }
  }
  const loggedUserId = userId;
  const navigate = useNavigate();
  const url = window.location.pathname;
  const modifiedUrl = url.split("/");
  let productId = modifiedUrl[modifiedUrl.length - 1];
  if (productId.includes("-")) {
    const productIdParts = productId.split("-");
    productId = productIdParts[productIdParts.length - 1];
  }

  const bookedDatesStyles = {
    color: colors.pastelPink,
    textColor: colors.gray,
    disabled: true,
    disableTouchEvent: true,
  };

  const mySelectedDatesStyles = {
    color: colors.pink,
  };

  const myPreviouslySelectedDatesStyles = {
    color: colors.pastelPink,
  };

  useEffect(() => {
    fetchProductDetail();
    setTimeout(() => {
      fetchReservedDates();
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  const fetchProductDetail = () => {
    getProductById(productId, userId)
      .then((res) => {
        if (res.success) {
          setPdpProduct(res.data);
        } else {
          logErrorToGrayLogs(
            `function: ProductDetail.js -> fetchProductDetail, platform: Web, Details:Product Id${productId}, userId${userId}, vendorDetails:${res.data.swishedUser}.  Message; ${res.message}:`
          );
          navigate("*");
        }
      })
      .catch((error) => {
        logErrorToGrayLogs(
          `function: ProductDetail.js -> fetchProductDetail, platform: Web, Details:Product Id${productId}, userId${userId}, vendorDetails:${pdpProduct.swishedUser} client error msg: ${error.message}`
        );
      });
  };

  useEffect(() => {
    // Once product data is fetched, update the URL
    if (pdpProduct) {
      const formattedUrl = `/products/${getCategorySlug(
        pdpProduct?.variants[0]?.category
      )}-${convertTitleToSlug(pdpProduct.title)}-${pdpProduct?.variants[0]?.colour}-${productId}`;
      navigate(formattedUrl, { replace: true });
    }
  }, [pdpProduct, navigate]);

  const markReservedDates = (data) => {
    let updatedBookedDates = [];
    let updatedDisabledDates = [];
    let myNewlySelectedDates = [];

    // Mark booked dates fetched from api
    const formatedReservations = data.map((item) => {
      return {
        startDate: new Date(item.startDate),
        endDate: new Date(item.endDate),
        color: colors.pink,
        key: "selection",
      };
    });

    setReservations(formatedReservations);
    data.forEach((bookingInstance) => {
      const { startDate, endDate, status, loggedUserId, isPaymentDetailAdded } = bookingInstance;
      let isMineSelection = userId === loggedUserId;
      let isNewRequest = status === constants.BOOKING_STATUS.REQUESTED && !isPaymentDetailAdded;
      try {
        let updatedStyles;
        if (isMineSelection) {
          if (isNewRequest) {
            updatedStyles = mySelectedDatesStyles;
          } else {
            updatedStyles = myPreviouslySelectedDatesStyles;
          }
        } else {
          updatedStyles = bookedDatesStyles;
        }

        if (isMineSelection && isNewRequest) {
          myNewlySelectedDates.push({
            startDate,
            endDate,
            color: updatedStyles.color,
            disabled: true,
          });
        } else {
          updatedBookedDates.push({
            startDate,
            endDate,
            color: updatedStyles.color,
            disabled: true,
          });
        }

        if (!isMineSelection && !isNewRequest) {
          const disableStartDate = startDate;
          const disableEndDate = getDateAfterDays(TOTAL_CLEANING_DAYS, endDate);
          let datesToDisable = getDatesBetween(disableStartDate, disableEndDate);

          datesToDisable = datesToDisable.map((item) => new Date(item));
          updatedDisabledDates = [...updatedDisabledDates, ...datesToDisable];
        }
      } catch (error) {
        console.log("error while extracting dates: ", error);
      }
    });

    setDisabledDates(updatedDisabledDates);

    if (!isEmpty(myNewlySelectedDates)) {
      setMySelectedDates(myNewlySelectedDates);
    }
  };

  const fetchReservedDates = () => {
    if (isLoggedIn) {
      props.productActions.getProductReservationDates(productId, loggedUserId).then((response) => {
        if (response.success) {
          markReservedDates(response.data);
        }
      });
    }
  };

  const handleDayClick = async (day) => {
    const dateString = new Date(day).toDateString();
    const startedDay = moment(dateString, "ddd MMM D YYYY");

    let rentEndDay = getDateAfterDays(selectedDuration.threshold - 1, dateString);
    let rentAndServiceEndDay = getDateAfterDays(
      selectedDuration.threshold + TOTAL_CLEANING_DAYS - 1,
      dateString
    );
    let rentAndServiceDates = getDatesBetween(dateString, rentAndServiceEndDay);

    if (checkDatesClash(rentAndServiceDates, reservations)) {
      toast.success(
        "This date conflicts with another rental. Please select another date or message the Lister!",
        { className: "toastSuccess", position: toast.POSITION.TOP_CENTER }
      );
      return;
    }

    let endDate = new Date(rentEndDay);
    let range = {
      from: day,
      to: endDate,
    };

    setMySelectedDates(range);

    setApiPayload({
      productId: productId,
      startDate: startedDay.format("YYYY-MM-DD"),
      endDate: rentEndDay,
      userId: loggedUserId,
      status: BOOKING_STATUS.REQUESTED,
      webReservation: true,
    });
  };

  const requestDate = (isPurchased = false) => {
    if (!userId) {
      props.productActions.setReturnPdpPath("/products/" + productId);
      sendAmplitudeData("On clicking confirm dates User is not logged in so navigated to Login", {
        Event: "User is not logged in so navigated to Login",
      });
      navigate("/login");
    } else {
      const payload = isPurchased
        ? {
          productId,
          startDate: null,
          endDate: null,
          userId: loggedUserId,
          status: BOOKING_STATUS.REQUESTED,
          webReservation: true,
          isPurchased: true,
        }
        : apiPayload;
      if (!isPurchased && !apiPayload.startDate) {
        setMessage("Please select dates.");
        return;
      } else {
        setMessage("");
      }
      props.productActions.setProductReservationDates(payload).then((response) => {
        if (response.success) {
          sendAmplitudeData("User Selected Dates & proceeded to CheckOut", {
            Event: "User Selected Dates & proceeded to CheckOut",
          });
          navigate("/checkout", {
            state: {
              productId,
              name: pdpProduct?.title,
              picture: pdpProduct?.images && pdpProduct?.images[0]?.src,
              size: pdpProduct?.variants && pdpProduct?.variants[0]?.size,
              arrivedate: payload.startDate ? new Date(payload.startDate).getDate() : "",
              arrivemonth: payload.startDate
                ? getMonthName(new Date(payload.startDate).getMonth())
                : "",
              postbackdate: payload.endDate ? new Date(payload.endDate).getDate() : "",
              postbackmonth: payload.endDate
                ? getMonthName(new Date(payload.endDate).getMonth())
                : "",
              rentalPrice: selectedDuration,
              resalePrice: pdpProduct?.variants && pdpProduct?.variants[0]?.resalePrice,
              isPurchased,
            },
          });
        } else {
          toast.error(response.message, {
            className: "toastError",
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };

  let rentalPrice = (pdpProduct?.variants && pdpProduct?.variants[0]?.rentalPrice) || 0;
  let rentalPricePerDay =
    rentalPrice % TOTAL_BOOKING_DAYS === 0
      ? rentalPrice / TOTAL_BOOKING_DAYS
      : (rentalPrice / TOTAL_BOOKING_DAYS).toFixed(2);
  const getRentalPriceVariant = useCallback(() => {
    const variants = [];
    variants.push({
      price: removeZeroInDecimal(parseFloat(rentalPrice).toFixed(2)),
      threshold: 4,
    });
    if (pdpProduct?.variants && pdpProduct?.variants[0]?.variantPrices.length) {
      pdpProduct?.variants[0]?.variantPrices.forEach((variantPrice) => {
        const { price, threshold } = variantPrice;
        variants.push({
          price,
          threshold,
        });
      });
    } else {
      defaultPriceVariants.forEach(({ percentage, threshold }) => {
        const priceForDays = parseFloat(
          parseFloat(rentalPrice) + parseFloat(rentalPrice) * (percentage / 100)
        );
        variants.push({
          price: removeZeroInDecimal(priceForDays.toFixed(2)),
          threshold,
        });
      });
    }
    return variants;
  }, [pdpProduct?.variants, rentalPrice]);

  useEffect(() => {
    if (pdpProduct?.variants && pdpProduct?.variants[0]) {
      const variants = getRentalPriceVariant();
      setSelectedDuration(variants[0]);
    }
  }, [getRentalPriceVariant, pdpProduct]);

  const initials = pdpProduct?.swishedUser?.name && pdpProduct?.swishedUser?.name.substring(0, 1);

  // Wishlist

  const { toggleWishList, isWishlisted } = useWishlist(pdpProduct?.isInMyWishList);

  return (
    <Container style={{ marginTop: "1.5rem" }} className="pdpWrapper">
      <Row>
        <Col xs={12} lg={6}>
          <Gallery images={pdpProduct?.images || []} />
        </Col>
        <Col xs={12} lg={6}>
          <div className="productDetailRightCol">
            <ProductDetailHeader
              userDetails={pdpProduct?.swishedUser}
              initials={initials}
              name={pdpProduct?.swishedUser?.name}
              rating={<StarRating />}
              productDetails={{
                title: pdpProduct?.title,
                image: pdpProduct?.images ? pdpProduct?.images[0]?.src : "",
                id: pdpProduct?.id,
              }}
              hearticon={<AiOutlineHeart />}
              uploadicon={<AiOutlineUpload />}
              messageHandler={() => navigate("/chat-message")}
              setProductPdp={props.productActions.setReturnPdpPath}
            />

            <ProductDetailBody
              name={pdpProduct?.title}
              description={pdpProduct?.description}
              size={pdpProduct?.variants && pdpProduct?.variants[0]?.size}
              fit={pdpProduct?.variants && pdpProduct?.variants[0]?.fit}
              condition={pdpProduct?.variants && pdpProduct?.variants[0]?.condition}
              rent={rentalPricePerDay}
              rentalPrice={selectedDuration.price}
              rrp={pdpProduct?.variants && pdpProduct?.variants[0]?.retailPrice}
              saving="100"
              hearticon={isWishlisted ? <FillHeart /> : <Heart />}
              onheartclick={(e) => {
                e.preventDefault();
                if (!userId) {
                  props.productActions.setReturnPdpPath("/products/" + productId);
                }
                toggleWishList(pdpProduct?.id);
              }}
            />

            {/* create 4 divs, with onClick selection functionality */}
            <div className="rentalDurations">
              {getRentalPriceVariant().map((duration, index) => (
                <Button className="px-0 py-0 border-0" variant="default" onClick={() => {
                  setSelectedDuration(duration);
                  setMySelectedDates({});
                }}
                  onKeyDown={() => {
                    setSelectedDuration(duration);
                    setMySelectedDates({});
                  }}
                  key={duration.threshold + "_" + index}>
                  <div
                    style={{
                      backgroundColor:
                        selectedDuration.threshold === duration.threshold ? "#ffb2c3" : "#f2f2f2",
                    }}
                    className={"rentalPriceDuration"}
                  >
                    {duration.threshold} days{" "}
                    <span className="rentalDurationPrice">£{duration.price}</span>
                  </div>
                </Button>
              ))}
            </div>

            <div style={{ minHeight: 200 }}>
              <Calendar
                selection={mySelectedDates}
                disabled={disabledDates}
                onDayClick={handleDayClick}
                fromDate={new Date()}
                toDate={new Date(getDateAfterDays(365))}
              />
            </div>

            <OrderDates
              selectedDuration={selectedDuration}
              arrivedate={apiPayload.startDate ? new Date(apiPayload.startDate).getDate() : ""}
              arrivemonth={
                apiPayload.startDate ? getMonthName(new Date(apiPayload.startDate).getMonth()) : ""
              }
              postbackdate={apiPayload.endDate ? new Date(apiPayload.endDate).getDate() : ""}
              postbackmonth={
                apiPayload.endDate ? getMonthName(new Date(apiPayload.endDate).getMonth()) : ""
              }
              retailPrice={pdpProduct?.variants && pdpProduct?.variants[0]?.retailPrice}
              onclick={() => {
                requestDate(false);
              }}
            />
            <p className="dateError">{message}</p>
            {pdpProduct?.isPurchaseAvailable &&
              pdpProduct?.variants[0]?.resalePrice &&
              pdpProduct?.variants[0]?.resalePrice > 0 ? (
              <BuyProduct
                retailPrice={pdpProduct?.variants && pdpProduct?.variants[0]?.resalePrice}
                onclick={() => {
                  requestDate(true);
                }}
              />
            ) : null}
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: "2rem" }}>
        <span className="rentalDuration faqHeading">Got any questions? Check out our FAQs</span>

        {data.map((item, index) => {
          return (
            <Col xs={12} lg={3} key={data.id}>
              <FaqsAccordians heading={item.heading} description={item.description} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ app }) => {
  const { userId, isLoggedIn, bearerToken } = app;

  return { userId, isLoggedIn, bearerToken };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActions: bindActionCreators(productActions, dispatch),
  };
};
ProductDetail.propTypes = {
  isLoggedIn: PropTypes.bool,
  userId: PropTypes.string,
  bearerToken: PropTypes.any,
  productActions: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
