import React, { useState } from "react";
import AppButton from "../../common/AppButton";
import firebaseUtils from "../../../../utils/firebaseUtils";
import { bindActionCreators } from "redux";
import * as AppActions from "../../../redux/actions/AppActions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import PropTypes from "prop-types";

const ProductDetailHeader = ({
  userDetails,
  userName,
  name,
  rating,
  followhandler,
  initials,
  productDetails,
  isLoggedIn,
  userId,
  email,
  setProductPdp,
}) => {
  let userpicture = userDetails?.artifactUrl;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const isIcons = false;
  let currentUser = {
    name: userName,
    isLoggedIn,
    email,
    userId,
  };

  const handleSendMessage = async () => {
    try {
      if (!isLoggedIn) {
        setProductPdp("/products/" + productDetails?.id);
        sendAmplitudeData('Not Logged-in User Clicks "Message the Lister"', {
          Event: 'Not Logged In User clicks "Message the Lister" and redirected to the Login Page',
          Receiver: userDetails?.userId,
          ProductID: productDetails?.id,
        });
        navigate("/login");
        return;
      }
      setLoading(true);
      let payload = {
        title: productDetails?.title,
        image: productDetails?.image || "",
        id: productDetails?.id,
      };
      firebaseUtils
        .getChatId(currentUser, userDetails, payload)
        .then((chatId) => {
          sendAmplitudeData('Logged-in User Clicks "Message the Lister"', {
            Event: 'Logged In User clicks "Message the Lister" and redirected to the Inbox Page',
            Receiver: userDetails?.userId,
            ProductID: productDetails?.id,
          });
          setLoading(false);
          navigate("/inbox", { state: { id: chatId, otherUser: userDetails } });
        })
        .catch((error) => {
          setLoading(false);
          logErrorToGrayLogs(
            `function: FirebaseUtils->getChatId, platform: Web, client error msg: ${error.stack} 
            user: ${currentUser.email}, product: ${productDetails?.id} error: ${error}`
          );
        });
    } catch (error) {
      logErrorToGrayLogs(
        `function: ProductDetailHeader->handleSendMessage, platform: Web, client error msg: ${error.stack}
        user: ${currentUser.email}, product: ${productDetails?.id}`
      );
    }
  };
  return (
    <div className="ProductDetailHeaderWrapper">
      <span className="userPic" onClick={() => navigate(`/profile?userId=${userDetails?.userId}`)}>
        <i className="userPicInner">
          <img src={userpicture} alt="" />
          {!userpicture && <span>{initials}</span>}
        </i>
        <span className="userDetail">
          {name}
          {isIcons && (
            <>
              <i>{rating} </i>
            </>
          )}
        </span>
      </span>

      <span className="headerBtns">
        {!isIcons && (
          <>
            {userDetails?.email !== currentUser?.email ? (
              <>
                <AppButton text="Follow" onclick={followhandler} />
                <AppButton
                  disabled={loading}
                  loading={loading}
                  text="Message"
                  onclick={handleSendMessage}
                />
              </>
            ) : (
              ""
            )}
          </>
        )}
      </span>
    </div>
  );
};

const mapStateToProps = ({ app }) => {
  const { isLoggedIn, userId, email, userName } = app;
  return { isLoggedIn, userId, email, userName };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AppActions: bindActionCreators(AppActions, dispatch),
  };
};

ProductDetailHeader.propTypes = {
  userDetails: PropTypes.any,
  userName: PropTypes.any,
  name: PropTypes.any,
  rating: PropTypes.any,
  followhandler: PropTypes.any,
  initials: PropTypes.any,
  productDetails: PropTypes.any,
  isLoggedIn: PropTypes.any,
  userId: PropTypes.any,
  email: PropTypes.any,
  setProductPdp: PropTypes.any,
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailHeader);
