import Accordion from 'react-bootstrap/Accordion';
import PropTypes from "prop-types";

const FaqsAccordians = ({ heading, description }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header>{heading}</Accordion.Header>
        <Accordion.Body>
          {description}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

FaqsAccordians.propTypes = {
  heading: PropTypes.any,
  description: PropTypes.any,
}
export default FaqsAccordians;