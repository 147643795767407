import { SEARCH_ACTIONS } from '../actionType';
import { logErrorToGrayLogs } from "./GrayLogActions";

// get product By search term
export const getProductsBySearchTerm = (params) => async (dispatch) => {
    try {
        const response = await fetch(
            `https://spwalugfwg.execute-api.eu-west-1.amazonaws.com/staging?${new URLSearchParams(
                params
            )}`
        );

        const data = await response.json();
        let result, start, found;
        if (response && response.status === 200) {
            result = data?.hits?.hit;
            found = data?.hits?.found;
            start = data?.hits?.start;

            // update the current and limit and other filter upon success
            dispatch({ type: SEARCH_ACTIONS.SET_SEARCH, payload: result });
            dispatch({
                type: SEARCH_ACTIONS.SET_HAS_MORE_SEARCH_DATA,
                payload: found - (start + result.length) > 0,
            });
        } else {
            dispatch({ type: SEARCH_ACTIONS.SET_SEARCH, payload: [] });
        }
    } catch (error) {
        logErrorToGrayLogs(
            `function: getProductsBySearchTerm, platform: Web, client error msg: ${error.message}`,
        );
        console.log('error: ', error);
        return {
            success: false,
        };
    }
};

export const setSearchParams = (payload) => {
    return {
        type: SEARCH_ACTIONS.SET_SEARCH_PARAMS,
        payload,
    };
};

export const setSearchString = (payload) => {
    return {
        type: SEARCH_ACTIONS.SET_SEARCH_STRING,
        payload,
    };
};

export const setHasMoreFlag = (payload) => {
    return {
        type: SEARCH_ACTIONS.SET_HAS_MORE_SEARCH_DATA,
        payload,
    };
};

export const resetCloudSearchState = () => {
    return {
        type: SEARCH_ACTIONS.RESET_CLOUD_SEARCH_STATE,
    };
};
