import * as types from "./../actionType";

const INITIAL_STATE = {
  filteredProducts: [],
  hasMoreData: true,
  pdpProduct: {},
  currentReservation: {},
  rentAllFilters: null,
  returnPdpPath: "",
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case types.PRODUCT_ACTIONS.GET_PRODUCTS_ON_SCROLL: {
      return {
        ...state,
        filteredProducts: action.payload
          ? state.filteredProducts.concat(action.payload.data)
          : state.filteredProducts,
        hasMoreData: action.payload ? action.payload.hasMore : false,
        current: action.payload ? action.payload.current : 1,
      };
    }
    case types.PRODUCT_ACTIONS.GET_PRODUCTS: {
      return {
        ...state,
        filteredProducts: action.payload ? action.payload.data : [],
        hasMoreData: action.payload ? action.payload.hasMore : false,
      };
    }
    case types.PRODUCT_ACTIONS.SET_PDP_DATA: {
      return {
        ...state,
        pdpProduct: action.payload,
      };
    }
    case types.PRODUCT_ACTIONS.SET_RESERVATION: {
      return {
        ...state,
        currentReservation: action.payload,
      };
    }
    case types.PRODUCT_ACTIONS.CLEAR_PDP_DATA: {
      return {
        ...state,
        pdpProduct: {},
      };
    }
    case types.PRODUCT_ACTIONS.SET_FILTERS: {
      return {
        ...state,
        rentAllFilters: action.payload,
      };
    }
    case types.PRODUCT_ACTIONS.SET_RETURN_PDP_PATH: {
      return {
        ...state,
        returnPdpPath: action.payload,
      };
    }
    default:
      return state;
  }
};
