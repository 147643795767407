import axios from "axios";
import { Common } from "./../utilities/helpers";

let callCounter = 0;

const getAuthToken = (store) => {
  try {
    const state = store.getState();
    const token = state?.app?.bearerToken;
    if (token) {
      return token;
    }
    return null;
  } catch (err) {
    return null;
  }
};

const configureAxiosDefaults = (store) => {
  /* attach token to each request header if request is other than login/register */
  axios.interceptors.request.use(
    (cfg) => {
      callCounter += 1;
      const config = cfg;
      config.headers.Pragma = "no-cache"; // IE-11 fix for cache issue
      const url = config.url.toLowerCase();

      if (url.includes("/getalllisting") || url.includes("/productreservationdetails/Get")) {
        Common.loaderDisplay(false);
      } else {
        Common.loaderDisplay(true);
      }
      if (url.endsWith("/login")) {
        return config;
      }

      const token = getAuthToken(store);
      if (token === null) return config;
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.request.use((cfg) => {
    const config = cfg;
    return config;
  });
  // global axios unauthorized handler
  axios.interceptors.response.use(
    (response) => {
      const { config } = response;
      callCounter -= 1;
      if (callCounter === 0) {
        if (config.url.includes("Search")) {
          if (!config.url.includes("CreateUserPaymentIntent")) Common.closeLoaderIn3Seconds();
        } else {
          (!config.url.includes("CreateUserPaymentIntent")) && Common.loaderDisplay(false);
        }
      }
      return response;
    },
    (error) => {
      const { config } = error;
      callCounter -= 1;
      if (error.response !== undefined) {
        if (error.response.status === 403) {
          // admin role required
          window.location.href = "/login";
        }
      }

      if (callCounter === 0) {
        if (config.url.includes("Search") || config.url.includes("CreateUserPaymentIntent"))
          Common.closeLoaderIn3Seconds();
        else Common.loaderDisplay(false);
      }
    }
  );
};

export default configureAxiosDefaults;
