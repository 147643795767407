import amplitude from "amplitude-js";
import keys from "../resources/utilities/keys";

export const initAmplitude = () => {
  amplitude.getInstance().init(keys.AMPLITUDE_KEY_PRODUCTION);
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const sendAmplitudeData = (eventName, eventDescription) => {
  amplitude.getInstance().logEvent(eventName, eventDescription);
};

export const resetAmplitudeUserId = () => {
  amplitude.getInstance().setUserId(null);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};
