import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

function Navigation({ onclick }) {
  const location = useLocation();
  return (
    <div
      className={`${location.pathname === "/payment-successful" ? "noNav" : ""} navigationWrapper`}
      onClick={onclick}
    >
      <ul>
        <li>
          <NavLink to="explore">.Explore</NavLink>
        </li>
        <li>
          <NavLink to="all-collections">.Collections</NavLink>
        </li>
        <li>
          <NavLink to="allrentals">.All items</NavLink>
        </li>
        <li>
          <NavLink to="resale">.Resale</NavLink>
        </li>
        <li>
          <NavLink to="gift-cards">.Gift Cards</NavLink>
        </li>
        <li>
          <NavLink to="how-it-works">.How it works</NavLink>
        </li>
      </ul>
    </div>
  );
}

Navigation.propTypes = {
  onclick: PropTypes.any
}

export default Navigation;
