import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RentallTile from "../../common/RentallTile";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CloudSearchActions from "../../../redux/actions/CloudSearchActions";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerComponent from "../../common/Spinner";
import { getScrollThreshold, getCategorySlug } from "../../../utilities/helpers";
import { convertTitleToSlug } from "../../../../utils/utilities";
import Dropdown from "react-bootstrap/Dropdown";
import { TbArrowsSort, TbFilter } from "react-icons/tb";
import options from "./data";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getSearchProducts } from "../../../../services/getProductService";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import PropTypes from "prop-types";

const ProductSearchGrid = (props) => {
  const [selectedSizeList, setSelectedSizeList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedColorList, setSelectedColorList] = useState([]);
  const location = useLocation();
  let queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const q = searchParams.get("q");
  const sortParam = searchParams.get("sort");
  const sizeParam = searchParams.get("size");
  const colorParam = searchParams.get("colour");
  const categoryParam = searchParams.get("category");
  const sizes = sizeParam?.split("_");
  const categories = categoryParam?.split("_");
  const colors = colorParam?.split("_");
  const [querySize, setQuerySize] = useState("");
  const [queryCat, setQueryCat] = useState("");
  const [queryColor, setQueryColor] = useState("");

  const { searchString } = props;

  const [selectedSort, setSelectedSort] = useState(sortParam || "");
  const [searchKey, setSearchKey] = useState(q);

  useEffect(() => {
    let sizev = "";
    const sizeObjects = sizes?.map((size) => ({
      value: size,
      label: size,
    }));
    setSelectedSizeList(sizeObjects);

    let catv = "";
    const categoryObjects = categories?.map((cat) => ({
      value: cat,
      label: cat,
    }));
    setSelectedCategoryList(categoryObjects);

    let colorv = "";
    const colorObjects = colors?.map((color) => ({
      value: color,
      label: color,
    }));
    setSelectedColorList(colorObjects);

    if (sizes) {
      sizes?.forEach((x) => {
        sizev += `size:'${x}' `;
      });
      setQuerySize(sizev);
    }
    if (categories) {
      categories?.forEach((x) => {
        catv += `category:'${x}' `;
      });
      setQueryCat(catv);
    }
    if (colors) {
      colors?.forEach((x) => {
        colorv += `colour:'${x}' `;
      });
      setQueryColor(colorv);
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    selectedSort ? urlSearchParams.set("sort", selectedSort) : urlSearchParams.delete("sort");
    !selectedSizeList && urlSearchParams.delete("size");
    !selectedCategoryList && urlSearchParams.delete("category");
    !selectedColorList && urlSearchParams.delete("colour");
    if (selectedSizeList) {
      let result = selectedSizeList.map((a) => a.value);
      if (result.length > 0) {
        const sizeValue = result.join(",");
        urlSearchParams.set("size", sizeValue);
      }
    }
    if (selectedCategoryList) {
      let result = selectedCategoryList.map((a) => a.value);
      if (result.length > 0) {
        const catValue = result.join(",");
        urlSearchParams.set("category", catValue);
      }
    }
    if (selectedColorList) {
      let result = selectedColorList.map((a) => a.value);
      if (result.length > 0) {
        const colorValue = result.join(",");
        urlSearchParams.set("colour", colorValue);
      }
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlSearchParams.toString().replace(/%2C/g, "_")}`
    );
  }, [selectedSort, selectedSizeList, selectedCategoryList, selectedColorList]);

  const fetchProducts = async ({ pageParam = 0 }) => {
    let filterquery = "";
    if (querySize || queryCat || queryColor) {
      let q1 = queryCat && `(or ${queryCat})`
      let q2 = querySize && `(or ${querySize})`
      let q3 = queryColor && `(or ${queryColor})`
      filterquery = `(and ${q1}${q2}${q3})`;
    }
    let result = await getSearchProducts({
      size: 20,
      start: pageParam,
      q: `${searchString || searchKey}`,
      fq: filterquery,
      sort: selectedSort ? `rentalprice ${selectedSort}` : "position asc",
    });
    return result;
  };

  let dataLength = 20;
  const { data, fetchNextPage, hasNextPage, status, refetch } = useInfiniteQuery({
    queryKey: ["searchProducts"],
    queryFn: fetchProducts,
    getNextPageParam: (lastPage, pages) => {
      const totalDataLength = pages.reduce(
        (totalLength, page) => totalLength + page?.result?.length,
        0
      );
      dataLength = totalDataLength;
      let nextPage = lastPage.hasMoreData ? lastPage.start : undefined;
      return nextPage;
    },
    enabled: false,
  });

  useEffect(() => {
    setSearchKey(q);
    refetch();
  }, [props.searchString, q, selectedSort, selectedSizeList, selectedCategoryList, selectedColorList]);

  const handleSortSelect = (e) => {
    e === "position" ? setSelectedSort("") : setSelectedSort(e);
  };
  const handleSizeSelect = (data) => {
    const sizesList = [];
    data?.map((obj) => {
      sizesList.push(obj);
    });
    let sizeq = "";

    sizesList?.forEach((x) => {
      sizeq += `size:'${x.value}' `;
    });
    setQuerySize(sizeq);
    setSelectedSizeList(sizesList);
  };
  const handleCategorySelect = (data) => {
    const categoryList = [];
    data?.map((obj) => {
      categoryList.push(obj);
    });
    let catq = "";

    categoryList?.forEach((x) => {
      catq += `category:'${x.value}' `;
    });
    setQueryCat(catq);
    setSelectedCategoryList(categoryList);
  };
  const handleColorSelect = (data) => {
    const colorsList = [];
    data?.map((obj) => {
      colorsList.push(obj);
    });
    let colorq = "";

    colorsList?.forEach((x) => {
      colorq += `colour:'${x.value}' `;
    });
    setQueryColor(colorq);
    setSelectedColorList(colorsList);
  };
  const [isFilter, setIsFilter] = useState(false);

  return (
    <div className="productTilesGridWrapper">
      <Container>
        {status === "loading" && <SpinnerComponent />}
        <div className="productGridfilters col-lg-12">
          <div xs={12} md={12} className="p-0">
            <span className="filterWrapperMobile" onClick={() => setIsFilter(!isFilter)}>
              <TbFilter /> <i>Search filters</i>
            </span>
            <div className={`filtersWrapper ${isFilter ? "mobileFitersMain" : "noMobileFilter"}`}>
              <ReactMultiSelectCheckboxes
                options={options.categorySearch}
                placeholderButtonLabel="Category"
                onChange={handleCategorySelect}
                value={selectedCategoryList}
              />
              <ReactMultiSelectCheckboxes
                options={options.size}
                placeholderButtonLabel="Size"
                onChange={handleSizeSelect}
                value={selectedSizeList}
              />
              <ReactMultiSelectCheckboxes
                options={options.colour}
                placeholderButtonLabel="Color"
                onChange={handleColorSelect}
                value={selectedColorList}
              />
            </div>
          </div>
          <div className="sortingDropdown">
            <Dropdown onSelect={handleSortSelect}>
              <Dropdown.Toggle variant="outlined" id="dropdown-basic">
                <TbArrowsSort /> Sort
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="desc">High to low</Dropdown.Item>
                <Dropdown.Item eventKey="asc">Low to high</Dropdown.Item>
                <Dropdown.Item eventKey="position">Recommended</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <InfiniteScroll
          style={{ overflowX: "hidden" }}
          dataLength={dataLength}
          next={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={<SpinnerComponent />}
          scrollThreshold={getScrollThreshold()}
        >
          <Row>
            {data?.pages?.map((group, i) => {
              return (
                <React.Fragment key={`Group_${i}`}>
                  {group?.result.length > 0 ? (
                    group?.result?.map((item) => (
                      <Col lg={4} xl={3} md={6} xs={6} key={item.id} className="mobileTiles">
                        <RentallTile
                          picture={item.fields.src}
                          title={item.fields.title}
                          size={item.fields.size}
                          dress={item.fields.category}
                          rrp={item.fields.retailprice}
                          price={item.fields.rentalprice}
                          isInMyWishList={item.fields.isInMyWishList}
                          productId={parseInt(item.id)}
                          onclick={`/products/${getCategorySlug(
                            item.fields.category
                          )}-${convertTitleToSlug(item.fields.title)}-${item.fields.colour}-${item.id
                            }`}
                        />
                      </Col>
                    ))
                  ) : (
                    <p>No Record Found</p>
                  )}
                </React.Fragment>
              );
            })}
          </Row>
        </InfiniteScroll>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ search }) => {
  const { cloudSearchResult, hasMoreData, params, searchString } = search;
  return { cloudSearchResult, hasMoreData, params, searchString };
};

const mapDispatchToProps = (dispatch) => {
  return {
    CloudSearchActions: bindActionCreators(CloudSearchActions, dispatch),
  };
};
ProductSearchGrid.propTypes = {
  searchString: PropTypes.any,
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductSearchGrid);
