import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useInfiniteQuery } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import RentallTile from "../../common/RentallTile";
import * as ProfileActions from "../../../redux/actions/ProfileActions";
import { bindActionCreators } from "redux";
import { getWardrobe } from "../../../../services/getProductService";
import SpinnerComponent from "../../common/Spinner";
import { getCategorySlug, getScrollThreshold } from "../../../utilities/helpers";
import { convertTitleToSlug } from "../../../../utils/utilities";
import { useNavigate } from "react-router-dom";
import { differenceInDays } from "date-fns";
import firebaseUtils from "../../../../utils/firebaseUtils";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import { toast } from "react-toastify";
import { setLoading } from "../../../redux/actions/messagingActions";
import PropTypes from "prop-types";

const UserProfile = (props) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const otherUserId = urlParams.get("userId");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [imageurl, setImageurl] = useState("");
  const [bio, setBio] = useState("");
  const [lastActiv, setLastActiv] = useState(null);
  const [totalListings, setTotalListings] = useState(0);
  const [totalRentals, setTotalRentals] = useState(0);

  const fetchProducts = async ({ pageParam = 1 }) => {
    let result = await getWardrobe({
      userId: `${otherUserId || props.userId}`,
      limit: 20,
      current: pageParam,
    });
    return result;
  };

  let dataLength = 20;
  const { data, fetchNextPage, hasNextPage, refetch, status } = useInfiniteQuery({
    queryKey: ["wardrobeProducts"],
    queryFn: fetchProducts,
    getNextPageParam: (lastPage, pages) => {
      const totalDataLength = pages.reduce(
        (totalLength, page) => totalLength + page?.data?.length,
        0
      );
      let nextPage = lastPage.hasMore ? lastPage.nextPage : undefined;
      dataLength = totalDataLength;
      return nextPage;
    },
  });

  useEffect(() => {
    setLoading(true);
    props.ProfileActions.getUserProfileById(`${otherUserId || props.userId}`).then((res) => {
      setLoading(false);
      setName(res.data.name);
      setImageurl(res.data.artifactUrl);
      setBio(res.data.bio);
      setLastActiv(res.data.lastActive);
      setTotalListings(res.data.totalListings);
      setTotalRentals(res.data.totalRentals);
      setUsername(res.data.userName);
      setEmail(res.data.email);
    });
    window.scrollTo(0, 0);
    refetch();

  }, [otherUserId]);

  const messageProfileUser = async () => {
    const currentUser = {
      userId: props.userId,
      name: props.userName,
      email: props.email,
    };
    const userDetails = {
      userId: otherUserId,
      userName: username,
      email: email,
      name: name,
    };
    try {
      if (!props.isLoggedIn) {
        navigate("/login");
        return;
      }
      firebaseUtils
        .getChatId(currentUser, userDetails)
        .then((chatId) => {
          navigate("/inbox", { state: { id: chatId, otherUser: userDetails } });
        })
        .catch((error) => {
          logErrorToGrayLogs(
            `function: FirebaseUtils->getChatId profile, platform: Web, client error msg: ${error.stack} 
            user: ${currentUser.email} error: ${error}`
          );
        });
    } catch (error) {
      logErrorToGrayLogs(
        `function: ProductDetailHeader->handleSendMessage profile, platform: Web, client error msg: ${error.stack}
        user: ${currentUser.email} error: ${error}`
      );
    }
  };
  const copyMessage = () => {
    setLoading(true);
    if (navigator.share) {
      setLoading(false);
      navigator.share({
        url: window.location.href,
        title: "Share profile",
      });
    } else {
      setLoading(false);
      navigator.clipboard.writeText(window.location.href);
      toast.success("Profile copied to clipboard!", {
        className: "toastSuccess",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const getDateDifferenceInDays = (date) => {
    return differenceInDays(new Date(), new Date(date));
  };

  const getLastActiveStatus = (date) => {
    const difference = getDateDifferenceInDays(date);
    if (!date) {
      return "";
    }
    let activeStatus = "";
    if (difference <= 0) {
      activeStatus = "Active today";
    } else if (difference <= 1) {
      activeStatus = "Active yesterday";
    } else if (difference <= 7) {
      activeStatus = "Active this week";
    } else if (difference <= 31) {
      activeStatus = " Active this month";
    } else if (difference > 31) {
      activeStatus = "Active over a month ago";
    } else {
      activeStatus = "Unknown";
    }
    return activeStatus;
  };

  return (
    <div className="productTilesGridWrapper wishlistWrapper">
      <Container>

        <div className="pt-5" style={{ maxWidth: "330px" }}>
          <Row>
            <Col style={{ display: "flex" }}>
              {imageurl ? (
                <img src={imageurl} alt="profileImage" className="profileImage" />
              ) : (
                <div className="profileImage">
                  <span>{Array.from(name)[0]}</span>
                </div>
              )}
              <div className="profileUserInfo">
                <span>{name}</span>
                <span>
                  {username && "@"}
                  {username}
                </span>
                <span>
                  <b>{getLastActiveStatus(lastActiv)}</b>
                </span>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: "20px 0" }}>
            <Col>
              <b>{totalListings}</b> listings
            </Col>
            {otherUserId === props.userId && (
              <Col>
                <b>{totalRentals}</b> rentals
              </Col>
            )}
          </Row>
          <Row style={{ margin: "20px 0" }}>
            <Col>{bio}</Col>
          </Row>
          <Row>
            <Col>
              {otherUserId !== props.userId ? (
                <button className="messageButtonPr" onClick={messageProfileUser}>
                  Message
                </button>
              ) : (
                <button className="editButtonPr" onClick={() => navigate("/edit")}>
                  Edit profile
                </button>
              )}
              <button className="messageButtonPr editButtonCancel" onClick={copyMessage}>
                Share profile
              </button>
            </Col>
          </Row>
        </div>
        <div className="profileHeading">Wardrobe</div>
        <InfiniteScroll
          style={{ overflowX: "hidden" }}
          dataLength={dataLength}
          next={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={<SpinnerComponent />}
          scrollThreshold={getScrollThreshold()}
        >
          <Row>
            {!data?.pages[0].data.length && status !== "loading" && <p>No listings.</p>}
            {data?.pages.map((group, i) => {
              return (
                <React.Fragment key={`Group_${i}`}>
                  {group?.data.map((item) => (
                    <Col lg={4} xl={3} md={6} xs={6} key={item.id} className="mobileTiles">
                      <RentallTile
                        picture={item.fields.src}
                        title={item.fields.title}
                        size={item.fields.size}
                        dress={item.fields.category}
                        rrp={item.fields.retailprice}
                        price={item.fields.rentalprice}
                        isInMyWishList={item.isInMyWishList}
                        color={item.fields.colour}
                        category={item.fields.category}
                        resalePrice={item.resaleprice}
                        productId={item.id}
                        refetch={refetch}
                        onclick={`/products/${getCategorySlug(
                          item.fields.category
                        )}-${convertTitleToSlug(item.fields.title)}-${item.fields.colour}-${item.id
                          }`}
                      />
                    </Col>
                  ))}
                </React.Fragment>
              );
            })}
          </Row>
        </InfiniteScroll>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  userId: app.userId,
  email: app.email,
  userName: app.userName,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ProfileActions: bindActionCreators(ProfileActions, dispatch),
  };
};

UserProfile.propTypes = {
  isLoggedIn: PropTypes.bool,
  userId: PropTypes.string,
  email: PropTypes.string,
  userName: PropTypes.any,
  ProfileActions: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
