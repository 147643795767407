import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RentallTile from "../common/RentallTile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCategorySlug, getScrollThreshold } from "../../utilities/helpers";
import * as ExploreActions from "../../redux/actions/ExploreActions";
import * as filterActions from "../../redux/actions/FilterActions";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerComponent from "../common/Spinner";
import FilteredProductsComponent from "../common/FilteredProductsComponent";
import { convertTitleToSlug } from "../../../utils/utilities";
import useInfiniteQueryData from "../../../services/useInfiniteQueryData";
import PropTypes from "prop-types";

const SingleCollection = (props) => {
  const url = window.location.pathname;
  const modifiedUrl = url.split("/");
  let collectionString = modifiedUrl[modifiedUrl.length - 1];
  let result = collectionString.split("-");
  let collectionId = result[result.length - 1];

  useEffect(() => {
    return () => {
      props.filterActions.clearFilters(); // An action to reset Filters values In Redux
    };
  }, []);

  let { fetchNextPage, dataLength, hasNextPage, status, data, refetch } = useInfiniteQueryData({
    filters: props.filters,
    collectionId,
  });

  return (
    <div className="productTilesGridWrapper">
      <Container>
        <FilteredProductsComponent source="collection" />
        {status === "loading" && <SpinnerComponent />}
        {!data?.pages[0].data.length && status !== "loading" && <p>No products matched.</p>}
        <InfiniteScroll
          style={{ overflowX: "hidden" }}
          dataLength={dataLength}
          next={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={<SpinnerComponent />}
          scrollThreshold={getScrollThreshold()}
        >
          <Row>
            {data?.pages.map((group, i) => {
              return (
                <React.Fragment key={`Group_${i}`}>
                  {group?.data.map((item) => (
                    <Col key={item.id} lg={4} xl={3} md={6} xs={6} className="mobileTiles">
                      <RentallTile
                        picture={item.fields.src}
                        title={item.fields.title}
                        size={item.fields.size}
                        dress={item.fields.category}
                        rrp={item.fields.retailprice}
                        price={item.fields.rentalprice}
                        isInMyWishList={item.isInMyWishList}
                        color={item.fields.colour}
                        category={item.fields.category}
                        productId={item.id}
                        refetch={refetch}
                        onclick={`/products/${convertTitleToSlug(item.fields.title)}-${item.fields.colour
                          }-${getCategorySlug(item.fields.category)}-${item.id}`}
                      />
                    </Col>
                  ))}
                </React.Fragment>
              );
            })}
          </Row>
        </InfiniteScroll>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ explore, filters }) => {
  return {
    explore,
    filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ExploreActions: bindActionCreators(ExploreActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
};

SingleCollection.propTypes = {
  filterActions: PropTypes.any,
  filters: PropTypes.any,
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleCollection);
