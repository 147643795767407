import QRCode from "qrcode";
import { useEffect, useRef, useState } from "react";

const QrCode = () => {
  const [text, setText] = useState("");
  const canvasRef = useRef();

  console.log(setText)
  useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      // QR code doesn't work with an empty string
      // so we are using a blank space as a fallback
      text || "https://onelink.to/swished",
      (error) => error && console.error(error)
    );
  }, [text]);

  return (
    <div>
      {/* <input
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <br /> */}

      <canvas ref={canvasRef} />
    </div>
  );
}

export default QrCode