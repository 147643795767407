import axios from "axios";
import Config from "./../../axios/config";
import { APP_ACTIONS } from "../actionType";
import { logErrorToGrayLogs } from "./GrayLogActions";

const { REST_API } = Config;
export const register = (filters) => (dispatch) => {
  return axios
    .post(REST_API.AppActions.RegisterWithoutPhone, filters, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        const { data } = baseModel;
        dispatch({
          type: APP_ACTIONS.REGISTER,
          isLoggedIn: false,
          // bearerToken: data.access_token,
          userId: data.userId,
          // userName: data.user.name,
        });
      }
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(`function: register, platform: Web, client error msg: ${error.message}`);
      return {
        success: false,
      };
    });
};

export const otpValidation = (filters) => (dispatch) => {
  return axios
    .post(REST_API.AppActions.OtpValidation, filters, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        const { data } = baseModel;
        dispatch({
          type: APP_ACTIONS.REGISTER,
          isLoggedIn: true,
          bearerToken: data.access_token,
          userId: data?.user?.userId,
          userName: data.user.name,
          email: data?.user.email,
        });
        localStorage.setItem('userEmail', data.user.email);
      }
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(`function: register, platform: Web, client error msg: ${error.message}`);
      return {
        success: false,
      };
    });
};

export const resendOTPCode = (filters) => (dispatch) => {
  return axios
    .post(REST_API.AppActions.ResendOtpValidation, filters, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      console.log("resend otp", baseModel);
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(`function: ResendOTP, platform: Web, client error msg: ${error.message}`);
      return {
        success: false,
        message: error.message,
      };
    });
};
