import axios from "axios";
import Config from "./../../axios/config";
import { PHONE_VERIFICATION_ACTIONS } from "../actionType";

const { REST_API } = Config;
export const sendPhoneVerificationCode = (phoneNumber, token) => (dispatch) => {
  return axios
    .post(
      REST_API.PhoneNumberVerification.SendPhoneNumberVerification,
      { PhoneNumber: phoneNumber, token: token },
      { headers: { "Content-Type": "application/json; charset=utf-8" } }
    )
    .then((response) => {
      const baseModel = response.data;
      dispatch({
        type: PHONE_VERIFICATION_ACTIONS.SET_PHONE_VERIFICATION_CODE,
        payload: baseModel.data,
      });
      return baseModel;
    })
    .catch((error) => {
      return {
        success: false,
      };
    });
};
export const getPhoneVerificationCode = (phoneNumber) => (dispatch) => {
  return axios
    .post(
      REST_API.PhoneNumberVerification.RefreshPhoneNumberVerification,
      { PhoneNumber: phoneNumber },
      { headers: { "Content-Type": "application/json; charset=utf-8" } }
    )
    .then((response) => {
      const baseModel = response.data;
      dispatch({
        type: PHONE_VERIFICATION_ACTIONS.GET_PHONE_VERIFICATION_CODE,
        payload: baseModel.data,
      });
      return baseModel;
    })
    .catch((error) => {
      return {
        success: false,
      };
    });
};
