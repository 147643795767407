import { useEffect } from "react";
import { getAllProducts } from "./getProductService";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

const useInfiniteQueryData = (props) => {
  let { selectedCategories, selectedColors, selectedSizes, selectedSorting } = props.filters;
  let { collectionId } = props;
  const userId = useSelector((state) => state.app.userId);

  const fetchProducts = async ({ pageParam = 1 }) => {
    let resale = props?.isResale ? "&isResale=true" : "&isResale=false";

    let result = await getAllProducts({
      id: collectionId,
      pageParam,
      UserId: userId || "",
      resale,
      sort: selectedSorting || "Position",
      filter1: selectedCategories,
      filter2: selectedColors,
      filter3: selectedSizes,
    });
    return result;
  };

  let queryKey = collectionId ? [collectionId] : ["products"];
  let dataLength = 40;
  const { data, fetchNextPage, hasNextPage, status, refetch } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: fetchProducts,
    getNextPageParam: (lastPage, pages) => {
      const totalDataLength = pages.reduce(
        (totalLength, page) => totalLength + page?.data?.length,
        0
      );
      let nextPage = lastPage.nextPage;
      dataLength = totalDataLength;
      return nextPage;
    },
    enabled: false,
  });

  const handleFilterChange = () => {
    refetch({ pageParam: 1 });
  };

  useEffect(() => {
    if (
      selectedSorting !== "" ||
      selectedCategories !== null ||
      selectedColors !== null ||
      selectedSizes !== null
    ) {
      handleFilterChange();
    }
  }, [selectedSorting, selectedCategories, selectedColors, selectedSizes]);

  return {
    data,
    dataLength,
    fetchNextPage,
    hasNextPage,
    status,
  };
};

export default useInfiniteQueryData;
