import axios from "axios";
import Config from "./../../axios/config";
import { PRODUCT_ACTIONS } from "../actionType";
import { logErrorToGrayLogs } from "./GrayLogActions";

const { REST_API } = Config;

export const setCurrentPage = (number) => (dispatch) => {
  dispatch({
    type: PRODUCT_ACTIONS.SET_CURRENT_PAGE,
    payload: number,
  });
};

export const getProductDetailById = (productid, userid) => (dispatch) => {
  return axios
    .get(
      `${REST_API.productActions.getProductDetailById}?productId=${productid}&userId=${userid}`,
      { headers: { "Content-Type": "application/json; charset=utf-8" } }
    )
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        {
          const { data } = baseModel;
          dispatch({
            type: PRODUCT_ACTIONS.SET_PDP_DATA,
            payload: data,
          });
        }
      } else {
        const { data } = baseModel;
        logErrorToGrayLogs(
          `function: getProductDetailById, platform: Web, Details:Product Id${productid}, userId${userid}, vendorDetails:${data.swishedUser} client error msg: ${response.message}`
        );
      }
      return baseModel;
    })
    .catch((error, data) => {
      logErrorToGrayLogs(
        `function: getProductDetailById, platform: Web, Details:Product Id${productid}, userId${userid}, vendorDetails:${data.swishedUser} client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const setProductReservationDates = (obj) => (dispatch) => {
  return axios
    .post(REST_API.productActions.setProductReservationDates, obj, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      const { data } = baseModel.data;
      if (baseModel.success) {
        dispatch({
          type: PRODUCT_ACTIONS.SET_RESERVATION,
          payload: baseModel.data,
        });
      } else {
        logErrorToGrayLogs(
          `function: setProductReservationDates:post, platform: Web, Details: startDate:${data.startDate},endDate:${obj.endDate}, userId:${obj.userId}, productId:${obj.productId}, vendorName:${data.swishedProduct.vendor}  client error msg: ${response.message}`
        );
      }
      return baseModel;
    })
    .catch((error, data) => {
      logErrorToGrayLogs(
        `function: setProductReservationDates:post , platform: Web, Details: startDate:${data.startDate},endDate:${obj.endDate}, userId:${obj.userId}, productId:${obj.userId}, vendorName:${data.swishedProduct.vendor} client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const getProductReservationDates = (productid, userid) => (dispatch) => {
  return axios
    .get(
      `${REST_API.productActions.getProductReservationDates}?productId=${productid}&userId=${userid}`,
      { headers: { "Content-Type": "application/json; charset=utf-8" } }
    )
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        return baseModel;
      } else {
        logErrorToGrayLogs(
          `function: getProductReservationDates:get, platform: Web, Details:  userId:${userid}, productId:${productid}, client error msg: ${response.message}`
        );
      }
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: getProductReservationDates:get, platform: Web, userId:${userid}, productId:${productid}, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const clearPdpData = () => (dispatch) => {
  return dispatch({
    type: PRODUCT_ACTIONS.CLEAR_PDP_DATA,
  });
};

export const deleteProductReservationDates = (id) => (dispatch) => {
  return axios
    .delete(`${REST_API.productActions.deleteProductReservationDates}?id=${id}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: deleteProductReservationDates, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const setReturnPdpPath = (path) => (dispatch) => {
  return dispatch({
    type: PRODUCT_ACTIONS.SET_RETURN_PDP_PATH,
    payload: path,
  });
};
