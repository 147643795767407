import axios from 'axios';
import Config from './../../axios/config';

const { REST_API } = Config;

export const logErrorToGrayLogs = payload => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${REST_API.grayLog.grayLogError}?error=${payload}`,
                { headers: { 'Content-Type': 'application/json; charset=utf-8' } },
            )
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
};