import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import config from "./resources/axios/config";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: config.REST_API.Messages.firebaseApiKey,
  authDomain: "swished-f805d.firebaseapp.com",
  projectId: "swished-f805d",
  storageBucket: "swished-f805d.firebasestorage.app",
  messagingSenderId: "6392019528",
  appId: "1:6392019528:web:1c7230ee15b0f6709f367d",
  measurementId: "G-MQ1P4HW1WV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
