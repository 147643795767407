import axios from "axios";
import Config from "../../axios/config";
import { LISTING_ACTIONS } from "../actionType";
import { logErrorToGrayLogs } from "./GrayLogActions";
import { useSelector } from "react-redux";

const { REST_API } = Config;
export const getRecommendedProducts = (userId) => (dispatch) => {
  const userID = userId ? `UserId=${userId}` : "";
  return axios
    .get(`${REST_API.Listing.GetRecommendedProducts}?${userID}&SortBy=${"Position"}&Current=1&Limit=${12}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      dispatch({
        type: LISTING_ACTIONS.GET_RECOMMENDED_PRODUCTS,
        payload: baseModel.data,
      });
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: getRecommendedProducts, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};
