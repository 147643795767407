import React from "react";
import PropTypes from "prop-types";

const Loader = ({ display }) => {
  return (
    <div style={Object.assign({}, { display: `${display}`, })} className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
Loader.propTypes = {
  display: PropTypes.any
}
export default Loader;
