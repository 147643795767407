import axios from 'axios';
import Config from './../../axios/config'
const { REST_API } = Config;

export const getPreSignedUrl = (file, callback) => () =>
    axios.get(`${REST_API.S3Upload.GetPreSignedUrl}?&fileName=${file.name}`,
        { headers: { 'Content-Type': 'application/json; charset=utf-8' } })
        .then((response) => {
            const baseModel = response.data;
            return baseModel;
        })
        .catch((error) => {
            return {
                success: false,
                message: "Messages.general.problem"
            };
        });

export const deleteFile = (files) => () =>
        axios
            .post(REST_API.S3Upload.DeleteFile, files)
            .then((response) => {
                const baseModel = response.data;
                return baseModel;
            })
            .catch((error) => {
                return {
                    success: false,
                    message: "Messages.general.problem"
                };
            });
export const getS3Keys = () => () =>
    axios.get(REST_API.S3Upload.GetS3Keys,
        { headers: { 'Content-Type': 'application/json; charset=utf-8' } 
    })
    .then((response) => {
        const baseModel = response.data;
        return baseModel;
    })
    .catch((error) => {
        return {
            success: false,
            message: "Messages.general.problem"
        };
    }
);