import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

const AppButton = ({ text, disabled, loading, onclick, variant = "" }) => {
  return (
    <div className={`appBtnWrapper ${variant}`}>
      <button onClick={onclick} disabled={disabled}>
        {loading ? <Spinner size="sm" /> : text}
      </button>
    </div>
  );
};

AppButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.any,
  onclick: PropTypes.any,
  loading: PropTypes.any,
};
export default AppButton;
