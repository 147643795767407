import React from "react";
import AppButton from "../../common/AppButton";
import PropTypes from "prop-types";

const OrderDates = (props) => {
  const { arrivedate, arrivemonth, postbackdate, postbackmonth, retailPrice, onclick, selectedDuration } = props
  return (
    <div className="checkoutProductInfo orderDatesWrapper">
      <ul>
        <li>
          Arrives on or before:
          <i>
            {arrivedate} {arrivemonth}
          </i>
        </li>
        <li>
          Post back on or before:
          <i>
            {postbackdate} {postbackmonth}
          </i>
        </li>
      </ul>

      <div className="orderDatesCTA">
        <span>
          <i><strong>&#163;{selectedDuration.price}</strong> total for {selectedDuration.threshold} days</i>
          <i>RRP &#163;{retailPrice}</i>
        </span>

        <span>
          <AppButton text="Select Dates" onclick={onclick} />
        </span>
      </div>
    </div>
  );
};

OrderDates.propTypes = {
  arrivedate: PropTypes.any,
  arrivemonth: PropTypes.any,
  postbackdate: PropTypes.any,
  postbackmonth: PropTypes.any,
  retailPrice: PropTypes.any,
  onclick: PropTypes.any,
  selectedDuration: PropTypes.any,
}
export default OrderDates;
