import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const DeliveryReturns = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contentBodyInner termsOfServicesWrapper">
      <Container>
        <Row>
          <Col>
            <h3> How long do I have the item(s) for? </h3>
            <p>
              You get to borrow each item for 4 days from your rental start
              date. You may be able to borrow the items for longer, simply
              contact the lister directly via the Ownish app to ask them if the
              item is available.
            </p>
            <p>On day 4 you must return the item(s) to the address provided.</p>
            <h3>How do I return the item(s) </h3>
            <p>
              Please return your item to the address provided to you in the
              Rentals section of your app. We highly recommend that you use a
              guaranteed next day service such as Royal Mail Special Delivery
              before 1pm so that the item is insured and the renter gets it back
              swiftly ready for their next rental.
            </p>
            <h3>What happens if I do not return my rental on time?</h3>
            <p>
              If for any reason you don’t manage to post the item on or before
              the rental end date, please let the lister know. Should the lister
              open a dispute, Ownish reserves the right to charge your payment
              method for the extension and any additional fees that have been
              incurred as a result of Ownish being asked to intervene.
            </p>

            <h3>What happens if the item(s) gets lost in the post?</h3>
            <p>
              Please ensure you get proof of postage so that should the item be
              lost in the post you can take it up with the delivery company. You
              are responsible for contacting the delivery company. If the item
              does not get returned to us, we will ask you to pay the resale
              price or the replacement cost whichever is less. Ownish reserves
              the right to charge your payment method for these costs.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DeliveryReturns;
