import axios from 'axios';
import Config from './../../axios/config';
import { logErrorToGrayLogs } from "./GrayLogActions";

const { REST_API } = Config;
export const CheckUserExists = (phoneNumber) => () => {
    return axios
        .get(`${REST_API.AppActions.CheckUserExists}?&phoneNumber=${phoneNumber}`,
            { headers: { 'Content-Type': 'application/json; charset=utf-8' } },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            logErrorToGrayLogs(
                `function: CheckUserExists, platform: Web, client error msg: ${error.message}`,
            );
            return {
                success: false,
            };
        })
}