import { Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { logErrorToGrayLogs } from "../../redux/actions/GrayLogActions";

const NotFound = () => {
  logErrorToGrayLogs(
    `404 Page not found, platform: Web, url:${window.location.href}`
  );
  return (
    <Container>
    <Row>
      <Col  className="notFoundWrapper">
     
     <span className='notFoundInner'>
       <h1>404</h1>
       <h3>Oops, page not found.</h3>
       <Link to="/">Back to home</Link>
     </span>
        
      </Col>
      </Row>
      </Container>
    
  );
}

export default NotFound;