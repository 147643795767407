import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ExploreActions from "../../redux/actions/ExploreActions";
import Sorting from "../views/rentAll/Sorting";
import options from "../views/rentAll/data";
import { Col, Row } from "react-bootstrap";
import TilesFilters from "../views/rentAll/TilesFilters";
import * as productActions from "../../redux/actions/productActions";
import * as filterActions from "../../redux/actions/FilterActions";
import { logErrorToGrayLogs } from "../../redux/actions/GrayLogActions";
import { sendAmplitudeData } from "../../../utils/amplitude";
import PropTypes from "prop-types";

const FilteredProductsComponent = (props) => {
  const { productsFilters, current, source, rentAllFilters, hasPrevious, filterActions } = props;

  const [selectedCateogryOptions, setSelectedCateogryOptions] = useState(null);
  const [selectedCateogryOptionsList, setSelectedCateogryOptionsList] = useState([]);
  const [selectedSizeOptions, setSelectedSizeOptions] = useState(null);
  const [selectedSizeOptionsList, setSelectedSizeOptionsList] = useState([]);
  const [selectedColorOptions, setSelectedColorOptions] = useState(null);
  const [selectedColorOptionsList, setSelectedColorOptionsList] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");

  let categories = "";
  let colors = "";
  let sizes = "";
  let sortBy = "";

  const categoryList = [];
  const colorsList = [];
  const sizesList = [];
  // URL FILTES

  // GET URL PARAMS
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const categoryParam = urlParams.get("category");
    const sortingParam = urlParams.get("sort");
    const sizeParam = urlParams.get("size");
    const colorParam = urlParams.get("color");
    let categories = [];
    if (categoryParam) {
      categories = categoryParam.split("_");
    }
    const sizes = sizeParam?.split("_");
    const colors = colorParam?.split("_");

    const categoryObjects = categories?.map((category) => {
      const matchedCategories = options.category.find(
        (categoryrObj) => categoryrObj.value === category
      );
      if (matchedCategories) {
        return {
          id: matchedCategories.id,
          value: matchedCategories.value,
          label: matchedCategories.label,
        };
      } else {
        return {};
      }
    });
    const sizeObjects = sizes?.map((size) => ({
      value: size,
      label: size,
    }));
    const colorObjects = colors?.map((color) => {
      const matchedColor = options.color.find((colorObj) => colorObj.label === color);
      if (matchedColor) {
        return {
          value: matchedColor.value,
          label: matchedColor.label,
        };
      } else {
        return {};
      }
    });
    selectedSorting(sortingParam);
    selectedCategory(categoryObjects);
    selectedSize(sizeObjects);
    selectedColor(colorObjects);
    props.productActions.clearPdpData();
    props.productActions.setReturnPdpPath("");
    window.scrollTo(0, 0);
  }, []);

  // SET URL PARAMS
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    // Category
    urlSearchParams.delete("category");
    if (selectedCateogryOptions) {
      const extractedValues = selectedCateogryOptions
        .split("&")
        .map((value) => value.split("=")[1])
        .filter((value) => value);
      if (extractedValues.length > 0) {
        const categoryValue = extractedValues.join(",");
        urlSearchParams.set("category", categoryValue);
      }
    }

    // Sorting
    urlSearchParams.delete("sort");
    if (selectedDropdownValue) {
      const extractedValues = selectedDropdownValue;
      urlSearchParams.set("sort", extractedValues);
    }
    // Size
    urlSearchParams.delete("size");
    if (selectedSizeOptions) {
      const extractedValues = selectedSizeOptions
        .split("&")
        .map((value) => value.split("=")[1])
        .filter((value) => value);
      if (extractedValues.length > 0) {
        const sizeValue = extractedValues.join(",");
        urlSearchParams.set("size", sizeValue);
      }
    }
    // Color
    urlSearchParams.delete("color");
    if (selectedColorOptions) {
      const extractedValues = selectedColorOptions
        .split("&")
        .map((value) => value.split("=")[1])
        .filter((value) => value);
      if (extractedValues.length > 0) {
        const colorValues = [...new Set(extractedValues)]; // Remove duplicates using Set
        const colorValue = colorValues.join(",");
        urlSearchParams.set("color", colorValue);
      }
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlSearchParams.toString().replace(/%2C/g, "_")}`
    );
  }, [
    selectedCateogryOptions,
    selectedSizeOptions,
    selectedColorOptions,
    selectedDropdownValue,
    window.location.href,
  ]);

  const selectedCategory = (data) => {
    let result = "";
    const categoryList = [];
    data?.map((obj) => {
      result += "&Categories=" + obj.value;
      categoryList.push(obj);
    });

    setSelectedCateogryOptions(result);
    filterActions.setCategories(result);
    sendAmplitudeData("Rent-Filters", {
      "Event": "User filters the products",
      "Filter by Category": result,
    });
    setSelectedCateogryOptionsList(categoryList);
  };

  const selectedColor = (data) => {
    let result = "";
    const colorsList = [];
    data?.map((obj) => {
      result += "&Colours=" + obj.label;
      colorsList.push(obj);
    });
    setSelectedColorOptions(result);
    filterActions.setColors(result);
    sendAmplitudeData("Rent-Filters", {
      "Event": "User filters the products",
      "Filter by Color": result,
    });
    setSelectedColorOptionsList(colorsList);
  };

  const selectedSorting = (data) => {
    setSelectedDropdownValue(data);
    filterActions.setSorting(data || "Position");
  };

  const selectedSize = (data) => {
    let result = "";
    const sizesList = [];
    data?.map((obj) => {
      result += "&Sizes=" + obj.value;
      sizesList.push(obj);
    });
    setSelectedSizeOptions(result);
    filterActions.setSizes(result);
    setSelectedSizeOptionsList(sizesList);
    sendAmplitudeData("Rent-Filters", {
      "Event": "User filters the products",
      "Filter by Size": result,
    });
  };

  const dropdownValue = (data) => {
    setSelectedDropdownValue(data);
    sendAmplitudeData("Rent-Sort", {
      "Event": "User sorts the order of the products",
      "Sort by": data,
    });
    filterActions.setSorting(data);
  };

  const applyFilters = (item) => {
    if (item !== "") {
      const key = item.split("=")[0];
      const value = item.split("=")[1];
      switch (key) {
        case "Categories":
          categories += "&Categories=" + value;
          const cItem = options.category.find((item) => item.value === value);
          categoryList.push({ id: cItem.id, value: cItem.value, label: cItem.label });
          break;

        case "Colours":
          colors += "&Colours=" + value;
          const colItem = options.color.find((item) => item.label === value);
          colorsList.push({ id: colItem.id, value: colItem.value, label: colItem.label });
          break;

        case "Sizes":
          sizes += "&Sizes=" + value;
          const sItem = options.size.find((item) => item.value === value);
          sizesList.push({ id: sItem.id, value: sItem.value, label: sItem.label });
          break;

        default:
          sortBy = value;
          break;
      }
    }
  };
  const fetchFilteredProducts = async () => {
    if (
      !selectedCateogryOptions &&
        !selectedSizeOptions &&
        !selectedColorOptions &&
        selectedDropdownValue === "" &&
        source === "collections"
        ? productsFilters
        : rentAllFilters
    ) {
      let filtersArray = source === "collections" ? productsFilters : rentAllFilters;
      filtersArray.split("&").map((item) => {
        applyFilters(item);
      });
      setSelectedCateogryOptions(categories);
      setSelectedColorOptions(colors);
      setSelectedSizeOptions(sizes);
      setSelectedDropdownValue(sortBy);

      setSelectedCateogryOptionsList(categoryList);
      setSelectedColorOptionsList(colorsList);
      setSelectedSizeOptionsList(sizesList);
    }
  };

  useEffect(() => {
    if (
      selectedCateogryOptions !== null ||
      selectedSizeOptions !== null ||
      selectedColorOptions !== null ||
      selectedDropdownValue !== ""
    ) {
      fetchFilteredProducts()
        .then(() => { })
        .catch((error) => {
          logErrorToGrayLogs(
            `function: fetchFilteredProducts->FilteredProductsComponent, platform: Web, client error msg:  ${error.message}`
          );
        });
    }
  }, [
    selectedCateogryOptions,
    selectedSizeOptions,
    selectedColorOptions,
    selectedDropdownValue,
    current,
    hasPrevious,
  ]);

  return (
    <div>
      <Row>
        <Col className="productGridfilters" lg={12}>
          <span>
            <TilesFilters
              selectedCategory={selectedCategory}
              selectedColor={selectedColor}
              selectedSize={selectedSize}
              selectedCateogryOptionsList={selectedCateogryOptionsList}
              selectedSizeOptionsList={selectedSizeOptionsList}
              selectedColorOptionsList={selectedColorOptionsList}
            />
          </span>
          <span className="sortingDropdown">
            <Sorting dropdownValue={dropdownValue} />
          </span>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ explore, product, filters }) => {
  return { explore, product, filters };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ExploreActions: bindActionCreators(ExploreActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
};

FilteredProductsComponent.propTypes = {
  productsFilters: PropTypes.any,
  current: PropTypes.any,
  source: PropTypes.any,
  rentAllFilters: PropTypes.any,
  hasPrevious: PropTypes.any,
  filterActions: PropTypes.any,
  productActions: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(FilteredProductsComponent);
