import React from "react";
import { Col, Container, Row } from "react-bootstrap";
const Collections = (props) => {
  return (
    <div className="contentBodyInner">
      <Container>
        <Row>
          <Col>
            <h1>Collections</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Collections;
