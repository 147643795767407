export const LISTING_ACTIONS = {
  GET_RECOMMENDED_PRODUCTS: "GET_RECOMMENDED_PRODUCTS",
};
export const APP_ACTIONS = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  LOGOUT: "LOGOUT",
  INSTA_ACCESSTOKEN: "INSTA_ACCESSTOKEN",
  RESET_CHAT_STATE: "RESET_CHAT_STATE",
};

export const EXPLORE_ACTIONS = {
  HERO_CAROUSEL_PRODUCTS: "HERO_CAROUSEL_PRODUCTS",
  COLLECTIONS: "COLLECTIONS",
  SINGLE_COLLECTION: "SINGLE_COLLECTION",
  CLEAR_SINGLE_COLLECTION_DATA: "CLEAR_SINGLE_COLLECTION_DATA",
  GET_PRODUCTS_ON_SCROLL: "GET_PRODUCTS_ON_SCROLL",
};

export const PHONE_VERIFICATION_ACTIONS = {
  SET_PHONE_VERIFICATION_CODE: "SET_PHONE_VERIFICATION_CODE",
  GET_PHONE_VERIFICATION_CODE: "GET_PHONE_VERIFICATION_CODE",
};

export const SEARCH_ACTIONS = {
  SET_SEARCH: "SET_SEARCH",
  SET_HAS_MORE_SEARCH_DATA: "SET_HAS_MORE_SEARCH_DATA",
  SET_SEARCH_PARAMS: "SET_SEARCH_PARAMS",
  SET_SEARCH_STRING: "SET_SEARCH_STRING",
  RESET_CLOUD_SEARCH_STATE: "RESET_CLOUD_SEARCH_STATE",
};

export const PRODUCT_ACTIONS = {
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_ON_SCROLL: "GET_PRODUCTS_ON_SCROLL",
  SET_PDP_DATA: "SET_PDP_DATA",
  SET_RESERVATION: "SET_RESERVATION",
  CLEAR_PDP_DATA: "CLEAR_PDP_DATA",
  SET_FILTERS: "SET_FILTERS",
  SET_RETURN_PDP_PATH: "SET_RETURN_PDP_PATH",
};

export const FILTERS_ACTIONS = {
  SET_CATEGORIES: "SET_CATEGORIES",
  SET_COLORS: "SET_COLORS",
  SET_SIZES: "SET_SIZES",
  SET_SORT: "SET_SORT",
  CLEAR_FILTERS: "CLEAR_FILTERS",
};

export const DELIVERY_OPTIONS = {
  GET_DELIVERY_OPTIONS: "GET_DELIVERY_OPTIONS",
};
export const MESSAGING = {
  SET_CHAT_IDS: "SET_CHAT_IDS",
  SET_LOADING: "SET_LOADING",
  RESET_CHATS: "RESET_CHATS",
  SET_CHAT_ROOMS: "SET_CHAT_ROOMS",
  FETCH_CHAT_ROOMS: "FETCH_CHAT_ROOMS",
  INBOX_NOTIFICATION_COUNT: "INBOX_NOTIFICATION_COUNT",
  SAVE_CHAT_ID_FROM_NOTIFICATION: "SAVE_CHAT_ID_FROM_NOTIFICATION",
};

export const GIFT_CARDS = {
  SET_STRIPE_INTENT: "SET_STRIPE_INTENT",
  SET_GIFT_CARD_DATA: "SET_GIFT_CARD_DATA",
  CLEAR_STRIPE_INTENT: "CLEAR_STRIPE_INTENT",
};
