import * as types from "../actionType";
const INITIAL_STATE = {
  recommendedProducts: [],
  totalDealer: 0,
};
export default (state, action) => {
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case types.LISTING_ACTIONS.GET_RECOMMENDED_PRODUCTS: {
      return {
        ...state,
        recommendedProducts: action.payload.data,
      };
    }
    case 2:
    case 3:
    default:
      return state;
  }
};
