import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./resources/store/ConfigureStore";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import configureAxios from "./resources/axios/AxiosDefault";
import "../src/resources/css/style.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";
import { ConsentBanner, ConsentProvider } from "react-hook-consent";
import { initAmplitude } from "./utils/amplitude";

ReactGA.initialize("984660962908681");
initAmplitude();

configureAxios(store);
const root = ReactDOM.createRoot(document.getElementById("root"));

const consentOptions = {
  services: [
    {
      id: "myid",
      name: "Essential cookies",
      description:
        "Essential cookies are those strictly necessary to operate our site or allow you to access the content and services requested. This type of cookie cannot be disabled, as this would compromise the functioning of our site or the use of its contents and services. If you do not wish to install this type of cookie on your device, you are advised not to use our site",
      scripts: [],
      mandatory: true,
    },
    {
      id: "myid2",
      name: "Functional cookies",
      description:
        "Functional cookies are used to enable specific site features as well as a number of options (e.g. preferred language, products selected for purchase) in order to improve the service provided. By disabling this type of cookie, certain services or functions of our site may not be available or may not function properly, and you may be forced to modify or manually enter certain information or preferences each time you visit our site.",
      scripts: [],
    },
    {
      id: "myid3",
      name: "Analytical cookies",
      description:
        "Analytical cookies, including third party analytical cookies, help us understand how you navigate our site. These cookies do not contain any information about your identity or any personal data. Information is processed in an aggregated and anonymous way.",
      scripts: [
        { id: "analytical-scripts", src: "/marketingScripts.js" },
        { id: "analytical-scripts", code: ReactGA.initialize("984660962908681") },
      ],
    },
    {
      id: "myid4",
      name: "Advertising cookies",
      description:
        "Advertising (or “Profiling”) cookies, including those from third parties, are cookies aimed at creating user profiles and are used to display advertisements based on your preferences when browsing the web.",
      scripts: [],
    },
  ],
  theme: "light",
};

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <ConsentProvider options={consentOptions}>
          <ConsentBanner
            settings={{ hidden: false, label: "Let me choose", modal: { title: "Cookies" } }}
            decline={{ show: false, label: "No", hidden: true }}
            approve={{ label: "That's ok" }}
          >
            <>
              <h2>Cookies improve your experience</h2>
              We use cookies and similar technologies to give you a personalised shopping
              experience, personalised advertising and to analyse our web traffic. Click ‘That's ok'
              if you'd like to allow all cookies. Alternatively, you can choose which types of
              cookies you'd like to accept or disable by clicking 'Let me choose' below. For more
              information, please see our
              <a href="/privacy-policy"> Privacy Policy.</a>
            </>
          </ConsentBanner>
          <App />
        </ConsentProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
