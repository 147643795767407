import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RentallTile from "../../common/RentallTile";
import * as CloudSearchActions from "../../../redux/actions/CloudSearchActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../../redux/actions/productActions";
import * as filterActions from "../../../redux/actions/FilterActions";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerComponent from "../../common/Spinner";
import { getScrollThreshold, getCategorySlug } from "../../../utilities/helpers";
import FilteredProductsComponent from "../../common/FilteredProductsComponent";
import { convertTitleToSlug } from "../../../../utils/utilities";
import useInfiniteQueryData from "../../../../services/useInfiniteQueryData";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import PropTypes from "prop-types";

const ProductTilesGrid = (props) => {
  const { isResale } = props;

  useEffect(() => {
    props.productActions.clearPdpData();
    props.productActions.setReturnPdpPath("");
    sendAmplitudeData("RentAll-View", {
      Event: "User views RentAll Page",
    });
    return () => {
      props.filterActions.clearFilters(); // An action to reset Filters values In Redux
    };
  }, []);

  let { fetchNextPage, dataLength, hasNextPage, status, data } = useInfiniteQueryData({
    filters: props,
    isResale,
  });

  return (
    <div className="productTilesGridWrapper">
      <Container>
        <h1 className="metaHeading">See all the outfits available to rent at affordable prices</h1>
        <FilteredProductsComponent source="rentall" isResale={isResale} />
        {status === "loading" && <SpinnerComponent />}
        {!data?.pages[0].data.length && status !== "loading" && <p>No products matched.</p>}
        <InfiniteScroll
          style={{ overflowX: "hidden" }}
          dataLength={dataLength}
          next={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={<SpinnerComponent />}
          scrollThreshold={getScrollThreshold()}
        >
          <Row>
            {data?.pages.map((group, i) => {
              return (
                <React.Fragment key={`Group_${i}`}>
                  {group?.data.map((item) => (
                    <Col lg={4} xl={3} md={6} xs={6} key={item.id} className="mobileTiles">
                      <RentallTile
                        picture={item.fields.src}
                        title={item.fields.title}
                        size={item.fields.size}
                        dress={item.fields.category}
                        rrp={item.fields.retailprice}
                        price={item.fields.rentalprice}
                        isInMyWishList={item.isInMyWishList}
                        color={item.fields.colour}
                        category={item.fields.category}
                        isResale={isResale}
                        resalePrice={item.resaleprice}
                        productId={item.id}
                        onclick={`/products/${getCategorySlug(
                          item.fields.category
                        )}-${convertTitleToSlug(item.fields.title)}-${item.fields.colour}-${item.id
                          }`}
                      />
                    </Col>
                  ))}
                </React.Fragment>
              );
            })}
          </Row>
        </InfiniteScroll>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ search, product, filters }) => {
  const { cloudSearchResult } = search;
  const { filteredProducts, hasMoreData, rentAllFilters } = product;
  const { clearFilters, selectedCategories, selectedColors, selectedSizes, selectedSorting } =
    filters;
  return {
    cloudSearchResult,
    filteredProducts,
    hasMoreData,
    rentAllFilters,
    clearFilters,
    selectedCategories,
    selectedColors,
    selectedSizes,
    selectedSorting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActions: bindActionCreators(productActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
    CloudSearchActions: bindActionCreators(CloudSearchActions, dispatch),
  };
};

ProductTilesGrid.propTypes = {
  productActions: PropTypes.any,
  filterActions: PropTypes.any,
  isResale: PropTypes.any,
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductTilesGrid);
