import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import UAParser from "ua-parser-js";
import { useParams } from "react-router-dom";

const Profile = () => {
  let { id } = useParams();
  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    const isAndroid = result.os.name === "Android";
    const isiOS = result.os.name === "iOS";

    const appUrl = "ownish://userprofile/" + id;

    const androidStoreUrl = "https://play.google.com/store/apps/details?id=co.uk.swished"; // Replace with your app's Play Store URL
    const iosStoreUrl = "https://apps.apple.com/gb/app/swished/id1600685718"; // Replace with your app's App Store URL

    try {
      // Attempt to open the app using the custom URL scheme
      let deepLink = document.createElement("a");
      deepLink.setAttribute("href", appUrl);
      document.body.appendChild(deepLink);
      deepLink.click();
    } catch (e) {
      console.error("Error opening app", e);
    }

    setTimeout(() => {
      // Fallback to store URL if the app doesn't open
      if (isAndroid) {
        window.location.href = androidStoreUrl;
      } else if (isiOS) {
        window.location.href = iosStoreUrl;
      } else {
        window.location.href = "/explore";
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="signinWrapper">
      <Container></Container>
    </div>
  );
};

export default Profile;
