import React from "react";
import Tag from "./Tag";
import Radio from "../Radio";
import { CiClock1 } from "react-icons/ci";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const DeliveryDetail = (props) => {
  const {
    deliveryOptions,
    handleDeliveryRadioChange,
    selectedDelivery,
    onChangeValue,
    isBillingAddress,
    setIsBillingAddress,
    deliveryErrors,
    isPurchased,
  } = props;

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    onChangeValue(name, value);
  };

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
      const handleWindowResize = () => {
        setWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return { width };
  };

  const { width } = useViewport();
  const breakpoint = 520;

  return (
    <div className="checkoutInnerCol">
      <h2>Delivery details</h2>

      <Tag
        icon={<CiClock1 />}
        classname="deliveryDetailTag"
        text={
          isPurchased
            ? "Once an item is purchased, the sale is final and refunds are not permitted. If you have any questions about the item and its condition, please message the lister in advance of purchasing."
            : "We will reserve your rental dates for 10 minutes whilst you add your payment details."
        }
      />

      {width > breakpoint && (
        <div className="webDeliveryOptions">
          <span className="tagHeading">Delivery</span>
          <span className="package">
            {deliveryOptions?.map((item) => {
              return (
                <span className="radioInnerWrapper" key={item.id}>
                  <Radio
                    type="radio"
                    label={item.option}
                    name="deliveryOption"
                    id={item.id}
                    onChange={handleDeliveryRadioChange}
                    key={item.id}
                    checked={selectedDelivery === item.id}
                  />
                  &#163;{item.value}
                </span>
              );
            })}
          </span>
        </div>
      )}
      <span className="checkoutFullname">
        <h3>Name for delivery</h3>
        <div className="did-floating-label-content chechoutDeliveryDetail">
          <input
            className="did-floating-input"
            type="text"
            placeholder=" "
            name="name"
            onChange={onChangeHandler}
          />
          <label htmlFor="fullName" className="did-floating-label">Full name</label>
          <span className="formErrorMsg">{deliveryErrors.name?.message}</span>
        </div>
      </span>
      <span className="checkoutFullname">
        <h3>Delivery address</h3>

        <div className="did-floating-label-content chechoutDeliveryDetail">
          <input
            className="did-floating-input"
            type="text"
            placeholder=" "
            name="daAddressLine1"
            onChange={onChangeHandler}
          />
          <label htmlFor="address line 1" className="did-floating-label">Address Line 1</label>
          <span className="formErrorMsg">{deliveryErrors.daAddressLine1?.message}</span>
        </div>
        <div className="did-floating-label-content chechoutDeliveryDetail">
          <input
            className="did-floating-input"
            type="text"
            placeholder=" "
            name="daAddressLine2"
            onChange={onChangeHandler}
          />
          <label htmlFor="address line 2" className="did-floating-label">Address Line 2</label>
        </div>
        <div className="did-floating-label-content chechoutDeliveryDetail">
          <input
            className="did-floating-input"
            type="text"
            placeholder=" "
            name="daCountry"
            defaultValue="United Kingdom"
            onChange={onChangeHandler}
            disabled={true}
          />
          <label htmlFor="country" className="did-floating-label">Country</label>
          <span className="formErrorMsg">{deliveryErrors.daCountry?.message}</span>
        </div>
        <div className="checoutCityPostalcode">
          <div className="did-floating-label-content chechoutDeliveryDetail">
            <input
              className="did-floating-input"
              type="text"
              placeholder=" "
              name="daTownOrCity"
              onChange={onChangeHandler}
            />
            <label htmlFor="town" className="did-floating-label">Town or city</label>
            <span className="formErrorMsg">{deliveryErrors.daTownOrCity?.message}</span>
          </div>
          <div className="did-floating-label-content chechoutDeliveryDetail">
            <input
              className="did-floating-input"
              type="text"
              placeholder=" "
              name="daPostalCode"
              onChange={onChangeHandler}
            />
            <label htmlFor="postalcode" className="did-floating-label">Postal code</label>
            <span className="formErrorMsg">{deliveryErrors.daPostalCode?.message}</span>
          </div>
        </div>

        <Form.Check
          inline
          id="switch1"
          className="pl-5"
          style={{ marginTop: "1rem", fontSize: "1rem", fontWeight: "600" }}
        >
          <Form.Check
            aria-label="option 1"
            onChange={() => setIsBillingAddress(!isBillingAddress)}
          />
          <Form.Check.Label>Billing address is different to delivery</Form.Check.Label>
        </Form.Check>

        {isBillingAddress && (
          <>
            <div className="did-floating-label-content chechoutDeliveryDetail">
              <input
                className="did-floating-input"
                type="text"
                placeholder=" "
                name="billingAddressLine1"
                onChange={onChangeHandler}
              />
              <label htmlFor="address line 1" className="did-floating-label">Address Line 1</label>
              <span className="formErrorMsg">{deliveryErrors.billingAddressLine1?.message}</span>
            </div>
            <div className="did-floating-label-content chechoutDeliveryDetail">
              <input
                className="did-floating-input"
                type="text"
                placeholder=" "
                name="billingAddressLine2"
                onChange={onChangeHandler}
              />
              <label htmlFor="address line 2" className="did-floating-label">Address Line 2</label>
            </div>
            <div className="did-floating-label-content chechoutDeliveryDetail">
              <input
                className="did-floating-input"
                type="text"
                placeholder=" "
                name="billingCountry"
                defaultValue="United Kingdom"
                onChange={onChangeHandler}
                disabled={true}
              />
              <label htmlFor="country" className="did-floating-label">Country</label>
              <span className="formErrorMsg">{deliveryErrors.billingCountry?.message}</span>
            </div>
            <div className="checoutCityPostalcode">
              <div className="did-floating-label-content chechoutDeliveryDetail">
                <input
                  className="did-floating-input"
                  type="text"
                  placeholder=" "
                  name="billingTownOrCity"
                  onChange={onChangeHandler}
                />
                <label htmlFor="town" className="did-floating-label">Town or city</label>
                <span className="formErrorMsg">{deliveryErrors.billingTownOrCity?.message}</span>
              </div>
              <div className="did-floating-label-content chechoutDeliveryDetail">
                <input
                  className="did-floating-input"
                  type="text"
                  placeholder=" "
                  name="billingPostalCode"
                  onChange={onChangeHandler}
                />
                <label htmlFor="postalcode" className="did-floating-label">Postal code</label>
                <span className="formErrorMsg">{deliveryErrors.billingPostalCode?.message}</span>
              </div>
            </div>
          </>
        )}
      </span>
    </div>
  );
};

DeliveryDetail.propTypes = {
  deliveryOptions: PropTypes.any,
  handleDeliveryRadioChange: PropTypes.any,
  selectedDelivery: PropTypes.any,
  onChangeValue: PropTypes.any,
  isBillingAddress: PropTypes.any,
  setIsBillingAddress: PropTypes.any,
  deliveryErrors: PropTypes.any,
  isPurchased: PropTypes.any,
}

export default DeliveryDetail;
