import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { user } from "../../../images/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AppActions from "./../../../redux/actions/ProfileActions";
import * as S3Actions from "./../../../redux/actions/S3Actions";
import { toast } from "react-toastify";
import { userNameRegex } from "./../../../utilities/helpers";
import S3 from "react-aws-s3";
import * as buffer from "buffer";
import Config from "./../../../axios/config";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import PropTypes from "prop-types";

window.Buffer = buffer.Buffer;

const schema = yup
  .object({
    location: yup.string(),
    bio: yup.string(),
    userName: yup
      .string()
      .matches(
        userNameRegex,
        "Your username can only include letters, numbers, @ or _ it can't have spaces or start with a number."
      )
      .required("User name must not be empty"),
  })
  .required();

const RegisterProfile = (props) => {
  const navigate = useNavigate();
  const [profilePicturePath, setProfilePicturePath] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const registerProfile = {
      userId: props.userId,
      userName: data.userName,
      location: data.location,
      bio: data.bio,
      artifactUrl: profilePicturePath,
    };
    props.AppActions.RegisterProfile(registerProfile).then((response) => {
      if (response.success) {
        toast.success("Profile registered successfully!", {
          className: "toastSuccess",
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/login");
        sendAmplitudeData("Sign_Up-Success", {
          Event: "User succeded to sign up",
        });
      } else {
        toast.error(response.message, {
          className: "toastError",
          position: toast.POSITION.TOP_CENTER,
        });
        sendAmplitudeData("Sign_Up-Fail", {
          "Event": "User fails to sign up",
          "Reason for failure": response.message,
        });
        logErrorToGrayLogs(
          `function: signinUser, platform: Web, client error msg: ${response.message}`
        );
      }
    });
  };

  const photoUpload = (e) => {
    const file = e.target.files[0];
    props.S3Actions.getS3Keys().then((response) => {
      const config = {
        bucketName: response.data.bucketName,
        dirName: response.data.keyFolder,
        region: response.data.awsRegion,
        accessKeyId: response.data.awsAccessKeyID,
        secretAccessKey: response.data.awsSecretAccessKey,
        s3Url: Config.S3_URL,
      };
      const ReactS3Client = new S3(config);
      if (file.size < 2097152) {
        ReactS3Client.uploadFile(file, file.name)
          .then((data) => setProfilePicturePath(data.location))
          .catch((err) => console.error(err));
        sendAmplitudeData("Profile Picture Uploaded Successfully", {
          Event: "User Upload Image",
        });
      } else {
        toast.error("File Size Limit is 2MB");
        logErrorToGrayLogs(
          `function: S3ImageUpload, platform: Web, client error msg: ${response.message}`
        );
      }
    });
  };

  return (
    <div className="signinWrapper regProfileWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="loginWrapper">
              <h3>Step 3 of 3</h3>
              <Row>
                <Col>
                  <p>Hi, {props.fName} welcome to the Ownish community!</p>
                  <p>You're almost finished - you can set your profile now, or do it later.</p>
                  <p>Add a profile picture (optional)</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label htmlFor="photo-upload" className="custom-file-upload fas">
                    <div className="img-wrap img-upload">
                      <img
                        htmlFor="photo-upload"
                        src={profilePicturePath !== "" ? profilePicturePath : user}
                        alt="profile"
                      />
                    </div>
                    <input
                      id="photo-upload"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={photoUpload}
                    />
                  </label>
                </Col>
              </Row>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={12}>
                    <div className="did-floating-label-content">
                      <input
                        {...register("userName")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                        defaultValue={"@" + props.userName}
                      />
                      <label htmlFor="username" className="did-floating-label">Username</label>
                      <span className="formErrorMsg">{errors.userName?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      <input
                        {...register("location")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="location" className="did-floating-label">Location (Optional)</label>
                      <span className="formErrorMsg">{errors.location?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      <input
                        {...register("bio")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="Bio" className="did-floating-label">Bio (Optional)</label>
                    </div>
                  </Col>
                </Row>
                <span className="appBtnWrapperInner">
                  <input type="submit" value="Create Profile" />
                </span>
                <span className="noAccountbtnWrapper">
                  <Link to="/login">skip </Link> for now
                </span>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AppActions: bindActionCreators(AppActions, dispatch),
    S3Actions: bindActionCreators(S3Actions, dispatch),
  };
};

const mapStateToProps = ({ app }) => {
  const { isLoggedIn, userId } = app;
  return { isLoggedIn, userId };
};

RegisterProfile.propTypes = {
  isLoggedIn: PropTypes.any,
  userId: PropTypes.any,
  userName: PropTypes.any,
  fName: PropTypes.any,
  AppActions: PropTypes.any,
  S3Actions: PropTypes.any,
  returnPdpPath: PropTypes.any
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterProfile);
