import axios from 'axios';
import Config from './../../axios/config'
import { DELIVERY_OPTIONS } from '../actionType';

const { REST_API } = Config;

// get product By search term
export const getDeliveryOptions = (string) => (dispatch) => {
    return axios
        .get(`${REST_API.DeliveryOptions.GetDeliveryDetails}`,
            { headers: { 'Content-Type': 'application/json; charset=utf-8' } },)
        .then((response) => {
            const baseModel = response.data;
            if (baseModel.success) {
                const { data } = baseModel;
                dispatch({
                    type: DELIVERY_OPTIONS.GET_DELIVERY_OPTIONS,
                    payload: data,
                });
            }

            return baseModel;
        })
        .catch((error) => {
            return {
                success: false,
            };
        })
};