import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { AiOutlineSafety, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsCalendarCheck } from "react-icons/bs";
import Radio from "../Radio";
import CollapsableTag from "./CollapsableTag";
import PaymentDetail from "./PaymentDetail";
import ProductInfo from "./ProductInfo";
import Tag from "./Tag";
import AppButton from "../AppButton";
import PropTypes from "prop-types";

const Rentaldetail = (props) => {
  const {
    deliveryOptions,
    handleDeliveryRadioChange,
    selectedDelivery,
    productName,
    productPicture,
    productSize,
    arriveDate,
    arriveMonth,
    postbackDate,
    postbackMonth,
    rentalPrice,
    selectedDeliveryText,
    selectedDeliveryAmount,
    stressFreePrice,
    selectedStressFree,
    calculatedStressFreePrice,
    setStressFreePrice,
    setSelectedStressFree,
    discountPrice,
    totalChargeable,
    transactionFee,
    handleCoupon,
    discountMessage,
    setDiscountMessage,
    isPurchased,
    resalePrice,
    useReferralCredit,
    setDiscount,
    referralDiscount,
    setUseReferralCredit,
    discount,
    userReferralCredit,
  } = props;

  const [isCollapsable, setIsCollapsable] = useState(false);
  const [couponText, setCouponText] = useState(null);

  const collapsableIcon = !isCollapsable ? <AiOutlinePlus /> : <AiOutlineMinus />;

  const couponButtonText = discountPrice && discount.type === "code" ? "Clear" : "Apply";

  const couponButtonAction =
    discountPrice && discount.type === "code"
      ? () => {
        setCouponText("");
        handleCoupon(null);
        setDiscountMessage({
          show: false,
          isError: false,
          text: "",
        });
      }
      : () => handleCoupon(couponText);

  const handleRadioChange = (e) => {
    const id = e.target.id;
    if (id === "yes") {
      setStressFreePrice(calculatedStressFreePrice);
      setSelectedStressFree("yes");
    } else {
      setStressFreePrice(0);
      setSelectedStressFree("no");
    }
  };

  const onChangeHandler = (e) => {
    const value = e.target.value;
    setCouponText(value);
  };

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
      const handleWindowResize = () => {
        setWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return { width };
  };

  const { width } = useViewport();
  const breakpoint = 520;

  return (
    <div className="checkoutInnerCol">
      <h2>{isPurchased ? "Resale" : "Rental"} details</h2>
      <ProductInfo
        name={productName}
        picture={productPicture}
        size={productSize}
        arrivedate={arriveDate}
        arrivemonth={arriveMonth}
        postbackdate={postbackDate}
        postbackmonth={postbackMonth}
        isPurchased={isPurchased}
      />

      {width <= breakpoint && (
        <div className="mobileDeliveryOptions">
          <span className="tagHeading">Delivery</span>
          <span className="package">
            {deliveryOptions?.map((item) => {
              return (
                <span className="radioInnerWrapper" key={item.id}>
                  <Radio
                    type="radio"
                    label={item.option}
                    name="deliveryOption"
                    id={item.id}
                    onChange={handleDeliveryRadioChange}
                    key={item.id}
                    checked={selectedDelivery === item.id}
                  />
                  &#163;{item.value}
                </span>
              );
            })}
          </span>
        </div>
      )}

      {!isPurchased ? (
        <>
          <span className="tagHeading">Stress-free renting</span>
          <Tag
            icon={<AiOutlineSafety />}
            classname="rentDetailTag"
            text="Accidents rarely happen, but if they do, with this option cover we'll take care of it by paying for the cost of repair up to  £100. This doesn't cover loss, deliberate or irreparable damage."
          />
          <span className="radioInnerWrapper">
            <Radio
              type="radio"
              label="Yes, please"
              name="stressFreeOptions"
              id="yes"
              onChange={handleRadioChange}
              checked={selectedStressFree === "yes"}
            />
            &#163;{calculatedStressFreePrice}
          </span>
          <span className="radioInnerWrapper">
            <Radio
              type="radio"
              label="No, I won't need it"
              name="stressFreeOptions"
              id="no"
              onChange={handleRadioChange}
              checked={selectedStressFree === "no"}
            />
          </span>
          <span>
            <CollapsableTag
              text="Promo or referral code?"
              icon={collapsableIcon}
              onclick={() => {
                setIsCollapsable(!isCollapsable);
                setDiscountMessage({ show: false, isError: false, text: "" });
              }}
            />
            {isCollapsable && (
              <>
                <div className="did-floating-label-content promoCodeWrapper">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=" "
                    style={{ marginBottom: "1rem" }}
                    onChange={onChangeHandler}
                    value={couponText}
                  />
                  <label htmlFor="enterCode" className="did-floating-label">Enter code</label>
                  <AppButton text={couponButtonText} onclick={couponButtonAction} />
                </div>
                {discountMessage.show && (
                  <span
                    className={`formErrorMsg ${discountMessage.isError ? "promoCodeError" : "promoCodeSuccess"
                      } `}
                  >
                    {discountMessage.text}
                  </span>
                )}
              </>
            )}
          </span>
          {userReferralCredit && userReferralCredit >= 10 && totalChargeable >= 30 ? (
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Use Ownish credit"
                onChange={() => {
                  if (!useReferralCredit) {
                    setDiscount({
                      ...referralDiscount,
                      type: "referralCredit",
                    });
                    setCouponText("");
                  } else {
                    setDiscount({
                      discountCode: "",
                      discountValue: 0,
                      id: "",
                      type: "",
                    });
                  }
                  setDiscountMessage({
                    show: false,
                    isError: false,
                    text: "",
                  });
                  setUseReferralCredit(!useReferralCredit);
                }}
                value={useReferralCredit}
                checked={useReferralCredit}
              />
            </Form>
          ) : null}
        </>
      ) : null}

      <PaymentDetail
        price={isPurchased ? resalePrice : rentalPrice?.price}
        discount={discountPrice}
        selectedDeliveryAmount={selectedDeliveryAmount}
        selectedDeliveryText={selectedDeliveryText}
        freerenting={stressFreePrice}
        transactionfee={transactionFee}
        total={totalChargeable}
        isPurchased={isPurchased}
      />

      <Tag
        icon={<BsCalendarCheck />}
        text={`Payment won't be taken until the lister accepts your ${isPurchased ? "request" : "rental"
          }. Any transaction you see in your account is pending and won't be processed until your ${isPurchased ? "request" : "rental"
          } is confirmed.`}
      />
    </div>
  );
};

Rentaldetail.propTypes = {
  deliveryOptions: PropTypes.any,
  handleDeliveryRadioChange: PropTypes.any,
  selectedDelivery: PropTypes.any,
  productName: PropTypes.any,
  productPicture: PropTypes.any,
  productSize: PropTypes.any,
  arriveDate: PropTypes.any,
  arriveMonth: PropTypes.any,
  postbackDate: PropTypes.any,
  postbackMonth: PropTypes.any,
  rentalPrice: PropTypes.any,
  selectedDeliveryText: PropTypes.any,
  selectedDeliveryAmount: PropTypes.any,
  stressFreePrice: PropTypes.any,
  selectedStressFree: PropTypes.any,
  calculatedStressFreePrice: PropTypes.any,
  setStressFreePrice: PropTypes.any,
  setSelectedStressFree: PropTypes.any,
  discountPrice: PropTypes.any,
  totalChargeable: PropTypes.any,
  transactionFee: PropTypes.any,
  handleCoupon: PropTypes.any,
  discountMessage: PropTypes.any,
  setDiscountMessage: PropTypes.any,
  isPurchased: PropTypes.any,
  resalePrice: PropTypes.any,
  useReferralCredit: PropTypes.any,
  setDiscount: PropTypes.any,
  referralDiscount: PropTypes.any,
  setUseReferralCredit: PropTypes.any,
  discount: PropTypes.any,
  userReferralCredit: PropTypes.any,
}
export default Rentaldetail;
