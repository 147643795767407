import React from 'react';
import PropTypes from "prop-types";

function CollapsableTag({ icon, text, onclick }) {
    return (
        <div className='collapsableWrapper'>
            <span>{text}</span>
            <span onClick={onclick}>{icon}</span>
        </div>
    );
}

CollapsableTag.propTypes = {
    icon: PropTypes.any,
    text: PropTypes.any,
    onclick: PropTypes.any
}

export default CollapsableTag;