import React, {useEffect} from "react";
import { Col, Container, Row } from "react-bootstrap";

const ContactUs = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contentBodyInner termsOfServicesWrapper contactWrapper">
      <Container>
        <Row>
          <Col>
            <h3  style={{textAlign: "center", marginBottom: "2rem"}}>HOW TO GET IN TOUCH </h3>
            <p>
              If you have any questions about our items, rentals in general,
              your order, or you'd like to make money from your wardrobe renting
              out your clothes, get in touch via the form, email or Insta:
            </p>
            <p>
            Email: <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a> <br />
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
