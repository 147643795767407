import * as yup from "yup";
import { addMonths, format } from "date-fns";

import { emailRegex, nameRegex } from "../../../utilities/helpers";

export const giftCardForm = yup.object({
  giftAmount: yup
    .number()
    .required("Amount to gift is required")
    .min(1, "Please select an option from amount to gift"),
  recipientName: yup
    .string()
    .required("Recipient's name is required")
    .matches(nameRegex, "Please enter valid name"),
  recipientEmail: yup
    .string()
    .email("Please enter valid email")
    .required("Recipient's email is required")
    .matches(emailRegex, "Please enter valid email"),
  senderName: yup
    .string()
    .required("Sender's name is required")
    .matches(nameRegex, "Please enter valid name"),
  senderEmail: yup
    .string()
    .email("Please enter valid email")
    .required("Sender's email is required")
    .matches(emailRegex, "Please enter valid email"),
  deliveryDate: yup
    .date()
    .required("Delivery date for gift card is required")
    .min(format(new Date(), "yyyy-MM-dd"), "Provided date cannot be older than today")
    .max(format(addMonths(new Date(), 2), "yyyy-MM-dd"), "Provided date cannot exceed two months from today")
    .typeError("Provided delivery date is not in correct format"),
});
