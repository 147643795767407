import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AppActions from "../../../redux/actions/InstaActions";
import { InstagramGallery } from "instagram-gallery";
import PropTypes from "prop-types";

const InstaFeed = (props) => {
  useEffect(() => {
    props.AppActions.getInstaAccessToken();
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as per your needs
    };

    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const count = isMobile ? 6 : 8;

  return (
    <div className="instaFeedWrapper">
      <InstagramGallery
        accessToken="IGQWRONVMzMWdaMEthLV9NOWcyby1kMWx5anlFT3pWU3RqV0FobDE0WVFGZAzVwWDRKaGt2ZADI2WnBEb2hUbzNickdQLW5xd0VaejRvOWktYVlWbFVGcDl1cTk2ZA3hlX280WTFIOTlfUHJYWDktS2p3THZAsbnZAhNFUZD"
        count={count}
      />
    </div>
  );
};

const mapStateToProps = ({ app }) => {
  const { insta_access_token } = app;
  return { insta_access_token };
};

const mapDispatchToProps = (dispatch) => {
  return { AppActions: bindActionCreators(AppActions, dispatch) };
};

InstaFeed.propTypes = {
  AppActions: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstaFeed);
