import { combineReducers } from "redux";
import AppReducer from "./AppReducer";
import ListingReducer from "./ListingReducer";
import searchReducer from "./searchReducer";
import productReducer from "./productReducer";
import DeliveryOptionsReducer from "./DeliveryOptionsReducer";
import { MessagingReducer } from "./messagingReducer";
import ExploreReducer from "./ExploreReducer";
import FilterReducer from "./FilterReducer";
import GiftCardReducer from "./GiftCardReducer";

export default combineReducers({
  listing: ListingReducer,
  app: AppReducer,
  search: searchReducer,
  product: productReducer,
  delivery: DeliveryOptionsReducer,
  messaging: MessagingReducer,
  explore: ExploreReducer,
  filters: FilterReducer,
  gift: GiftCardReducer,
});
