import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AppActions from "../../redux/actions/AppActions";
import ToggleWishlistButton from "./ToggleWishlistButton";
import * as productActions from "../../redux/actions/productActions";
import PropTypes from "prop-types";

const RentallTile = (props) => {
  const {
    picture,
    title,
    size,
    price,
    rrp,
    dress,
    isInMyWishList,
    onclick,
    resalePrice,
    productId,
    refetch,
  } = props;
  const rentalPricePerDay = price % 4 === 0 ? price / 4 : (price / 4).toFixed(2);

  return (
    <Link style={{ color: "black", textDecoration: "none" }} to={onclick}>
      <div className="productTileWrapper">
        <span className="dressPicture">
          <img src={picture} alt="dress" />
        </span>

        <ToggleWishlistButton
          productId={productId}
          isInMyWishList={isInMyWishList}
          refetch={refetch}
          productActions={props.productActions.setReturnPdpPath}
        />

        <span className="productTitle">{title}</span>
        <span className="productSize">
          {dress}, Size {size}
        </span>
        <span className="productPrice">
          <i>£{rentalPricePerDay}</i> per day
        </span>
        {resalePrice ? <span className="productRRP">Buy £{resalePrice}</span> : null}
        <span className="productRRP">RRP £{rrp}</span>
      </div>
    </Link>
  );
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  userId: app.userId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AppActions: bindActionCreators(AppActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
  };
};


RentallTile.propTypes = {
  picture: PropTypes.any,
  title: PropTypes.any,
  size: PropTypes.any,
  price: PropTypes.any,
  rrp: PropTypes.any,
  dress: PropTypes.any,
  isInMyWishList: PropTypes.any,
  onclick: PropTypes.any,
  resalePrice: PropTypes.any,
  productId: PropTypes.any,
  refetch: PropTypes.any,
  productActions: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(RentallTile);
