import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as ProfileActions from "../../../redux/actions/ProfileActions";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";


const EditProfile = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    props.ProfileActions.getUserProfileById(`${props.userId}`).then((res) => {
      setName(res.data.name);
      setBio(res.data.bio);
      setUsername(res.data.userName);
      setPhoneNumber(res.data.phoneNumber);
      setEmail(res.data.email);
    });
  }, []);

  const data = {
    userId: props.userId,
    userName: username,
    email: email,
    name: name,
    phoneNumber: phoneNumber,
    bio: bio,
  }

  const confirmedits = () => {
    props.ProfileActions.EditProfile(data).then((response) => {
      if (response.success) {
        toast.success("Profile updated successfully!", {
          className: "toastSuccess",
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/");
      } else {
        toast.error(response.message, {
          className: "toastError",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    })
  }


  return (
    <div className="productTilesGridWrapper wishlistWrapper">
      <Container>
        <div style={{ width: "50%" }}>
          <Row>
            <Col xs={12} md={12}>
              <h3>Edit Profile</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="did-floating-label-content">
                <input
                  value={name}
                  className="did-floating-input"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
                <label className="did-floating-label">Name</label>
              </div>
              <div className="did-floating-label-content">
                <input
                  value={username}
                  className="did-floating-input"
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label className="did-floating-label">Username</label>
              </div>
              <div className="did-floating-label-content">
                <input
                  value={bio}
                  className="did-floating-input"
                  type="text"
                  onChange={(e) => setBio(e.target.value)}
                />
                <label className="did-floating-label">Bio</label>
              </div>
              <button className="editButtonPr"
                onClick={confirmedits}>confirm</button>
              <button className="editButtonPr editButtonCancel"
                onClick={() => navigate("/")}>cancel</button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  userId: app.userId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ProfileActions: bindActionCreators(ProfileActions, dispatch),
  };
};

EditProfile.propTypes = {
  userId: PropTypes.any,
  ProfileActions: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
