import { MESSAGING } from "../actionType";

const INITIAL_STATE = {
  chatIds: [],
  chatRooms: [],
  allMsgsNotificationsCount: 0,
  chatIdFromNotification: "",
  loading: false,
};

export const MessagingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MESSAGING.RESET_CHATS:
      return {
        chatIds: [],
        chatRooms: [],
        allMsgsNotificationsCount: 0,
        chatIdFromNotification: "",
        loading: false,
      };
    case MESSAGING.SET_LOADING:
      return { ...state, loading: true };
    case MESSAGING.SET_CHAT_IDS:
      return { ...state, chatIds: action.payload, loading: true };
    case MESSAGING.SET_CHAT_ROOMS:
      return { ...state, chatRooms: action.payload, loading: false };
    case MESSAGING.SAVE_CHAT_ID_FROM_NOTIFICATION:
      return { ...state, chatIdFromNotification: action.payload };
    case MESSAGING.INBOX_NOTIFICATION_COUNT:
      const { chat, loggedInUser } = action;

      //getting notification count of all chats of logged in user
      let myUserCount = chat.map((item) => {
        return item?.unseenMessageCount?.filter((e) => e.email === loggedInUser.email);
      });

      //creating array of notification counts
      let countsArray = myUserCount.map((item) => {
        return item.map((i) => i.count).join(", ");
      });
      //calculating count
      const totalCount =
        countsArray.length > 0 &&
        countsArray.reduce(
          (previousValue, currentValue) => Number(previousValue) + Number(currentValue)
        );
      return { ...state, allMsgsNotificationsCount: totalCount };
    default:
      return state;
  }
};
