export default {
  color: [
    {
      id: 1,
      value: "9ce42304-b732-4791-9731-6f299b6df8c7",
      label: "Multi",
    },
    {
      id: 2,
      value: "90419f06-7d07-45c8-bcec-d675096fe27f",
      label: "Black",
    },
    {
      id: 3,
      value: "a23521da-0a48-4ef6-baa2-d727704f65c2",
      label: "Grey",
    },
    {
      id: 4,
      value: "34b2b58a-0123-49e2-b2de-1eef0922139b",
      label: "White",
    },
    {
      id: 5,
      value: "0fceaec7-fb46-4aef-994b-863a97130198",
      label: "Brown",
    },
    {
      id: 6,
      value: "b68c6b76-bbe0-49a6-8a90-209b8d88d363",
      label: "Tan",
    },
    {
      id: 7,
      value: "e2829c11-0960-490c-b19b-5c5bb26a18ce",
      label: "Cream",
    },
    {
      id: 9,
      value: "841e6f9f-1e19-4501-8b75-cd153282ed21",
      label: "Yellow",
    },
    {
      id: 10,
      value: "9346ba4b-8b3e-4c06-b930-cb3d98c58890",
      label: "Silver",
    },
    {
      id: 11,
      value: "7294d507-d6c5-47bf-8ef3-c291c273b647",
      label: "Red",
    },
    {
      id: 12,
      value: "451d4254-a882-4d53-a4ca-c7cb4707d1a5",
      label: "Orange",
    },
    {
      id: 13,
      value: "2378fade-f686-4f0d-bf00-4e5213032c9b",
      label: "Pink",
    },
    {
      id: 14,
      value: "77ec7a0b-329d-4643-bbc7-0fa12c788ded",
      label: "Purple",
    },
    {
      id: 15,
      value: "9ce42304-b732-4791-9781-6f299b6df8c7",
      label: "Blue",
    },
    {
      id: 16,
      value: "b68c6b76-bbe0-49a6-8a90-209b8d88d353",
      label: "Green",
    },
    {
      id: 17,
      value: "0fceaec7-fb46-4aef-994b-863a97130168",
      label: "Khaki",
    },
    {
      id: 18,
      value: "90419f06-7d07-45c8-bcec-d675096fe27g",
      label: "Gold",
    },
  ],

  condition: [
    {
      id: 1,
      value: "Brand new",
      label: "Brand new",
      details: "Unworn with original packaging or tags",
    },
    {
      id: 2,
      value: "Excellent",
      label: "Excellent",
      details: "Great condition, no signs of wear",
    },
    {
      id: 3,
      value: "Slight imperfections",
      label: "Slight imperfections",
      details: "Minor flaws or sign of wear",
    },
  ],
  category: [
    {
      id: 1,
      value: "Top",
      label: "Tops",
    },
    {
      id: 2,
      value: "Bottoms",
      label: "Bottoms",
    },
    {
      id: 3,
      value: "Dress",
      label: "Dresses",
    },
    {
      id: 4,
      value: "Outerwear",
      label: "Outerwear",
    },
    {
      id: 5,
      value: "Suit / Co-ord",
      label: "Suits and Co-ords",
    },
    {
      id: 6,
      value: "Jumpsuit",
      label: "Jumpsuits",
    },
    {
      id: 7,
      value: "Accessories",
      label: "Accessories",
    },
  ],
  fit: [
    {
      id: 1,
      value: "Small",
      label: "Small",
    },
    {
      id: 2,
      value: "True to size",
      label: "True to size",
    },
    {
      id: 3,
      value: "Large",
    },
  ],
  size: [
    {
      id: 1,
      value: "4",
      label: "4",
    },
    {
      id: 2,
      value: "6",
      label: "6",
    },
    {
      id: 3,
      value: "8",
      label: "8",
    },
    {
      id: 4,
      value: "10",
      label: "10",
    },
    {
      id: 5,
      value: "12",
      label: "12",
    },
    {
      id: 6,
      value: "14",
      label: "14",
    },
    {
      id: 7,
      value: "16",
      label: "16",
    },
    {
      id: 8,
      value: "18",
      label: "18",
    },
    {
      id: 9,
      value: "20",
      label: "20",
    },
    {
      id: 10,
      value: "22",
      label: "22",
    },
    {
      id: 11,
      value: "24",
      label: "24",
    },
    {
      id: 12,
      value: "XXS",
      label: "XXS",
    },
    {
      id: 13,
      value: "XS",
      label: "XS",
    },
    {
      id: 14,
      value: "S",
      label: "S",
    },
    {
      id: 15,
      value: "M",
      label: "M",
    },
    {
      id: 16,
      value: "L",
      label: "L",
    },
    {
      id: 17,
      value: "XL",
      label: "XL",
    },
    {
      id: 18,
      value: "XXL",
      label: "XXL",
    },
    {
      id: 19,
      value: "One Size",
      label: "One Size",
    },
  ],
  colour: [
    {
      id: 1,
      value: "Multi",
      label: "Multi",
    },
    {
      id: 2,
      value: "Black",
      label: "Black",
    },
    {
      id: 3,
      value: "Grey",
      label: "Grey",
    },
    {
      id: 4,
      value: "White",
      label: "White",
    },
    {
      id: 5,
      value: "Brown",
      label: "Brown",
    },
    {
      id: 6,
      value: "Tan",
      label: "Tan",
    },
    {
      id: 7,
      value: "Cream",
      label: "Cream",
    },
    {
      id: 9,
      value: "Yellow",
      label: "Yellow",
    },
    {
      id: 10,
      value: "Silver",
      label: "Silver",
    },
    {
      id: 11,
      value: "Red",
      label: "Red",
    },
    {
      id: 12,
      value: "Orange",
      label: "Orange",
    },
    {
      id: 13,
      value: "Pink",
      label: "Pink",
    },
    {
      id: 14,
      value: "Purple",
      label: "Purple",
    },
    {
      id: 15,
      value: "Blue",
      label: "Blue",
    },
    {
      id: 16,
      value: "Green",
      label: "Green",
    },
    {
      id: 17,
      value: "Khaki",
      label: "Khaki",
    },
    {
      id: 18,
      value: "Gold",
      label: "Gold",
    },
  ],
  categorySearch: [
    {
      id: 1,
      value: "Top",
      label: "Tops",
    },
    {
      id: 2,
      value: "Bottoms",
      label: "Bottoms",
    },
    {
      id: 3,
      value: "Dress",
      label: "Dresses",
    },
    {
      id: 4,
      value: "Outerwear",
      label: "Outerwear",
    },
    {
      id: 5,
      value: "Suits",
      label: "Suits and Co-ords",
    },
    {
      id: 6,
      value: "Jumpsuit",
      label: "Jumpsuits",
    },
    {
      id: 7,
      value: "Accessories",
      label: "Accessories",
    },
  ],
};
