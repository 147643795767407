import axios from "axios";
import Config from "./../../axios/config";
import { APP_ACTIONS, MESSAGING } from "../actionType";
import { logErrorToGrayLogs } from "./GrayLogActions";

const { REST_API } = Config;
export const login = (filters) => (dispatch) => {
  return axios
    .get(REST_API.AppActions.Login, {
      params: filters,
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        const { data, message } = baseModel;
        if (data.userId) {
          return {
            success: true,
            message: message,
            userId: data.userId,
          };
        } else {
          dispatch({
            type: APP_ACTIONS.LOGIN,
            isLoggedIn: true,
            bearerToken: data.access_token,
            userId: data.user.userId,
            userName: data.user.name,
            email: data.user.email,
          });
        }
        localStorage.setItem('userEmail', data.user.email);
      } else {
        logErrorToGrayLogs(
          `function: login, platform: Web, Entered Values : Entered email is ${filters.email} , client error msg: ${response.data.message}`
        );
      }
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: login, platform: Web, Entered Values : Entered email is ${filters.email} , client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: APP_ACTIONS.LOGOUT,
  });
  dispatch({
    type: MESSAGING.RESET_CHATS,
  });
};

export const forgotPassword = (email) => (dispatch) => {
  return axios
    .post(`${REST_API.AppActions.ForgotPassword}?email=${email}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        return baseModel;
      } else {
        logErrorToGrayLogs(
          `function: forgotPassword, platform: Web, client error msg:  ${response.message}`
        );
      }
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: forgotPassword, platform: Web, client error msg:  ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const resetPassword = (resetPasswordModel) => (dispatch) => {
  return axios
    .post(REST_API.AppActions.ResetPassword, resetPasswordModel, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        return baseModel;
      }
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: resetPassword, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const otpVerification = (email, code) => (dispatch) => {
  return axios
    .get(`${REST_API.AppActions.OtpVerification}?email=${email}&otp=${code}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        return baseModel;
      }
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: otpVerification, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};
