import axios from "axios";
import Config from "./../../axios/config";
import { logErrorToGrayLogs } from "./GrayLogActions";
import { EXPLORE_ACTIONS, PRODUCT_ACTIONS } from "../actionType";

const { REST_API } = Config;
export const fetchHeroCarousel = () => (dispatch) => {
  return axios
    .get(`${REST_API.ExploreActions.HeroCarousel}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: EXPLORE_ACTIONS.HERO_CAROUSEL_PRODUCTS,
          products: response.data.data,
        });
      } else {
        logErrorToGrayLogs(
          `function: fetchHeroCarousel, platform: Web, client error msg:  ${response.message}`
        );
      }
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: fetchHeroCarousel, platform: Web, client error msg:  ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const fetchCollections = () => (dispatch) => {
  return axios
    .get(`${REST_API.ExploreActions.Collections}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: EXPLORE_ACTIONS.COLLECTIONS,
          collections: response.data.data,
        });
      } else {
        logErrorToGrayLogs(
          `function: fetchHeroCarousel, platform: Web, client error msg:  ${response.message}`
        );
      }
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: fetchHeroCarousel, platform: Web, client error msg:  ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const fetchSingleCollection = (options) => (dispatch) => {
  const { id, filter1, filter2, filter3, sort, current, limit, scroll } = options;
  const filters = `${filter1}${filter2}${filter3}`;
  return axios
    .get(
      `${REST_API.Listing.GetRecommendedProducts}?${filters}&SortBy=${sort}&Current=${current}&Limit=${limit}&collectionId=${id}`,
      {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      }
    )
    .then((response) => {
      const baseModel = response.data;
      {
        if (scroll)
          dispatch({
            type: EXPLORE_ACTIONS.GET_PRODUCTS_ON_SCROLL,
            payload: baseModel.data,
          });
        else {
          dispatch({
            type: EXPLORE_ACTIONS.SINGLE_COLLECTION,
            payload: baseModel.data || [],
          });
          dispatch({
            type: PRODUCT_ACTIONS.SET_FILTERS,
            payload: filters,
          });
        }
      }
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: fetchSingleCollection, platform: Web, client error msg:  ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const clearSingleCollectionData = () => (dispatch) => {
  return dispatch({
    type: EXPLORE_ACTIONS.CLEAR_SINGLE_COLLECTION_DATA,
  });
};
