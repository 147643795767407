import React from "react";
import { Button, Spinner } from "react-bootstrap";

const SpinnerComponent = () => {
  return (
    <div className="d-flex justify-content-center">
      <Button
        style={{ background: "white", border: 0 }}
        variant="light outline-default"
        border="0"
        disabled
      >
        <Spinner variant="danger" animation="border" size="md" />
      </Button>
    </div>
  );
};

export default SpinnerComponent;
