import React from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './../../css/calendar.css';
import PropTypes from "prop-types";

const CalendarComponent = ({ selection, disabled, ...props }) => {
  return (
    <DayPicker
      mode="range"
      selected={selection}
      disabled={disabled}
      weekStartsOn={1}
      fromDate={props.fromDate}
      toDate={props.toDate}
      {...props}
    />
  );
};

CalendarComponent.propTypes = {
  selection: PropTypes.string,
  fromDate: PropTypes.any,
  toDate: PropTypes.any,
  disabled: PropTypes.bool,
};
export default CalendarComponent;
