import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import options from "./data";
import { TbFilter } from "react-icons/tb";
import PropTypes from "prop-types";

const TilesFilters = (props) => {
  const {
    selectedCategory,
    selectedColor,
    selectedSize,
    selectedCateogryOptionsList,
    selectedSizeOptionsList,
    selectedColorOptionsList,
  } = props;
  const [isFilter, setIsFilter] = useState(false);
  return (
    <Container>
      <Row>
        <Col xs={12} md={12} className="p-0">
          <span className="filterWrapperMobile" onClick={() => setIsFilter(!isFilter)}>
            <TbFilter /> <i>Search filters</i>
          </span>
          <div className={`filtersWrapper ${isFilter ? "mobileFitersMain" : "noMobileFilter"}`}>
            <ReactMultiSelectCheckboxes
              options={options.category}
              onChange={selectedCategory}
              placeholderButtonLabel="Category"
              value={selectedCateogryOptionsList}
            />
            <ReactMultiSelectCheckboxes
              options={options.size}
              placeholderButtonLabel="Size"
              onChange={selectedSize}
              value={selectedSizeOptionsList}
            />
            <ReactMultiSelectCheckboxes
              options={options.color}
              placeholderButtonLabel="Color"
              onChange={selectedColor}
              value={selectedColorOptionsList}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
TilesFilters.propTypes = {
  selectedCategory: PropTypes.any,
  selectedColor: PropTypes.any,
  selectedSize: PropTypes.any,
  selectedCateogryOptionsList: PropTypes.any,
  selectedSizeOptionsList: PropTypes.any,
  selectedColorOptionsList: PropTypes.any,
}
export default TilesFilters;
