import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReSendOTP from "./ResendOTP";
import PropTypes from "prop-types";

const VerifyOTP = ({ phoneNo, otpHandler, hideOtp, userId, AppActions }) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    otpHandler(data);
  };
  return (
    <div>
      <div className="signinWrapper">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="loginWrapper">
                <h3>Step 2 of 3</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      {phoneNo ? (
                        <div className="otp-code">
                          <label htmlFor="enterCode">Enter the code we sent to</label>
                          <div className="phone">
                            {phoneNo}
                            <i className="edit" onClick={hideOtp}>
                              Edit
                            </i>
                          </div>
                        </div>
                      ) : null}

                      <div className="did-floating-label-content">
                        <input
                          {...register("code")}
                          className="did-floating-input"
                          type="number"
                          placeholder=" "
                          required
                        />
                        <label htmlFor="code" className="did-floating-label">Code</label>
                        {/* <span className="formErrorMsg">{errors.phoneNumber?.message}</span> */}
                      </div>
                      <ReSendOTP AppActions={AppActions} userId={userId} />
                    </Col>
                  </Row>
                  <span className="appBtnWrapperInner" style={{ marginBottom: "2rem" }}>
                    <input
                      type="submit"
                      value="Verify Code"
                    // onClick={registerinfohandler}
                    />
                  </span>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

VerifyOTP.propTypes = {
  phoneNo: PropTypes.any,
  otpHandler: PropTypes.any,
  hideOtp: PropTypes.any,
  userId: PropTypes.any,
  AppActions: PropTypes.any,
}

export default VerifyOTP;
