import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { googleplaybtn, appstorebtn } from "../../images/index";
import { MainLogo } from "./SVGIcons";
import { useConsent } from 'react-hook-consent';

const Footer = () => {
  const location = useLocation();
  const { toggleBanner } = useConsent();
  return (
    <div
      className={`${location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/inbox"
        ? "nofooter inboxFooter"
        : ""
        } footerWrapper`}
    >
      <Container>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <MainLogo />
            <p>Download the free app</p>
            <span className="playstoreWrapper">
              <a href="https://apps.apple.com/gb/app/swished/id1600685718" target="_blank" rel="noreferrer">
                <img src={appstorebtn} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=co.uk.swished" target="_blank" rel="noreferrer">
                <img src={googleplaybtn} alt="" />
              </a>
            </span>
          </Col>
          <Col xs={12} md={3}>
            <h3>.Help &amp; info</h3>
            <ul>
              <li>
                <Link to="how-it-works">How it works FAQs</Link>
              </li>
              <li>
                <Link to="contact-us">Contact us</Link>
              </li>
              <li>
                <p style={{ cursor: "pointer" }} onClick={() => toggleBanner()}>Your cookie choices</p>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <h3>.Policies</h3>
            <ul>
              <li>
                <Link to="terms-of-services"> Terms of Use</Link>
              </li>
              <li>
                <Link to="refund-policy">Refund Policy</Link>
              </li>
              <li>
                <Link to="privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="delivery-returns">Delivery &amp; Returns</Link>
              </li>
            </ul>
          </Col>

          <Col xs={12} md={4} lg={3}>
            {/* <h3>.Join mailing list</h3>
            <p>£5 off your first rental when you sign up to our newsletter</p>
            <span className="joinUsWrapper">
              <FloatingLabelInput type="text" label="Email" name="email" />
              <button>Subscribe</button>
            </span> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="copyRight">
              &copy; 2023 copyright .ownish limited. all rights reserved.
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
