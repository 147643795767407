import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Common } from "./../../../utilities/helpers";
import PropTypes from "prop-types";

export default function CheckoutForm(props) {
  const {
    totalChargeable,
    reservationId,
    billingCountry,
    billingPostalCode,
    isBillingAddress,
    state,
    setDeliveryErrors,
    additionalInfo,
    isPurchased,
  } = props;
  const [disableRequestRental, setDisableRequestRental] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setDeliveryErrors({});
    let isValid = true;
    if (!state.daAddressLine1) {
      isValid = false;
      setDeliveryErrors((state) => ({
        ...state,
        daAddressLine1: { message: "Address line 1 is required" },
      }));
    }
    if (!state.daTownOrCity) {
      isValid = false;
      setDeliveryErrors((state) => ({
        ...state,
        daTownOrCity: { message: "Town or city is required" },
      }));
    }
    if (!state.daPostalCode) {
      isValid = false;
      setDeliveryErrors((state) => ({
        ...state,
        daPostalCode: { message: "Postal code is required" },
      }));
    }
    if (!state.name) {
      isValid = false;
      setDeliveryErrors((state) => ({ ...state, name: { message: "Name is required" } }));
    }
    if (!state.daCountry) {
      isValid = false;
      setDeliveryErrors((state) => ({ ...state, daCountry: { message: "Country is required" } }));
    }
    if (isBillingAddress) {
      if (!state.billingAddressLine1) {
        isValid = false;
        setDeliveryErrors((state) => ({
          ...state,
          billingAddressLine1: { message: "Address line 1 is required" },
        }));
      }
      if (!state.billingTownOrCity) {
        isValid = false;
        setDeliveryErrors((state) => ({
          ...state,
          billingTownOrCity: { message: "Town or city is required" },
        }));
      }
      if (!state.billingPostalCode) {
        isValid = false;
        setDeliveryErrors((state) => ({
          ...state,
          billingPostalCode: { message: "Postal code is required" },
        }));
      }
      if (!state.billingCountry) {
        isValid = false;
        setDeliveryErrors((state) => ({
          ...state,
          billingCountry: { message: "Country is required" },
        }));
      }
    }
    if (!isValid) {
      return;
    }

    Common.loaderDisplay(true);
    setDisableRequestRental(true);
    props.StripeActions.CreateUserPaymentIntent({
      amount: totalChargeable * 100,
      currency: "GBP",
      reservationId: reservationId,
      ...additionalInfo,
    }).then((data) => {
      Common.loaderDisplay(true);
      stripe
        .confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: window.location.origin + "/payment-successful",
            payment_method_data: {
              billing_details: {
                address: {
                  country: billingCountry,
                  postal_code: billingPostalCode,
                },
              },
            },
          },
          redirect: "if_required",
        })
        .then((response) => {
          Common.loaderDisplay(true);
          if (!response.error)
            props.requestRentalClick(response.paymentIntent); // call parent method
          else {
            Common.loaderDisplay(false);
            setDisableRequestRental(false);
          }
        });
    });
  };

  const paymentElementOptions = {
    fields: {
      billingDetails: {
        address: {
          country: "never",
          postalCode: "never",
        },
      },
    },
    wallets: {
      applePay: "never",
      googlePay: "never",
    },
    terms: {
      card: "never",
    },
  };

  return (
    <div className="checkoutInnerCol">
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          disabled={!stripe || !elements || disableRequestRental}
          id="submit"
          className="stripePayNowBtn"
        >
          <span id="button-text">Request {isPurchased ? "to buy" : "rental"}</span>
        </button>
      </form>
    </div>
  );
}

CheckoutForm.propTypes = {
  totalChargeable: PropTypes.any,
  reservationId: PropTypes.any,
  billingCountry: PropTypes.any,
  billingPostalCode: PropTypes.any,
  isBillingAddress: PropTypes.any,
  state: PropTypes.any,
  setDeliveryErrors: PropTypes.any,
  additionalInfo: PropTypes.any,
  isPurchased: PropTypes.any,
  requestRentalClick: PropTypes.any,
  StripeActions: PropTypes.any,

};