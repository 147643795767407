import React from "react";
import PropTypes from "prop-types";

const FloatingLabelInput = ({ value, type, label, name, onchange, error, autoComplete }) => {
  return (
    <div className="container p-0">
      <div className="did-floating-label-content">
        <input
          className="did-floating-input"
          type={type}
          placeholder=" "
          value={value}
          name={name}
          onChange={onchange}
          autoComplete={autoComplete}
        />
        <label className="did-floating-label">{label}</label>
      </div>
      <span>{error}</span>
    </div>
  );
};

FloatingLabelInput.propTypes = {
  value: PropTypes.any,
  type: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  onchange: PropTypes.any,
  error: PropTypes.any,
  autoComplete: PropTypes.any,
}

export default FloatingLabelInput;
