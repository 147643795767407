import { useSelector } from "react-redux";
import axios from "axios";
import config from "../resources/axios/config";
import { removeFromWishList } from "./getProductService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logErrorToGrayLogs } from "../resources/redux/actions/GrayLogActions";
import { sendAmplitudeData } from "../utils/amplitude";

export const useWishlist = (isInMyWishList, refetch) => {
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn);
  const userId = useSelector((state) => state.app.userId);
  const [isWishListed, setIsWishListed] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      setIsWishListed(isInMyWishList || false);
    }
  }, [isInMyWishList]);
  const addToWishList = async (data) => {
    try {
      if (!isLoggedIn) {
        navigate("/login");
      }
      const result = await axios.post(config.REST_API.productActions.AddToWishList, data);
      return result;
    } catch (error) {
      console.error("Error adding to wishlist:", error);
      throw error;
    }
  };

  const toggleWishList = (productId) => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (isWishListed) {
      removeFromWishList({ productId, userId })
        .then((res) => {
          if (res.data.success) {
            setIsWishListed(false);
            sendAmplitudeData("Wishlist-remove", {
              "Event": "product removed from wishlist",
              "user id:": userId,
              "product id": productId,
            });
            if (refetch) refetch();
          }
        })
        .catch((error) => {
          setIsWishListed(true);
          logErrorToGrayLogs(
            `function: useWishlist->removeFromWishList, platform: Web, client error msg:  ${error.message}`
          );
        });
    } else {
      addToWishList({ productId, userId })
        .then((res) => {
          if (res.data.success) {
            setIsWishListed(true);
            sendAmplitudeData("Wishlist-add", {
              "Event": "product added to wishlist",
              "user id:": userId,
              "product id": productId,
            });
          }
        })
        .catch((error) => {
          setIsWishListed(false);
          logErrorToGrayLogs(
            `function: useWishlist->addToWishList, platform: Web, client error msg:  ${error.message}`
          );
        });
    }
  };

  return {
    isLoggedIn,
    userId,
    addToWishList,
    toggleWishList,
    isWishListed,
    setIsWishListed,
  };
};
