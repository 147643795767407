import React from "react";
import PropTypes from "prop-types";

const PaymentDetail = ({
  price,
  discount,
  selectedDeliveryAmount,
  selectedDeliveryText,
  freerenting,
  transactionfee,
  total,
  isPurchased,
}) => {
  return (
    <div className="paymentDetailsWrapper">
      <ul>
        <li>
          {isPurchased ? "Purchase" : "Rental"} price
          <span> &#163;{price}</span>
        </li>
        <li>
          {selectedDeliveryText}
          <span> &#163;{selectedDeliveryAmount}</span>
        </li>
        {freerenting !== 0 && (
          <li>
            Stress-free renting
            <span> &#163;{freerenting}</span>
          </li>
        )}
        <li>
          Transaction fee (2.5%)
          <span> &#163;{transactionfee}</span>
        </li>
        {discount !== 0 && (
          <li>
            Discount applied
            <span> &#163;{discount}</span>
          </li>
        )}
        <li>
          Total
          <span> &#163;{total}</span>
        </li>
      </ul>
    </div>
  );
};

PaymentDetail.propTypes = {
  price: PropTypes.any,
  discount: PropTypes.any,
  selectedDeliveryAmount: PropTypes.any,
  selectedDeliveryText: PropTypes.any,
  freerenting: PropTypes.any,
  transactionfee: PropTypes.any,
  total: PropTypes.any,
  isPurchased: PropTypes.any,
}
export default PaymentDetail;
