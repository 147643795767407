import React, { useEffect } from "react";
import CollectionCard from "./CollectionCard";
import * as ExploreActions from "../../../redux/actions/ExploreActions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const CardSlider = ({ ExploreActions, collections }) => {
  useEffect(() => {
    ExploreActions.fetchCollections();
  }, []);

  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 600, min: 480 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2,
    },
  };
  return (
    <div className="featuredCollectionWrapper featuredCollectionMobile">
      <span className="subHeading">.Featured Collections </span>

      <Row>
        <Col md={{ span: 12 }}>
          <Carousel responsive={responsive}>
            {collections.map((item, index) => {
              return (
                <div onClick={() => navigate(`/collection/${item.id}`)} key={item.id}>
                  <CollectionCard
                    title={item.title}
                    imgSrc={
                      item?.coverImageUrl ||
                      "https://ownish.s3.amazonaws.com/Swished_Production_Artifacts%2FB2D74FEF-1636-434E-B30D-69C7E64FE106.jpg"
                    }
                  />
                </div>
              );
            })}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ explore }) => {
  return explore;
};

const mapDispatchToProps = (dispatch) => {
  return {
    ExploreActions: bindActionCreators(ExploreActions, dispatch),
  };
};

CardSlider.propTypes = {
  ExploreActions: PropTypes.any,
  collections: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardSlider);
