import React, { useState } from "react";
import RegisterInformation from "./RegisterInformation";
import RegisterProfile from "./RegisterProfile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AppActions from "./../../../redux/actions/AuthenticationActions";
import * as UserActions from "./../../../redux/actions/UserActions";
import * as PhoneVerificationActions from "./../../../redux/actions/PhoneVerificationActions";
import { toast } from "react-toastify";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import VerifyOTP from "./VerifyOTP";
import { nameRegex, passwordRegex, userNameRegex, emailRegex } from "./../../../utilities/helpers";
import { computeHash, generateRandomString } from "../../../../utils/generateHash";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import {
  sendAmplitudeData,
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from "../../../../utils/amplitude";
import PropTypes from "prop-types";

const phoneRegExp = /^((\+44)|(0)) ?\d{4} ?\d{6}$/;
const schema = yup
  .object({
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number must contain contry code")
      .required("Phone number is required"),
    name: yup
      .string()
      .matches(nameRegex, "Please enter valid name")
      .required("First name is required"),
    lastName: yup
      .string()
      .matches(nameRegex, "Please enter valid name")
      .required("Please provide your last name"),
    email: yup
      .string()
      .email()
      .matches(emailRegex, "Please enter valid email")
      .required("Email is required"),
    userName: yup
      .string()
      .matches(
        userNameRegex,
        "Your username can only include letters, numbers, @ or _ it can't have spaces or start with a number."
      )
      .required("Username is required"),
    password: yup
      .string()
      .min(8, "Your password must contain at least 8 characters, including 1 letter and 1 number")
      .matches(passwordRegex, "Please enter valid password"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Password do not match")
      .matches(passwordRegex, "Please enter valid password")
      .required("Confirm Password is required"),
    dob: yup.date().nullable().typeError("Date of birth is required"),
    signupcode: yup.string(),
  })
  .required();

const Register = (props) => {
  const { returnPdpPath } = props;
  const location = useLocation();
  const userId = location?.state?.userId;

  const [userName, setUserName] = useState("");
  const [fName, setFName] = useState("");
  const [isRegisterInfo, setIsRegisterInfo] = useState(!userId);
  const [isOtpVerification, setIsOtpVerification] = useState(!!userId);
  const [isRegisterProfile, setIsRegisterProfile] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [phone, setPhone] = useState();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const EditHandler = () => {
    setIsRegisterInfo(false);
  };

  const hideOtp = () => {
    setIsOtpVerification(false);
    setIsRegisterInfo(true);
  };

  const RegisterInfoHandler = (data) => {
    const newObject = { ...data };
    setPhone(newObject?.phoneNumber);

    const hashValue = generateRandomString();
    const hash = computeHash(hashValue);

    const registerObject = {
      name: newObject.name,
      phoneNumber: newObject.phoneNumber,
      dateOfBirth: newObject?.dob,
      email: newObject.email,
      password: newObject.password,
      confirmPassword: newObject.confirmPassword,
      userName: newObject.userName,
      surName: newObject.lastName,
      hash,
      value: hashValue,
      userId: userInfo || null,
      isWeb: true,
    };
    const amplitudeObject = {
      name: newObject.name,
      dateOfBirth: newObject?.dob,
      email: newObject.email,
      userName: newObject.userName,
      surName: newObject.lastName,
      userId: userInfo || null,
    };
    props.AppActions.register(registerObject).then((response) => {
      if (response?.success) {
        toast.success("User registered successfully!", {
          className: "toastSuccess",
          position: toast.POSITION.TOP_CENTER,
        });
        setIsRegisterInfo(false);
        setIsOtpVerification(true);
        setUserName(data.userName);
        setFName(data.name);
        setUserInfo(response?.data?.userId);
        setAmplitudeUserId(data.email);
        setAmplitudeUserProperties(amplitudeObject);
        sendAmplitudeData("Registration-Success-Event", {
          Event: "User completes registration",
          ...amplitudeObject,
        });
        logErrorToGrayLogs(
          `function: User completes registration, platform: Web, Entered Values : ${JSON.stringify(
            amplitudeObject,
            null,
            2
          )}`
        );
      } else if (response.message.includes("already exists")) {
        toast.error("User with this email already exists!", {
          className: "toastError",
          position: toast.POSITION.TOP_CENTER,
        });
        logErrorToGrayLogs(
          `function: RegisterUser, platform: Web, Entered Values : UserName ${data.userName} Name ${data.name} LastName ${data.lastName} phoneNumber ${data.PhoneNumber} Email ${data.email} , client error msg: ${response.message}`
        );
      } else {
        toast.error("Something went wrong!", {
          className: "toastError",
          position: toast.POSITION.TOP_CENTER,
        });
        logErrorToGrayLogs(
          `function: RegisterUser, platform: Web, Entered Values : UserName ${data.userName} Name ${data.name} LastName ${data.lastName} phoneNumber ${data.PhoneNumber} Email ${data.email} , client error msg: ${response.message}`
        );
        sendAmplitudeData("RegisterUser-Fail", {
          "Event": "User fails to create an acount",
          "Reason for failure": response.message,
          "Data": `UserName ${data.userName} Name ${data.name} LastName ${data.lastName} phoneNumber ${data.PhoneNumber} Email ${data.email} , client error msg: ${response.message}`,
        });
      }
    });
  };

  const RegisterProfileHandler = () => {
    setIsRegisterInfo(false);
  };

  const otpHandler = (data) => {
    const hashValue = generateRandomString();
    const hash = computeHash(hashValue);

    const obj = {
      verificationCode: data?.code,
      userId: userId || userInfo,
      isWeb: true,
      hash,
      value: hashValue,
    };
    try {
      props.AppActions.otpValidation(obj).then((response) => {
        console.log("ottp response", response);
        if (response.success) {
          toast.success("OTP verified successfully!", {
            className: "toastSuccess",
            position: toast.POSITION.TOP_CENTER,
          });
          setIsRegisterProfile(true);
          setIsOtpVerification(false);
          reset();
        } else {
          toast.error("invalid code", {
            className: "toastError",
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } catch (err) {
      console.log("ere", err);
    }
  };

  const onSubmit = (data) => {
    RegisterInfoHandler(data);
  };

  return (
    <div>
      {isRegisterInfo && (
        <RegisterInformation
          onSubmit={onSubmit}
          onPhone={EditHandler}
          handleSubmit={handleSubmit}
          errors={errors}
          register={register}
        />
      )}

      {isOtpVerification && (
        <VerifyOTP
          phoneNo={phone}
          otpHandler={otpHandler}
          setIsOtpVerification={setIsOtpVerification}
          hideOtp={hideOtp}
          userId={userId || userInfo}
          AppActions={props.AppActions}
        />
      )}

      {isRegisterProfile && (
        <RegisterProfile
          registerprofilehandler={RegisterProfileHandler}
          userName={userName}
          fName={fName}
          returnPdpPath={returnPdpPath}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ product }) => {
  const { returnPdpPath } = product;
  return { returnPdpPath };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AppActions: bindActionCreators(AppActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    PhoneVerificationActions: bindActionCreators(PhoneVerificationActions, dispatch),
  };
};
Register.propTypes = {
  AppActions: PropTypes.any,
  returnPdpPath: PropTypes.any,
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);
