import { GIFT_CARDS } from "../actionType";

const INITIAL_STATE = {
  stripeIntentData: {},
  giftCardData: {},
};

const giftCardState = (state, action) => {
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case GIFT_CARDS.SET_STRIPE_INTENT:
      return {
        ...state,
        stripeIntentData: action.payload,
      };
    case GIFT_CARDS.SET_GIFT_CARD_DATA:
      return {
        ...state,
        giftCardData: action.payload,
      };
    case GIFT_CARDS.CLEAR_STRIPE_INTENT:
      return {
        ...state,
        stripeIntentData: {},
      };
    default:
      return state;
  }
};

export default giftCardState;
