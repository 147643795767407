import React from "react";
import PropTypes from "prop-types";

const ProductInfo = ({
  picture,
  name,
  size,
  arrivedate,
  arrivemonth,
  postbackdate,
  postbackmonth,
  isPurchased,
}) => {
  return (
    <div className="checkoutProductInfo">
      <span className="productPicture">
        <img src={picture} alt="" />
      </span>
      <ul>
        <li>{name}</li>
        <li>Size: {size}</li>
        {!isPurchased ? (
          <>
            {" "}
            <li>
              Arrives by:{" "}
              <i>
                {arrivedate} {arrivemonth}
              </i>
            </li>
            <li>
              Post back on:{" "}
              <i>
                {postbackdate} {postbackmonth}
              </i>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

ProductInfo.propTypes = {
  picture: PropTypes.any,
  name: PropTypes.any,
  size: PropTypes.any,
  arrivedate: PropTypes.any,
  arrivemonth: PropTypes.any,
  postbackdate: PropTypes.any,
  postbackmonth: PropTypes.any,
  isPurchased: PropTypes.any,
}
export default ProductInfo;
