import { FILTERS_ACTIONS } from "../actionType";

export const setCategories = (payload) => (dispatch) => {
  dispatch({
    type: FILTERS_ACTIONS.SET_CATEGORIES,
    payload,
  });
};

export const setColors = (payload) => (dispatch) => {
  dispatch({
    type: FILTERS_ACTIONS.SET_COLORS,
    payload,
  });
};

export const setSizes = (payload) => (dispatch) => {
  dispatch({
    type: FILTERS_ACTIONS.SET_SIZES,
    payload,
  });
};

export const setSorting = (payload) => (dispatch) => {
  dispatch({
    type: FILTERS_ACTIONS.SET_SORT,
    payload,
  });
};

export const clearFilters = () => (dispatch) => {
  dispatch({
    type: FILTERS_ACTIONS.CLEAR_FILTERS,
  });
};
