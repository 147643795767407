import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="contentBodyInner termsOfServicesWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h3 className="privacyHeading" style={{ textAlign: "center", marginBottom: "2.5rem", fontWeight: "700", fontSize: "1.5rem" }}>Website and app Privacy Policy</h3>
            <ol className="nb">
              <li>
                <strong>
                  <span className="sectit">INTRODUCTION</span>
                </strong>
                <ol className="nb">
                  <li>
                    <strong>
                      <span className="bold">
                        Important information and who we are
                      </span>
                    </strong>
                    <p>
                      Welcome to Ownish Ltd’s Privacy and Data Protection
                      Policy {" "}
                      <span className="bold">Privacy Policy</span>.
                    </p>
                    <p>
                      At Ownish Ltd {" "}(“<span className="bold">we</span>”, “
                      <span className="bold">us</span>”, or “{""}
                      <span className="bold">our</span> {" "}
                      ”) we are committed to protecting and respecting your
                      privacy and Personal Data in compliance with the United
                      Kingdom General Data Protection Regulation (“
                      <span className="bold">GDPR</span>”), the Data Protection Act
                      2018 and all other mandatory laws and regulations of the
                      United Kingdom.
                    </p>
                    <p>
                      This Privacy Policy explains how we collect, process and
                      keep your data safe. The Privacy Policy will tell you
                      about your privacy rights, how the law protects you, and
                      inform our employees and staff members of all their
                      obligations and protocols when processing data.
                    </p>
                    <p>
                      The individuals from which we may gather and use data can
                      include:
                    </p>
                    <ul>
                      <li>Visitors to <a href="https://ownish.co.uk">https://ownish.co.uk</a></li>
                      <li>Visitors to the Ownish iOS and Android apps</li>
                      <li>Customers that use our webchat facility or sign up to our newsletter</li>
                    </ul>
                    and any other people that the organisation has a
                    relationship with or may need to contact.
                    <p>
                      This Privacy Policy applies to all our employees and staff
                      members and all Personal Data processed at any time by us.
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">Your Data Controller</span>
                    </strong>
                    <p>
                      Ownish Ltd is your Data Controller and responsible for
                      your Personal Data. We are not obliged by the GDPR to
                      appoint a data protection officer and have not voluntarily
                      appointed one at this time. Therefore, any inquiries about
                      your data should either be sent to us by email to
                      hello@ownish.co.uk or by post to 57 Jordan Street,
                      Liverpool, L1 0BW, United Kingdom.
                    </p>
                    <p>
                      You have the right to make a complaint at any time to the
                      Information Commissioner’s Office (ICO), the UK
                      supervisory authority for data protection issues
                      (www.ico.org.uk). We would, however, appreciate the chance
                      to deal with your concerns before you approach the ICO so
                      please contact us in the first instance.
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">
                        Processing data on behalf of a Controller and
                        processors’ responsibility to you
                      </span>
                    </strong>
                    <p>
                      In discharging our responsibilities as a Data Controller
                      we have employees who will deal with your data on our
                      behalf (known as “<span className="bold">Processors</span>”).
                      The responsibilities below may be assigned to an
                      individual or may be taken to apply to the organisation as
                      a whole. The Data Controller and our Processors have the
                      following responsibilities:
                    </p>
                    <ul>
                      <li>
                        Ensure that all processing of Personal Data is governed
                        by one of the legal bases laid out in the GDPR (see 2.2
                        below for more information);
                      </li>
                      <li>
                        Ensure that Processors authorised to process Personal
                        Data have committed themselves to confidentiality or are
                        under an appropriate statutory obligation of
                        confidentiality;
                      </li>
                      <li>
                        Implement appropriate technical and organisational
                        measures to ensure a level of security appropriate to
                        the risk associated with the processing of Personal
                        Data;
                      </li>
                      <li>
                        Obtain the prior specific or general authorisation of
                        the Controller before engaging another Processor;
                      </li>
                      <li>
                        Assist the Controller in the fulfilment of the
                        Controller's obligation to respond to requests for
                        exercising the data subject's rights;
                      </li>
                      <li>
                        Make available to the Controller all information
                        necessary to demonstrate compliance with the obligations
                        laid down in the GDPR and allow for and contribute to
                        audits, including inspections, conducted by the
                        Controller or another auditor mandated by the
                        Controller;
                      </li>
                      <li>
                        Maintain a record of all categories of processing
                        activities carried out on behalf of a Controller;
                      </li>
                      <li>
                        Cooperate, on request, with the supervisory authority in
                        the performance of its tasks;
                      </li>
                      <li>
                        Ensure that any person acting under the authority of the
                        Processor who has access to Personal Data does not
                        process Personal Data except on instructions from the
                        Controller; and
                      </li>
                      <li>
                        Notify the Controller without undue delay after becoming
                        aware of a Personal Data Breach.
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  <span className="sectit">LEGAL BASIS FOR DATA COLLECTION</span>
                </strong>
                <ol className="nb">
                  <li>
                    <strong>
                      <span className="bold">
                        Types of data / Privacy policy scope
                      </span>
                    </strong>
                    <p>
                      “<span className="bold">Personal Data</span>” means any
                      information about an individual from which that person can
                      be identified. It does not include data where the identity
                      has been removed (anonymous data).
                    </p>
                    <p>
                      We may collect, use, store and transfer different kinds of
                      Personal Data about you which we have grouped together
                      below. Not all of the following types of data will
                      necessarily be collected from you but this is the full
                      scope of data that we collect and when we collect it from
                      you:
                    </p>
                    <ul>
                      <li>
                        Profile/Identity Data: This is data relating to your
                        first name, last name, gender, date of birth.
                      </li>
                      <li>
                        Contact Data: This is data relating to your phone
                        number, addresses, email addresses, phone numbers.
                      </li>
                      <li>
                        Marketing and Communications Data: This is your
                        preferences in receiving marketing information and other
                        information from us.
                      </li>
                      <li>
                        Billing Data: This is information relating to your debit
                        and credit card information such as the name attached to
                        your payment details and your billing address.
                      </li>
                      <li>
                        Financial Data: These are your banking details e.g. your
                        account number and sort code.
                      </li>
                      <li>
                        Transactional Data: This is information of details and
                        records of all payments you have made for our services
                        or products.
                      </li>
                      <li>
                        Technical Data: This is your IP address, browser type
                        and version, time zone setting and location, operating
                        system and platform, and other technology on the devices
                        you use to engage with us.
                      </li>
                      <li>
                        Customer Support Data: This includes feedback and survey
                        responses.
                      </li>
                      <li>
                        Usage Data: information about how you use our website,
                        products and services.
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      We do not collect any Special Categories of Personal Data
                      about you (this includes details about your race or
                      ethnicity, religious or philosophical beliefs, sex life,
                      sexual orientation, political opinions, trade union
                      membership, information about your health, and genetic and
                      biometric data). Nor do we collect any information about
                      criminal convictions and offences.
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">
                        The Legal Basis for Collecting That Data
                      </span>
                    </strong>
                    <p>
                      There are a number of justifiable reasons under the GDPR
                      that allow collection and processing of Personal Data. The
                      main avenues we rely on are:
                    </p>
                    <ul>
                      <li>
                        “<span className="bold">Consent</span>”: Certain situations
                        allow us to collect your Personal Data, such as when you
                        tick a box that confirms you are happy to receive email
                        newsletters from us, or ‘opt in’ to a service.
                      </li>
                      <li>
                        “<span className="bold">Contractual Obligations</span>”: We
                        may require certain information from you in order to
                        fulfil our contractual obligations and provide you with
                        the promised service.
                      </li>
                      <li>
                        “<span className="bold">Legal Compliance</span>”: We’re
                        required by law to collect and process certain types of
                        data, such as fraudulent activity or other illegal
                        actions.
                      </li>
                      <li>
                        “<span className="bold">Legitimate Interest</span>”: We
                        might need to collect certain information from you to be
                        able to meet our legitimate interests - this covers
                        aspects that can be reasonably expected as part of
                        running our business, that will not have a material
                        impact on your rights, freedom or interests. Examples
                        could be your address, so that we know where to deliver
                        something to, or your name, so that we have a record of
                        who to contact moving forwards.
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  <span className="sectit">HOW WE USE YOUR PERSONAL DATA</span>
                </strong>
                <ol className="nb">
                  <li>
                    <strong>
                      <span className="bold">Our data uses</span>
                    </strong>
                    <p>
                      We will only use your Personal Data when the law allows us
                      to.
                    </p>
                    <p>
                      Set out below is a table containing the different types of
                      Personal Data we collect and the lawful basis for
                      processing that data. Please refer to section 2.2 for more
                      information on the lawful basis listed in the table below.
                    </p>
                    <p>
                      Examples provided in the table below are indicative in
                      nature and the purposes for which we use your data may be
                      broader than described but we will never process your data
                      without a legal basis for doing so and it is for a related
                      purpose. For further inquiries please contact us.
                    </p>
                    <p>&nbsp;</p>
                    <div className="scrollable-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>Activity</th>
                            <th>Type of data</th>
                            <th>Legal Justification</th>
                            <th>Lawful basis for processing data</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>When you register to use Ownish</td>
                            <td>
                              <p>Profile/Identity Data</p>
                              <p>Contact Data</p>
                              <p>Marketing and Communications Data</p>
                            </td>
                            <td>
                              <p>Consent</p>
                            </td>
                            <td>
                              When you register to be a renter or a lister on
                              the Ownish platform we ask you to provide your
                              name, phone number and email. We also ask for your
                              Instagram handle and date of birth, these two are
                              optional. We ask you for your name, phone number,
                              email and Instagram handle so that we know who you
                              are and can contact you with any important
                              communications that relate to renting or listing,
                              for example that you have received a rental
                              request, or that your rental request has been
                              accepted. We ask for your date of birth so that we
                              can send you a happy birthday message and birthday
                              discount code to use as a gift. By registering you
                              are also opting-in to receive push notification
                              and email news updates relevant to your use of the
                              app. If for any reason you receive a news update
                              but did not register to receive them, we are
                              sending them to you because of our legitimate
                              interest to promote our business. You can
                              unsubscribe from our updates at any time by
                              clicking the unsubscribe link at the bottom of any
                              of our emails, DMing us on Insta or by emailing
                              hello@ownish.co.uk.
                            </td>
                          </tr>
                          <tr>
                            <td>When you register to use Ownish</td>
                            <td>
                              <p>Financial Data</p>
                            </td>
                            <td>
                              <p>Consent</p>
                            </td>
                            <td>
                              When you list an item for rent or sale, we ask you to create a digital wallet
                              – operated by our payments processor Stripe –
                              for your payouts to be paid to.
                            </td>
                          </tr>
                          <tr>
                            <td>When you rent an item</td>
                            <td>
                              <p>Profile/Identity Data</p>
                            </td>
                            <td>
                              <p>Consent</p>
                            </td>
                            <td>
                              When you rent or buy an item, we ask you to provide your
                              name and postal address so that we can pass that on to the
                              lister so they can post your item to the address you specify.
                            </td>
                          </tr>
                          <tr>
                            <td>When you lend an item</td>
                            <td>
                              <p>Profile/Identity Data</p>
                            </td>
                            <td>
                              <p>Consent</p>
                            </td>
                            <td>
                              When one of your listed items has been received a rental request,
                              we ask you to provide your address so that we can pass that onto
                              the renter so they can return the item to your specified address.
                            </td>
                          </tr>
                          <tr>
                            <td>When you rent an item</td>
                            <td>
                              <p>Transactional Data</p>
                            </td>
                            <td>
                              <p>Consent</p>
                              <p>Contractual Obligations</p>
                            </td>
                            <td>
                              When you rent an item, we ask you to provide your
                              payment details so that we fulfil your order.
                            </td>
                          </tr>
                          <tr>
                            <td>When you contact us</td>
                            <td>
                              <p>Contact Data</p>
                              <p>Customer Support Data</p>
                            </td>
                            <td>
                              <p>Legitimate Interest</p>
                            </td>
                            <td>
                              When you contact us, we will usually collect your
                              name, contact details and information relating to
                              your query, so that we can make sure we properly
                              respond to your query.
                            </td>
                          </tr>
                          <tr>
                            <td>When you use our app</td>
                            <td>
                              <p>Usage Data</p>
                            </td>
                            <td>
                              <p>Legitimate Interest</p>
                              <p>Consent</p>
                            </td>
                            <td>
                              When you use our website and/or app we collect
                              information about how you interact with the platform
                              including how often you use it and which features
                              and services you engage with so that we can continue
                              to improve how the platform works.
                              <br />
                              We collect this data using Google’s Analytics platform.
                              To learn more about how Google uses and stores your data,
                              please refer to <a href="https://policies.google.com/technologies/partner-sites">
                                Google’s Privacy & Terms site</a>.
                              If you do not agree to us using your usage data to
                              improve our platform, you can choose to disallow this in
                              our cookie prompt – this data is collected under the
                              ‘Analytical cookies’ option and can be turned on or off at any time.
                            </td>
                          </tr>
                          <tr>
                            <td>When you use our app</td>
                            <td>
                              <p>Usage Data</p>
                            </td>
                            <td>
                              <p>Consent</p>
                            </td>
                            <td>
                              When you visit our website, we also monitor where you came
                              to the website from. We use this information to understand
                              whether our marketing activities are resonating with consumers
                              and inspiring them to visit our site. We also use it to
                              identify who should receive which adverts for example adverts
                              for people that have not visited us before and different adverts
                              for those that have visited us before i.e., personalised
                              and non-personalised adverts.
                              <br />
                              To ensure you consent to this, we provide a cookie permission
                              check that ask whether you consent to us knowing whether you
                              visited us from one of our marketing campaigns.
                              <br />
                              If you do not agree to us using your usage data to understand
                              whether our marketing activities brought you to our site,
                              you can choose to disallow this in our cookie prompt – this
                              data is collected under the ‘Advertising cookies’ option and
                              can be turned on or off at any time.
                              <br />
                              When we monitor our advertising campaigns, we do so to
                              understand which platform visits have come to us from.
                              We monitor the following platforms and have linked to
                              their relevant privacy policies.
                              <br />
                              Pinterest |
                              Meta |
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>&nbsp;</p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">Our third-party providers</span>
                    </strong>
                    <p>
                      When we monitor our advertising campaigns, we do so to
                      understand which platform visits have come to us from.
                      To do this, we may share certain information with third
                      party advertising platforms such as Meta, TikTok,
                      Pinterest and Google to enable us to better target our
                      advertising on those third-party platforms to users of
                      our Service who are also users on those platforms, or to
                      individuals on those platforms that may be similar to
                      users of our Service.
                    </p>
                    <p>
                      In addition, we may also use information we collect via the
                      Ownish website and app to create “audience groups” for the
                      purposes of our advertising on third party platforms. In those
                      circumstances, we may share certain information with third
                      party platform providers which allows us to better target our
                      advertising to users on those third-party platforms. Any
                      third-party platforms will also have their own privacy policies
                      which you should read.
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">Marketing and content updates</span>
                    </strong>
                    <p>
                      You will receive marketing and new content communications
                      from us if you have created an account and chosen to opt
                      into receiving those communications. From time to time we
                      may make suggestions and recommendations to you about
                      goods or services that may be of interest to you.
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">Change of purpose</span>
                    </strong>
                    <p>
                      We will only use your Personal Data for the purposes for
                      which we collected it, unless we reasonably consider that
                      we need to use it for another reason and that reason is
                      compatible with the original purpose. If you wish to get
                      an explanation as to how the processing for the new
                      purpose is compatible with the original purpose, please
                      contact us.
                    </p>
                    <p>
                      If we need to use your Personal Data for an unrelated
                      purpose, we will notify you and we will explain the legal
                      basis which allows us to do so.
                    </p>
                    <p>
                      Please note that we may process your Personal Data without
                      your knowledge or consent, in compliance with the above
                      rules, where this is required or permitted by law.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  <span className="sectit">
                    YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US
                  </span>
                </strong>
                <ol className="nb">
                  <li>
                    <strong>
                      <span className="bold">Your legal rights</span>
                    </strong>
                    <p>
                      Under certain circumstances, you have the following rights
                      under data protection laws in relation to your personal
                      data:
                    </p>
                    <ul>
                      <li>
                        <span className="bold">Right to be informed</span>. You have
                        a right to be informed about our purposes for processing
                        your personal data, how long we store it for, and who it
                        will be shared with. We have provided this information
                        to you in this policy.
                      </li>
                      <li>
                        <span className="bold">Right of access</span>. This enables
                        you to receive a copy of the personal data we hold about
                        you and to check that we are lawfully processing it
                        (also known as a "data subject access request"). See
                        section 4.5 below for more details on how you can make a
                        data subject access request.
                      </li>
                      <li>
                        <span className="bold">Right to rectification</span>. You
                        have a right to request correction of the personal data
                        that we hold about you. This enables you to have any
                        incomplete or inaccurate data we hold about you
                        corrected, though we may need to verify the accuracy of
                        the new data you provide to us.
                      </li>
                      <li>
                        <span className="bold">Right to erasure</span>. You have the
                        right to ask us to delete or remove personal data where
                        there is no good reason for us continuing to process it,
                        where you have successfully exercised your right to
                        object to processing (see below), where we may have
                        processed your information unlawfully or where we are
                        required to erase your personal data to comply with
                        local law. Note, however, that we may not always be able
                        to comply with your request of erasure for specific
                        legal reasons which will be notified to you, if
                        applicable, at the time of your request.
                      </li>
                      <li>
                        <span className="bold">Right to object</span>. You can
                        object to the processing of personal data we hold about
                        you. This effectively allows you to stop or prevent us
                        from processing your personal data. Note that this is
                        not an absolute right and it only applies in certain
                        circumstances, for example:
                        <ol className="roman">
                          <li>
                            Where we are processing your personal data for
                            direct marketing purposes.
                          </li>
                          <li>
                            Where we are relying on a legitimate interest (or
                            those of a third party) and there is something about
                            your particular situation which makes you want to
                            object to processing on this ground as you feel it
                            impacts on your fundamental rights and freedoms.
                          </li>
                          <li>
                            In some cases, we may continue processing your data
                            if we can demonstrate that we have compelling
                            legitimate grounds to process your information which
                            override your rights and freedoms.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span className="bold">Right to restrict processing</span>.
                        You have the right to request the restriction or
                        suppression of their personal data. Note that this is
                        not an absolute right and it only applies in certain
                        circumstances:
                        <ol className="roman">
                          <li>
                            If you want us to establish the data's accuracy.
                          </li>
                          <li>
                            Where our use of the data is unlawful but you do not
                            want us to erase it.
                          </li>
                          <li>
                            Where you need us to hold the data even if we no
                            longer require it as you need it to establish,
                            exercise or defend legal claims.
                          </li>
                          <li>
                            You have objected to our use of your data but we
                            need to verify whether we have overriding legitimate
                            grounds to use it.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span className="bold">Right to data portability</span>. You
                        have the right to request the transfer of your personal
                        data to you or to a third party. If you make such a
                        request, we will provide to you, or a third party you
                        have chosen, your personal data in a structured,
                        commonly used, machine-readable format. Note that this
                        right only applies to automated information which you
                        initially provided consent for us to use or where we
                        used the information to perform a contract with you.
                      </li>
                    </ul>
                    <p>
                      If you wish to make a request under any of these rights,
                      please contact us at hello@ownish.co.uk.
                    </p>
                  </li>
                  <li>
                    <span className="bold">
                      <strong>
                        Your control over Ownish Ltd’s use of your Personal
                        Data
                      </strong>
                    </span>
                    <p>
                      You may delete your account at any time – this will remove
                      your account page from our systems and our related
                      software.
                    </p>
                    We do not guarantee the ability to delete all stored data.
                    If you would like us to delete/correct personally
                    identifiable data, let us know and we will action your
                    request as soon as practicable.
                    <p>
                      You can access information associated with your account by
                      logging into your account you created with us.
                    </p>
                    <p>
                      Your account information will be protected by a password
                      for your privacy and security. You need to prevent
                      unauthorized access to your account and personal
                      information by selecting and protecting your password
                      appropriately and limiting access to your computer or
                      device and by signing off after you have finished
                      accessing your account.
                    </p>
                    <p>
                      <span className="italic">
                        California Privacy Rights: Under California Civil Code
                        sections 1798.83-1798.84, California residents are
                        entitled to ask us for a notice identifying the
                        categories of personal customer information which we
                        share with our affiliates and/or third parties for
                        marketing purposes, and providing contact information
                        for such affiliates and/or third parties. If you are a
                        California resident and would like a copy of this
                        notice, please submit a written request to
                        hello@ownish.co.uk.
                      </span>
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">
                        How Ownish Ltd protects customers' Personal Data
                      </span>
                    </strong>
                    <p>
                      We are concerned with keeping your data secure and
                      protecting it from inappropriate disclosure. Any Personal
                      Data collected by us is only accessible by a limited
                      number of employees who have special access rights to such
                      systems and are bound by obligations of confidentiality.
                      If and when we use subcontractors to store your data, we
                      will not relinquish control of your Personal Data or
                      expose it to security risks that would not have arisen had
                      the data remained in our possession. However,
                      unfortunately no transmission of data over the internet is
                      guaranteed to be completely secure. It may be possible for
                      third parties not under the control of Ownish Ltd to
                      intercept or access transmissions or private
                      communications unlawfully. While we strive to protect your
                      Personal Data, we cannot ensure or warrant the security of
                      any Personal Data you transmit to us. Any such
                      transmission is done at your own risk. If you believe that
                      your interaction with us is no longer secure, please
                      contact us.
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">
                        Opting out of marketing promotions
                      </span>
                    </strong>
                    <p>
                      You can ask us to stop sending you marketing messages at
                      any time by You can ask us to stop sending you marketing
                      messages at any time by clicking 'unsubscribe' on our
                      email, by DMing us, or emailing us at hello@ownish.co.uk.
                    </p>
                    <p>
                      Where you opt out of receiving these marketing messages,
                      we will continue to retain other Personal Data provided to
                      us as a result of interactions with us not related to your
                      marketing preferences.
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">
                        How to request your data and the process for obtaining
                        it
                      </span>
                    </strong>
                    <p>
                      You will not have to pay a fee to access your Personal
                      Data (or to exercise any of the other rights). However, if
                      your request is clearly unfounded, we could refuse to
                      comply with your request.
                    </p>
                    <p>
                      We may need to request specific information from you to
                      help us confirm your identity and ensure you have the
                      right to access your Personal Data (or to exercise any of
                      your other rights). This is a security measure to ensure
                      that Personal Data is not disclosed to any person who has
                      no right to receive it. We may also contact you to ask you
                      for further information in relation to your request to
                      speed up our response.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  <span className="sectit">YOUR DATA AND THIRD PARTIES</span>
                </strong>
                <ol className="nb">
                  <li>
                    <strong>
                      <span className="bold">
                        Sharing your data with third parties
                      </span>
                    </strong>
                    <p>
                      We may share non-Personal Data with third parties. We may
                      share your Personal Data with subcontractors or
                      affiliates, subject to confidentiality obligations to use
                      it only for the purposes for which we disclose it to them
                      and pursuant to our instructions.
                    </p>
                    <p>
                      We may also share Personal Data with interested parties in
                      the event that Ownish Ltd anticipates a change in control
                      or the acquisition of all or part of our business or
                      assets or with interested parties in connection with the
                      licensing of our technology.
                    </p>
                    <p>
                      If Ownish Ltd is sold or makes a sale or transfer, we
                      may, in our sole discretion, transfer, sell or assign your
                      Personal Data to a third party as part of or in connection
                      with that transaction. Upon such transfer, the Privacy
                      Policy of the acquiring entity may govern the further use
                      of your Personal Data. In all other situations your data
                      will still remain protected in accordance with this
                      Privacy Policy (as amended from time to time).
                    </p>
                    <p>
                      We may share your Personal Data at any time if required
                      for legal reasons or in order to enforce our terms or this
                      Privacy Policy.
                    </p>
                  </li>
                  <li>
                    <strong>
                      <span className="bold">Third-Party Links</span>
                    </strong>
                    <p>
                      This Site may include links to third-party websites,
                      plug-ins and applications. Clicking on those links or
                      enabling those connections may allow third parties to
                      collect or share data about you. We do not control these
                      third-party websites and are not responsible for their
                      privacy statements. When you leave our Site, we encourage
                      you to read the privacy policy of every website you visit.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  <span className="sectit">HOW LONG WE RETAIN YOUR DATA</span>
                </strong>
                <p>
                  We will only retain your Personal Data for as long as
                  reasonably necessary to fulfil the purposes we collected it
                  for. We may retain your Personal Data for a longer period than
                  usual in the event of a complaint or if we reasonably believe
                  there is a prospect of litigation in respect to our
                  relationship with you.
                </p>
              </li>
              <li>
                <strong>
                  <span className="sectit">AGE LIMIT FOR OUR USERS</span>
                </strong>
                <p>
                  You must not use Ownish Ltd unless you are aged 16 or older.
                  If you are under 16 and you access Ownish Ltd by lying about
                  your age, you must immediately stop using Ownish Ltd.
                </p>
                <p>
                  This website is not intended for children and we do not
                  knowingly collect data relating to children.
                </p>
              </li>
              <li>
                <strong>
                  <span className="sectit">INTERNATIONAL TRANSFER OF DATA</span>
                </strong>
                <p>
                  Your information may be stored and processed in the US or
                  other countries or jurisdictions outside the US where Ownish
                  Ltd has facilities. By using Ownish Ltd, you are permitting
                  and consenting to the transfer of information, including
                  Personal Data, outside of the US.
                </p>
              </li>
              <li>
                <strong>
                  <span className="sectit">
                    NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY
                  </span>
                </strong>
                <p>
                  We keep our Privacy Policy under review and will place any
                  updates here. This version is dated 30 December 2022.
                </p>
                <p>
                  By using Ownish Ltd, you consent to the collection and use of
                  data by us as set out in this Privacy Policy. Continued access
                  or use of Ownish Ltd will constitute your express acceptance
                  of any modifications to this Privacy Policy.
                </p>
              </li>
              <li>
                <strong>
                  <span className="sectit">INTERPRETATION</span>
                </strong>
                <p>
                  All uses of the word "including" mean "including but not
                  limited to" and the enumerated examples are not intended to in
                  any way limit the term which they serve to illustrate. Any
                  email addresses set out in this policy may be used solely for
                  the purpose for which they are stated to be provided, and any
                  unrelated correspondence will be ignored. Unless otherwise
                  required by law, we reserve the right to not respond to
                  emails, even if they relate to a legitimate subject matter for
                  which we have provided an email address. You are more likely
                  to get a reply if your request or question is polite,
                  reasonable and there is no relatively obvious other way to
                  deal with or answer your concern or question (e.g. FAQs, other
                  areas of our website, etc.).
                </p>
                <p>
                  Our staff are not authorised to contract on behalf of Ownish
                  Ltd, waive rights or make representations (whether contractual
                  or otherwise). If anything contained in an email from a
                  Ownish Ltd address contradicts anything in this policy, our
                  terms or any official public announcement on our website, or
                  is inconsistent with or amounts to a waiver of any Ownish Ltd
                  rights, the email content will be read down to grant
                  precedence to the latter. The only exception to this is
                  genuine correspondence expressed to be from the Ownish Ltd
                  legal department.
                </p>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
