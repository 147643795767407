import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

const Radio = ({ type, label, name, id, onChange, key, checked }) => {
  return (
    <div>
      <Form.Check
        type={type}
        label={label}
        name={name}
        id={id}
        onChange={onChange}
        key={key}
        checked={checked}
      />
    </div>
  );
};

Radio.propTypes = {
  type: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  id: PropTypes.any,
  onChange: PropTypes.any,
  key: PropTypes.any,
  checked: PropTypes.any
}

export default Radio;
