import * as types from "./../actionType";

const INITIAL_STATE = {
  isLoggedIn: false,
  userId: null,
  userName: null,
  bearerToken: null,
  fullName: "",
  email: "",
  role: "",
  insta_access_token: "",
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case types.APP_ACTIONS.LOGIN:
    case types.APP_ACTIONS.REGISTER: {
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
        bearerToken: action.bearerToken,
        userId: action.userId,
        userName: action.userName,
        email: action.email,
      };
    }
    case types.APP_ACTIONS.LOGOUT: {
      localStorage.removeItem('userEmail');
      return {
        ...state,
        isLoggedIn: false,
        bearerToken: null,
        userId: null,
        userName: null,
        email: "",
      };
    }
    case types.APP_ACTIONS.INSTA_ACCESSTOKEN: {
      return {
        ...state,
        insta_access_token: action.payload,
      };
    }
    default:
      return state;
  }
};
