import axios from "axios";

import Config from "./../../axios/config";
import { GIFT_CARDS } from "../actionType";

const { REST_API } = Config;

const getGiftCardAmountList = () => async (dispatch) => {
  try {
    const res = await axios.get(REST_API.GiftCards.getGiftAmounts, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    });
    const baseModel = res.data;
    if (baseModel.success) {
      const { data } = baseModel;
      const giftAmounts = {};
      data
        .filter((giftAmount) => giftAmount.isActive)
        .sort((a, b) => a.amount - b.amount)
        .map((giftAmount) => (giftAmounts[giftAmount.id] = giftAmount.amount));
      return { success: true, data: giftAmounts };
    } else throw new Error("");
  } catch (err) {
    console.log({ err });
    return { success: false, data: [] };
  }
};

const postGetGiftCardPaymentIntent = (giftCard) => async (dispatch) => {
  dispatch({
    type: GIFT_CARDS.SET_GIFT_CARD_DATA,
    payload: giftCard,
  });
  const giftCardData = {
    amount: giftCard.amount * 100,
    currency: giftCard.currency,
    giftCardId: giftCard.giftAmountId,
    senderName: giftCard.senderName,
    senderEmail: giftCard.senderEmail,
    userId: giftCard?.userId,
  };
  try {
    const res = await axios.post(REST_API.GiftCards.postPaymentIntent, giftCardData, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    });
    const baseModel = res.data;
    if (baseModel.success) {
      const { data } = baseModel;
      dispatch({
        type: GIFT_CARDS.SET_STRIPE_INTENT,
        payload: data,
      });
      return { success: true };
    } else throw new Error("");
  } catch (err) {
    console.log({ err });
    return { success: false };
  }
};

//:- Saving gift card data to backend
const saveGiftCardData = (giftCard) => async (dispatch) => {
  try {
    const res = await axios.post(REST_API.GiftCards.postCardDetails, giftCard, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    });
    const baseModel = res.data;
    console.log({ baseModel });
    if (baseModel.success) {
      return { success: true };
    } else throw new Error("");
  } catch (err) {
    console.log({ err });
    return { success: false };
  }
};

const clearPaymentIntent = () => (dispatch) => {
  dispatch({
    type: GIFT_CARDS.CLEAR_STRIPE_INTENT,
  });
};

export {
  getGiftCardAmountList,
  postGetGiftCardPaymentIntent,
  saveGiftCardData,
  clearPaymentIntent,
};
