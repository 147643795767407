const colors = {
  pink: '#FFB3C3',
  pastelPink: '#ffd1dc',
  pinkWith25Opacity: 'rgba(255, 179, 195, 0.25)',
  pinkWith50Opacity: 'rgba(255, 179, 195, 0.5)',
  pinkWith70Opacity: 'rgba(255, 179, 195, 0.7)',
  pinkWith24Opacity: 'rgba(255, 179, 195, 0.24)',
  pinkMesageBackground: 'rgba(255, 197, 215, 0.3)',
  sealBrown: '#240201',
  white: '#FFFFFF',
  orange: '#FF5A08',
  black: '#000000',
  gray: 'gray',
  lightGray: '#E9E9E9',
  silver: '#C0C0C0',
  placeholderTextColor: 'rgba(40, 0, 0, 0.5)',
  optionDetails: 'rgba(0, 0, 0, 0.75)',
  infoBox: '#F2F2F2',
  red: '#EA0000',
  headingColor: '#280000',
  blackWith8Opacity: 'rgba(0, 0, 0, 0.08)',
};

export { colors };
