import React from "react";
import { user } from "../../images/index";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as AppActions from "../../redux/actions/AppActions";
import { Envalop, Heart } from "../common/SVGIcons";
import * as productActions from "../../redux/actions/productActions";
import * as MessagingActions from "../../redux/actions/messagingActions";
import { bindActionCreators } from "redux";
import { resetAmplitudeUserId, sendAmplitudeData } from "../../../utils/amplitude";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const UserProfileCart = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const logOut = () => {
    props.AppActions.logout();
    sendAmplitudeData("Logout_User", {
      Event: "Users logout from the Web Application",
    });
    resetAmplitudeUserId();
    navigate("/");
  };
  const myProfile = () => {
    navigate(`/profile?userId=${props.userId}`)
  };

  const onButtonCLick = (path) => {
    const currentPath = location.pathname;
    if (currentPath.indexOf("/products") > -1) {
      props.productActions.setReturnPdpPath(currentPath);
    }
    navigate(path, { state: null });
  };

  const initials = props?.userName?.substring(0, 1);

  return (
    <div className="profileCartWrapper">
      {props.isLoggedIn && (
        <>
          <Button variant="link" className="px-0 pt-2" onClick={() => navigate("/wishlist")}>
            <span className="messageIcon headerHeartIcon" >
              <Heart />
            </span>
          </Button>

          <Button variant="link" className="px-0 pt-2" onClick={() => {
            sendAmplitudeData('Message-User views "Inbox"', {
              Event: 'User clicks "Inbox" from Top menu',
            });
            navigate("/inbox");
          }}>
            <span
              className="messageIcon"
            >
              <Envalop />
              {props.messageCounts > 0 && <i className="badge">{props.messageCounts}</i>}
            </span>
          </Button>
        </>
      )}

      {location.pathname === "/login" || location.pathname === "/register" || !props.isLoggedIn ? (
        <span className="loginSignupBtn">
          <button onClick={() => onButtonCLick("/register")}>Sign up</button>
          <button onClick={() => onButtonCLick("/login")}>Sign in</button>
        </span>
      ) : (
        <span className="userPicture">
          <i className="userProfileName">{props.isLoggedIn ? "Hi, " + props.userName : " "}</i>
          <i>
            {props.user ? (
              <img src={user} alt="" />
            ) : (
              <span className="userInitial">{initials}</span>
            )}
          </i>
          <span className="loginSignupBtn profileBtnWrapper">
            {props.isLoggedIn && (
              <>
                <span onClick={myProfile}> My Profile</span>
                <span onClick={logOut}> Sign out</span>
              </>
            )}
          </span>
        </span>
      )}
    </div>
  );
};

const mapStateToProps = ({ app, messaging }) => {
  const { isLoggedIn, userName, userId } = app;
  let messageCounts = messaging?.allMsgsNotificationsCount;
  return { isLoggedIn, userName, userId, messageCounts };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AppActions: bindActionCreators(AppActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
    MessagingActions: bindActionCreators(MessagingActions, dispatch),
  };
};

UserProfileCart.propTypes = {
  userName: PropTypes.string,
  user: PropTypes.string,
  messageCounts: PropTypes.any,
  isLoggedIn: PropTypes.bool,
  userId: PropTypes.string,
  productActions: PropTypes.any,
  AppActions: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileCart);
