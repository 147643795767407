import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListFAQ from "./ListFAQ";

const RentFAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contentBodyInner termsOfServicesWrapper">
      <Container>
        <h1 className="metaHeading">
          Learn how to rent and to make money lending your clothes to others.
        </h1>
        <Row>
          <Col>
            <h1>How to rent &amp; FAQs</h1>
            <h3>How long do I have the item(s) for?</h3>
            <p>
              You have the item until the rental end date, which is determined by the length of the
              rental you chose. You can choose from one of the pre-set periods; 4, 8, 12 and 18
              days. Or, just ask the lister if you want to rent it for a different period. On the
              final day of your rental, you must return the item(s) to the address provided.&nbsp;
            </p>

            <h3>Who pays for postage?</h3>
            <p>
              The cost of posting the item to you is included in the price of the rental, unless you
              want express delivery, which is an extra cost. You pay the cost of returning the item
              to the lister. Please ensure you use a tracked signed for service when returning the
              item.
            </p>

            <h3>How do I return the item(s)</h3>
            <p>
              Please return your item to the address provided to you in the Orders section of your
              app. We highly recommend that you use a guaranteed next day service such as Royal Mail
              Special Delivery before 1pm so that the item is insured in case of loss and the renter
              gets it back swiftly ready for their next rental. If the item is lost in the post you
              are responsible for following this up with the postal company and claiming back any
              reimbursements costs due to the lister.{" "}
            </p>

            <h3>What is 'Stress-free Renting'?</h3>
            <p>
              'Stress Free Renting' is an optional fee a renter can take out that will cover the
              cost of repairing an item up to £100. This is not a financial product and will only be
              paid by Ownish to its tailors or one agreed between the lister and renter in case of
              accidental damage that can be repaired. This will not cover irreparable damage,
              replacement costs or lost or stolen items.{" "}
            </p>

            <h3>Who cleans the item?</h3>
            <p>
              The owner of the item will clean it, please do not try to clean the item in any way -
              even if it is has stains or smells of perfume, simply return it to the address
              provided and let the lister know if there are any issues with it. Incorrect cleaning
              of the item may result in damage so it's best to leave it to the lister.{" "}
            </p>

            <h3>What if I change my mind about my rental and no longer want it?</h3>
            <p>
              In the first instance, contact the lister directly to ask them. If the lister and
              Ownish agree to process a refund request made 15 or more days before your rental start
              date, you'll be refunded onto your original payment method, minus a small admin fee
              that covers the payment charges.
            </p>

            <p>
              If you cancel on or within 14 days of your rental start date, you will be charged £10
              or 10% of the rental value, whichever is greater. If the lister has already posted the
              item, a refund may not be possible, and this will be reviewed on a case-by-case basis
              as agreed by the lister. This is at the discretion of the lister, not Ownish.
            </p>

            <p>
              {" "}
              Please note it can take up to 10 days for your bank or credit card company to credit
              your refund to your account. If you have taken out the optional stress-free renting
              cover, these cost cannot be refunded in the event that a rental is cancelled within 14
              days.
            </p>

            <h3>What if the item doesn't fit?</h3>
            <p>
              We advise messaging the lister in advance of requesting your dates to ask them about
              fit. If when it turns up you can't wear it, then let the lender know immediately and
              see if there is anything they can do. Each lender sets their own refund policy. If the
              lister agrees a refund, please send the item back within 24 hours using a guaranteed
              signed for service, not on the rental end date.
            </p>

            <h3>Why have I been asked to provide photo and address ID?</h3>
            <p>
              The safety of our community is our top priority, so it's important that we know who is
              renting or listing an item. On some occasions, we will ask you to provide your ID and
              proof of postal address to continue to use the Ownish platform. All identification
              documents are stored security and once verified are permanently deleted.{" "}
            </p>

            <h3>What happens if the item(s) gets lost or damaged?</h3>
            <p>
              Please look after the item(s) as if it was your own. If an item(s) gets accidentally
              lost, damaged, stolen or is badly stained, let the lister know right away and try and
              resolve it directly with them. You can also let Ownish know and we will try our best
              to help fix things. If we really can't, we will ask you to pay the Resale price or the
              cost of repair. The exception to this is if we believe the item has been deliberately
              or negligently damaged in which case you may be charged a further fee. Ownish may also
              decide to charge an admin fee for having to resolve the dispute if you do not work us.
            </p>

            <h3>What happens if I do not return my rental on time?</h3>
            <p>
              If for any reason you don't manage to post the item on or before the rental end date,
              please let the lister know. Should the lister open a dispute, Ownish reserves the
              right to charge your payment method for the extension and any additional fees that
              have been incurred as a result of Ownish being asked to intervene.
            </p>

            <h3>What happens if the item(s) gets lost in the post?</h3>
            <p>
              We highly recommend posting items back using a tracked signed for service with the
              relevant insurance to avoid this happening. Please ensure you keep tracking and/or
              proof of postage so that should the item be lost in the post you can take it up with
              the delivery company. You are responsible for contacting the delivery company and
              either finding the item or determining that is it lost. If the item does not get
              returned to the lister, we will ask you to pay the Resale price or the replacement
              cost whichever is less. Ownish reserves the right to charge your payment method for
              these costs.
            </p>

            <h3>What happens if I don't return the item?</h3>
            <p>
              We really hope this never happens. But if you don't return the item - without the
              lister's authority - Ownish will charge your card 200% of the item's RRP to cover the
              cost of replacing the item and the lost rentals. Ownish retains the right to charge
              any card including from subsequent rentals made on other cards and accounts associated
              with you to cover the cost of the unreturned item.
            </p>
            <p>
              {" "}
              If you do not return an item and book another, Ownish may cancel your order but not
              refund the rental amount to cover the costs of the unreturned item. Ownish will also
              raise a stolen item case with the Police and forward on all your details to them for
              investigation. Any costs associated with recovering the funds are payable by you.
            </p>

            <h3>How are fees for damage, late returns, unreturned items and disputes charged?</h3>
            <p>
              Ownish reserves the right to automatically charge your card for any fees following our
              investigation into any disputes on these matters between renters and listers. Should
              Ownish be asked to intervene, Ownish's decision is final.{" "}
            </p>

            <h3>What happens if the items arrives unclean or damaged and unable to be worn?</h3>
            <p>
              Inspect your order upon receipt and contact the lister immediately on the day it
              arrives if there are any issues. As the items on Ownish are rental items, they will
              not be brand new and may have some minor marks, or some sequins and jewels missing.
              This should be considered normal. Ownish is here to help extend the life of clothes
              and enable everyone to have access to on trend beautiful items.
            </p>
            <p>
              {" "}
              In some instances, a zip may be difficult to use - which is normal on some items - and
              the lister should be able to help resolve it easily. If however, there is damage that
              means the item cannot be worn, or it's not clean, then you must in the first instance
              contact the lister and try to resolve the issues with them directly. If you are unable
              to agree a resolution with the lister, then you can contact Ownish to open a dispute.
              To do so, you must:
            </p>
            <ul>
              <li>Contact Ownish within 5 days of your rental period starting</li>
              <li>
                Show evidence you contacted the lister on the day you received the item reporting
                the issue
              </li>
              <li>Proof that you and the lister have not been able to agree a resolution</li>
              <li>
                Agree that Ownish will attempt to mediate a resolution and that you agree to the
                decision that Ownish makes on the outcome
              </li>
            </ul>
            <p>
              {" "}
              We aim to acknowledge all disputes within 24 hours. The best way to contact us if you
              are unable to resolve a dispute is to email us at hello@ownish.co.uk sending an
              explanation of what happened and the evidence set-out above.
            </p>

            <h3>How do I get a refund?</h3>
            <p>
              If the lister and Ownish agrees to process a refund where the refund request has been
              made 15 or more days before your rental start date, you'll be refunded onto your
              original payment method minus a small admin fee that covers the payment charges
              applied by the payment provider.
            </p>

            <p>
              {" "}
              If you cancel on or within 14 days of your rental start date, you will be charged £10
              or 10% of the rental value, whichever is greater. If the lister has already posted the
              item, a refund may not be possible, and this will be reviewed on a case-by-case basis
              and discussed with the lister. This is at the discretion of the lister, not Ownish.
            </p>
            <p>
              Please note it can take a few days for your bank or credit card company to credit your
              refund. If you have taken out the optional stress-free renting cover, these cost
              cannot be refunded in the event that a rental is cancelled within 14 days.
            </p>
            <p>
              Please also refer to our full refund policy which can be found at
              https://ownish.co.uk/refund-policy
            </p>
          </Col>
        </Row>
      </Container>
      <ListFAQ />
    </div>
  );
};

export default RentFAQ;
