import React from "react";
import AppButton from "../../common/AppButton";
import PropTypes from "prop-types";

const BuyProduct = (props) => {
  const { retailPrice, onclick } = props;
  return (
    <div className="checkoutProductInfo buyWrapper">
      <div className="orderCTA">
        <span>
          <i>
            Buy now <strong>&#163;{retailPrice}</strong>
          </i>
        </span>

        <span>
          <AppButton text="BUY" onclick={onclick} variant="outline" />
        </span>
      </div>
    </div>
  );
};

BuyProduct.propTypes = {
  retailPrice: PropTypes.any,
  onclick: PropTypes.any,
}
export default BuyProduct;
