import Dropdown from 'react-bootstrap/Dropdown';
import { TbArrowsSort } from 'react-icons/tb'
import PropTypes from "prop-types";

const Sorting = (props) => {
  const { dropdownValue } = props;

  return (
    <Dropdown onSelect={dropdownValue}>
      <Dropdown.Toggle variant="outlined" id="dropdown-basic">
        <TbArrowsSort /> Sort
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="What's new" >What's new?</Dropdown.Item>
        <Dropdown.Item eventKey="High to low" >High to low</Dropdown.Item>
        <Dropdown.Item eventKey="Low to high" >Low to high</Dropdown.Item>
        <Dropdown.Item eventKey="Position" >Recommended</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
Sorting.propTypes = {
  dropdownValue: PropTypes.any,
}
export default Sorting;