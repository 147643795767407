import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getWishList } from "../../../services/getProductService";
import { useInfiniteQuery } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCategorySlug, getScrollThreshold } from "../../utilities/helpers";
import SpinnerComponent from "../common/Spinner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AppActions from "../../redux/actions/AppActions";
import { convertTitleToSlug } from "../../../utils/utilities";
import RentallTile from "../common/RentallTile";
import PropTypes from "prop-types";

const Wishlist = (props) => {
  const fetchProducts = async ({ pageParam = 1 }) => {
    let result = await getWishList({
      userId: props.userId,
      limit: 20,
      current: pageParam,
    });
    return result;
  };

  let dataLength = 20;
  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
    queryKey: ["wishListProducts"],
    queryFn: fetchProducts,
    getNextPageParam: (lastPage, pages) => {
      const totalDataLength = pages.reduce(
        (totalLength, page) => totalLength + page?.data?.length,
        0
      );
      let nextPage = lastPage.hasMore ? lastPage.nextPage : undefined;
      dataLength = totalDataLength;
      return nextPage;
    },
  });

  return (
    <div className="productTilesGridWrapper wishlistWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h3>Wishlist</h3>
          </Col>
        </Row>
        <InfiniteScroll
          style={{ overflowX: "hidden" }}
          dataLength={dataLength}
          next={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={<SpinnerComponent />}
          scrollThreshold={getScrollThreshold()}
        >
          <Row>
            {data?.pages.map((group, i) => {
              return (
                <React.Fragment key={`Group_${i}`}>
                  {group?.data.map((item) => (
                    <Col lg={4} xl={3} md={6} xs={6} key={item.id} className="mobileTiles">
                      <RentallTile
                        picture={item.fields.src}
                        title={item.fields.title}
                        size={item.fields.size}
                        dress={item.fields.category}
                        rrp={item.fields.retailprice}
                        price={item.fields.rentalprice}
                        isInMyWishList={item.isInMyWishList}
                        color={item.fields.colour}
                        category={item.fields.category}
                        resalePrice={item.resaleprice}
                        productId={item.id}
                        refetch={refetch}
                        onclick={`/products/${getCategorySlug(
                          item.fields.category
                        )}-${convertTitleToSlug(item.fields.title)}-${item.fields.colour}-${item.id
                          }`}
                      />
                    </Col>
                  ))}
                </React.Fragment>
              );
            })}
          </Row>
        </InfiniteScroll>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  userId: app.userId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AppActions: bindActionCreators(AppActions, dispatch),
  };
};

Wishlist.propTypes = {
  userId: PropTypes.any,
}
export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
