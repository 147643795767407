import React from "react";
import PropTypes from "prop-types";

const Tag = ({ icon, text, classname }) => {
  return (
    <span className={` ${classname} tag`}>
      <span>{icon}</span>

      <i>{text}</i>
    </span>
  );
};

Tag.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.any,
  classname: PropTypes.any,

}
export default Tag;
