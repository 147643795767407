import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CollectionsTile from "../../common/CollectionsTile";
import { connect } from "react-redux";
import * as ExploreActions from "../../../redux/actions/ExploreActions";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { convertTitleToSlug } from "../../../../utils/utilities";
import PropTypes from "prop-types";

const AllCollections = ({ ExploreActions, collections }) => {
  const navigate = useNavigate();

  useEffect(() => {
    ExploreActions.fetchCollections();
  }, []);

  const handleNavigate = (item) => {
    navigate(`/collection/${convertTitleToSlug(item.title)}-${item.id}`);
  };
  return (
    <Container>

      <Row>
        {collections?.map((item, index) => {
          return (
            <Col
              onClick={() => handleNavigate(item)}
              lg={4}
              xl={3}
              md={6}
              xs={6}
              style={{ marginTop: "1rem" }}
              key={item.id}
            >
              <CollectionsTile picture={item.coverImageUrl} title={item.title} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ explore }) => {
  return explore;
};

const mapDispatchToProps = (dispatch) => {
  return {
    ExploreActions: bindActionCreators(ExploreActions, dispatch),
  };
};

AllCollections.propTypes = {
  ExploreActions: PropTypes.any,
  collections: PropTypes.any,

}
export default connect(mapStateToProps, mapDispatchToProps)(AllCollections);
