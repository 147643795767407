import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import PropTypes from "prop-types";
const carouselImg =
  "https://ownish.s3.eu-west-2.amazonaws.com/Swished_Production_Artifacts/Website+hero+banner.png";
const mobileCaroselImage =
  "https://ownish.s3.eu-west-2.amazonaws.com/Resized+banner+for+mobile+web.png";

const ProductCarousel = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <Carousel fade interval={null}>
      {data?.map((item) => {
        return (
          <Carousel.Item key={item.id}>
            <img
              src={isMobile ? mobileCaroselImage : carouselImg}
              alt={`carousel-slide+ ${item.id}`}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

ProductCarousel.propTypes = {
  data: PropTypes.any,
};

export default ProductCarousel;
