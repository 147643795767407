import React from "react";
import { useLocation } from "react-router-dom";
import { useWishlist } from "../../../services/useWishlist";
import { FillHeart, Heart } from "./SVGIcons";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const ToggleWishlistButton = ({ productId, isInMyWishList, refetch, productActions }) => {
  const { toggleWishList, isWishlisted } = useWishlist(isInMyWishList, refetch);
  const location = useLocation();
  let isSearchProduct = location.pathname.includes("search");

  return (
    <div>
      {!isSearchProduct && (
        <Button variant="link" className="p-0" onClick={(e) => {
          e.preventDefault();
          productActions("/products/" + productId);
          toggleWishList(productId);
        }}>
          <span
            className="tileWishlistWrapper"
          >
            {isWishlisted ? <FillHeart /> : <Heart />}
          </span>
        </Button>
      )}
    </div>
  );
};


ToggleWishlistButton.propTypes = {
  productId: PropTypes.any,
  isInMyWishList: PropTypes.any,
  refetch: PropTypes.any,
  productActions: PropTypes.any,
};

export default ToggleWishlistButton;
