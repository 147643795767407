import { MESSAGING } from "../actionType";

export const setChatIds = (payload) => {
  return {
    type: MESSAGING.SET_CHAT_IDS,
    payload,
  };
};

export const setLoading = (payload) => {
  return {
    type: MESSAGING.SET_LOADING,
    payload,
  };
};

export const setChatRooms = (payload) => {
  return {
    type: MESSAGING.SET_CHAT_ROOMS,
    payload,
  };
};

export const setInboxNotificationCount = (chat, loggedInUser) => {
  return {
    type: MESSAGING.INBOX_NOTIFICATION_COUNT,
    chat,
    loggedInUser,
  };
};

export const saveChatIdFromNotification = (payload) => {
  return {
    type: MESSAGING.SAVE_CHAT_ID_FROM_NOTIFICATION,
    payload,
  };
};
