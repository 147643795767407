export const Common = {
  loaderDisplay(show) {
    const element = document.getElementsByClassName("lds-ring")[0];
    if (element !== undefined) {
      if (show) {
        element.style.display = "flex";
      } else {
        element.style.display = "none";
      }
    }
  },
  closeLoaderIn3Seconds() {
    setTimeout(() => {
      this.loaderDisplay(false);
    }, 3000);
  },
};

export const nameRegex = /^[a-zA-Z ]*$/;
export const phoneNumberRegex = /^((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}$/;
export const emailRegex =
  /^(([^<>(){}\[\]\\.,;:+'?%#//\s@*=$&!^"]+(\.[^<>(){}\[\]\\.,;:+'?%#//\s@*=$&!^"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,3}))$/;
export const instaHandleRegex = /^[a-zA-Z0-9_@.](?:[a-zA-Z0-9_.]*[a-zA-Z0-9_.])?$/;
export const passwordRegex = /^(?=.{8,}$)(?=.*[a-zA-Z])(?=.*[0-9]).*$/;
export const userNameRegex = /^(?=[a-zA-Z0-9@_])(?!.*[\s])(?!^\d)[\w@]{1,20}$/;

export const getScrollThreshold = () => {
  // Set different scrollThreshold values for mobile and desktop
  if (window.innerWidth < 768) {
    return "400px"; // for mobile
  } else {
    return "200px"; // for desktop
  }
};

export const removeZeroInDecimal = (price) => {
  const priceArr = price.split(".");
  if (priceArr[1] === "00") {
    return priceArr[0];
  }
  return price;
};


export const getCategorySlug = (category) => {
  let categorySlug = "";

  switch (category) {
    case "Top":
      categorySlug = "Shirts-&-Tops";
      break;
    case "Bottoms":
      categorySlug = "Bottoms";
      break;
    case "Dress":
      categorySlug = "Dresses";
      break;
    case "Outerwear":
      categorySlug = "Coats-&-Jackets";
      break;
    case "Suit / Co-ord":
      categorySlug = "Outfit-Sets";
      break;
    case "Jumpsuit":
      categorySlug = "Jumpsuits-&-Rompers";
      break;
    case "Accessories":
      categorySlug = "Clothing-Accessories";
      break;
    default:
      break;
  }

  return categorySlug;
};