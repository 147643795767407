import * as constants from "./../../src/constants/constants";
import { add, sub, format, differenceInMonths, differenceInDays } from "date-fns";

export const getMaxDate = () => {
  return sub(new Date(), {
    years: 13,
  });
};

export const getDateAfterDays = (days, date) => {
  date = date ? new Date(date) : new Date();
  const daysLater = add(date, {
    days: days,
  });
  return getFormatedDate(daysLater);
};

export const getFormatedDate = (date, dateFormat = "yyyy-MM-dd") => {
  return format(new Date(date), dateFormat);
};

export const getDateDifferenceInMonths = (date) => {
  return differenceInMonths(new Date(), new Date(date));
};

export const getDateDifferenceInDays = (date) => {
  return differenceInDays(new Date(), new Date(date));
};

export const getMonthName = (monthNumber) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[monthNumber];
};

export const getDatesBetween = (startDate, endDate) => {
  if (new Date(startDate) >= new Date(endDate)) {
    throw new Error("start date is not smaller than the end date");
  }

  const dates = [];
  let nextDate = getFormatedDate(new Date(startDate));
  while (nextDate <= endDate) {
    dates.push(nextDate);
    nextDate = getDateAfterDays(1, nextDate);
  }

  return dates;
};

export const isDateInBetween = (startDate, endDate, dateToCheck) => {
  const start = Date.parse(startDate);
  const end = Date.parse(endDate);
  const date = Date.parse(dateToCheck);
  return date >= start && date <= end;
};

export const checkDatesClash = (rentAndServiceDates, bookedDates) => {
  let isClashing = false;
  bookedDates.forEach((booking) => {
    let { startDate, endDate } = booking;
    rentAndServiceDates.forEach((date) => {
      if (isDateInBetween(startDate, endDate, date)) {
        isClashing = true; // means date clashes with other reservation
      }
    });
  });
  return isClashing;
};

export const validateImageSize = (imageSize) => {
  return imageSize < constants.MAX_IMAGE_SIZE;
};

export const validateImageCount = (imageCount, maxImages) => {
  return imageCount <= maxImages;
};

export const removeGivenImage = (images, image) => {
  return images.filter((img) => img.id !== image.id);
};

export const TimestampDisplay = (timestamp) => {
  const timestampInMillis = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);

  // Create a Date object from the timestamp
  const date = new Date(timestampInMillis);

  // Get hours and minutes in 24-hour format, with leading zeros
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;

  return formattedTime;
};
