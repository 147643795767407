import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from "prop-types";

const Gallery = (props) => {
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    const list = props?.images?.map((image) => {
      return {
        original: image.src,
        thumbnail: image.src
      }
    });
    setImageList(list);
  }, [props.images]);

  return (
    <div>
      <ImageGallery
        items={imageList}
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
        disableSwipe={false}
      />
    </div>
  );
}

Gallery.propTypes = {
  images: PropTypes.any,
}
export default Gallery;


