import { logErrorToGrayLogs } from "./resources/redux/actions/GrayLogActions";

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals")
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      })
      .catch((err) => {
        logErrorToGrayLogs(
          `function: App.js->onUserDocumantUpdate, platform: Web, client error msg: ${err.stack}`
        );
      });
  }
};

export default reportWebVitals;
