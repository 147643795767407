import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import CardSlider from "../../common/slider/CardSlider";
import InstaFeed from "./InstaFeed";
import SwishedEdit from "./SwishedEdit";
import TrendingBrands from "./TrendingBrands";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as ExploreActions from "../../../redux/actions/ExploreActions";
import PropTypes from "prop-types";

const Exlpore = (props) => {
  let showItem = false;
  useEffect(() => {
    props.ExploreActions.clearSingleCollectionData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="productTilesGridWrapper contentBodyInner">

      <h1 className="metaHeading">
        Affordable, sustainable fashion rentals; rent and buy dresses, outfits at 85% off the retail
        price.
      </h1>
      {showItem && (
        <Container>
          <Row>
            <TrendingBrands />
          </Row>
        </Container>
      )}
      <Container style={{ background: "white" }}>
        <Row>
          <CardSlider />
        </Row>
      </Container>
      <Container>
        <Row>
          <SwishedEdit />
        </Row>
      </Container>

      <Container>
        <InstaFeed />
      </Container>
    </div>
  );
};

const mapStateToProps = ({ explore }) => {
  return explore;
};

const mapDispatchToProps = (dispatch) => {
  return {
    ExploreActions: bindActionCreators(ExploreActions, dispatch),
  };
};

Exlpore.propTypes = {
  ExploreActions: PropTypes.any,
}
export default connect(mapStateToProps, mapDispatchToProps)(Exlpore);
