import React from "react";
import PropTypes from "prop-types";

const ProductDetailBody = ({
  name,
  description,
  size,
  fit,
  condition,
  rent,
  rrp,
  rentalPrice,
  hearticon,
  uploadicon,
  onheartclick,
  onuploadclick,
}) => {
  const savedValue = rrp - rentalPrice;
  return (
    <div className="ProductDetailBodyWrapper">
      <span className="productDetailIcons">
        <i onClick={onheartclick}>{hearticon}</i> <i onClick={onuploadclick}>{uploadicon}</i>
      </span>
      <h1 className="prodName">{name}</h1>
      <p>{description}</p>
      <span>
        Size: <i>{size}</i>
      </span>
      <span>
        Fit: <i>{fit}</i>
      </span>
      <span>
        Condition: <i>{condition}</i>
      </span>
      <span className="rentWrapper">
        {" "}
        Price: <i>&#163;{rent}</i> per day (min 4 days)
      </span>
      {savedValue > 0 ? (
        <span>
          RRP: <i>&#163;{rrp}</i>{" "}
          <i style={{ color: "#ff5a08", fontWeight: "normal" }}>
            Ownish saves you  {
              new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
              }).format(
                savedValue,
              )
            }
          </i>
        </span>
      ) : null}
    </div>
  );
};
ProductDetailBody.propTypes = {
  name: PropTypes.any,
  description: PropTypes.any,
  size: PropTypes.any,
  fit: PropTypes.any,
  condition: PropTypes.any,
  rent: PropTypes.any,
  rrp: PropTypes.any,
  rentalPrice: PropTypes.any,
  hearticon: PropTypes.any,
  uploadicon: PropTypes.any,
  onheartclick: PropTypes.any,
  onuploadclick: PropTypes.any,
}
export default ProductDetailBody;
