import Header from "./resources/components/common/Header";
import ProductTilesGrid from "./resources/components/views/rentAll/ProductTilesGrid";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import * as productActions from "././resources/redux/actions/productActions";
import {
  Exlpore,
  Collections,
  RentFaq,
  TermsOfServices,
  PrivacyPolicy,
  Login,
  Register,
  PaymentSuccessful,
  Checkout,
  ProductDetail,
  ProductSearchGrid,
  DeliveryReturns,
  RefundPolicy,
  ContactUs,
  ChatMessage,
  AllCollections,
  Wishlist,
  UserProfile,
  EditProfile,
  PurchaseGiftCard,
  GiftPaymentSuccessfull,
} from "./resources/components/views/index";
import Footer from "./resources/components/common/Footer";
import NotFound from "./resources/components/views/NotFound";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./resources/components/views/ForgotPassword";
import ResetPassword from "./resources/components/views/ResetPassword";
import Profile from "./resources/components/views/Profile";
import AppLoader from "./resources/components/common/Loader";
import { ToastContainer } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { collection, doc, documentId, onSnapshot, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import * as AppActions from "./resources/redux/actions/AppActions";
import * as MessagingActions from "./resources/redux/actions/messagingActions";
import config from "./resources/axios/config";
import { logErrorToGrayLogs } from "./resources/redux/actions/GrayLogActions";
import SingleCollection from "./resources/components/views/SingleCollection";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PropTypes from "prop-types";

// styling
import "react-hook-consent/dist/styles/style.css";
const queryClient = new QueryClient();

const App = (props) => {
  const { isLoggedIn, userId, email, messaging } = props;
  const dispatch = useDispatch();
  const userCollection = config.REST_API.Messages.userCollection;
  const chatCollection = config.REST_API.Messages.chatCollection;
  const [myChatRoomIds, setMyChatRoomIds] = useState([]);
  const [chatUpdateArray, setChatUpdateArray] = useState([]);
  const getUpdatesInUserCollection = async (docId) => {
    const userRef = doc(collection(db, userCollection), docId);
    onSnapshot(userRef, onUserDocumantUpdate);
  };
  const userEmail = localStorage.getItem("userEmail");

  const onUserDocumantUpdate = async (documentSnapshot) => {
    let chatRoomIds = await documentSnapshot.data()?.chatIds;
    if (!isEmpty(chatRoomIds)) {
      setMyChatRoomIds((prevState) => prevState.concat(chatRoomIds));
      try {
        await getUpdatesInChatCollection(chatRoomIds);
      } catch (error) {
        logErrorToGrayLogs(
          `function: App.js->onUserDocumantUpdate, platform: Web, client error msg: ${error.stack}`
        );
      }
    }
  };

  const getUpdatesInChatCollection = async (ids) => {
    if (!ids?.length) {
      return [];
    }
    const batches = []; // Create an array to store the promises
    while (ids.length) {
      const batch = ids.splice(0, 30);
      const q = query(collection(db, chatCollection), where(documentId(), "in", [...batch]));
      onSnapshot(q, (snapshot) => {
        setChatUpdateArray((prevSnapshot) => [...prevSnapshot, snapshot]);
      });
    }
    return Promise.all(batches).then(() => {
      // Do something after all data is fetched
    });
  };

  const handleAddedChange = (change, existingChatIds, chatRooms) => {
    if (!existingChatIds.includes(change.doc.id)) {
      const chatId = change.doc.id;
      const data = change.doc.data();
      chatRooms.push({ chatId, ...data });
    }
  };

  const handleChatChange = (documentSnapshot) => {
    let { chatRooms } = messaging;
    let isDirty = false;
    const existingChatIds = chatRooms.map((chat) => chat.chatId);
    documentSnapshot?.forEach((element) => {
      element?.docChanges().forEach(function (change) {
        if (change.oldIndex !== 0) {
          isDirty = true;
        }
        if (change.type === "added") {
          handleAddedChange(change, existingChatIds, chatRooms);
        }
        if (change.type === "modified") {
          let isDocFound = false;
          const updatedChats = messaging.chatRooms.map((chatRoom) => {
            if (chatRoom.chatId === change.doc.id) {
              isDocFound = true;
              return {
                ...chatRoom,
                messages: change.doc.data().messages, // update messages array
                unseenMessageCount: change.doc.data().unseenMessageCount, // update unseendMessages array
              };
            }
            return chatRoom;
          });

          if (isDocFound) {
            chatRooms = updatedChats;
            isDirty = true;
          } else {
            chatRooms = [change.doc.data(), ...messaging.chatRooms];
            isDirty = true;
          }
        }
      });
    });
    if (isDirty) {
      dispatch(props.MessagingActions.setChatRooms(chatRooms));
      dispatch(props.MessagingActions.setInboxNotificationCount(chatRooms, { email, userId }));
    }
  };

  useEffect(() => {
    if (!isEmpty(myChatRoomIds)) {
      dispatch(props.MessagingActions.setChatIds(myChatRoomIds));
    }
  }, [myChatRoomIds]);

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        if (isEmpty(messaging.chatRooms)) {
          try {
            await getUpdatesInUserCollection(email || userEmail);
          } catch (error) {
            logErrorToGrayLogs(
              `function: App.js->getUpdatesInUserCollection, platform: Web, client error msg: ${error.stack}`
            );
          }
        }
      } else {
        setChatUpdateArray([]);
      }
    };

    fetchData();
  }, [isLoggedIn]);
  useEffect(() => {
    if (!isEmpty(chatUpdateArray) && isLoggedIn) {
      handleChatChange(chatUpdateArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUpdateArray, setChatUpdateArray]);

  const location = useLocation()
  useEffect(() => {
    const name = location.pathname
      .split('/')
      .map(word => {
        if (word.includes('-')) {
          return word
            .split('-')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join(' ');
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
      })
      .join(' ');
    document.title = `Ownish - ${name}`

  }, [location.pathname]);


  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div>
          <Header />
          <div className="bodyContent">
            <Routes>
              <Route exact path="/" element={<Navigate to="/explore" replace />} />

              <Route exact path="/allrentals" element={<ProductTilesGrid key="allrentals" />} />

              <Route
                exact
                path="/resale"
                element={<ProductTilesGrid isResale={true} key="resale" />}
              />
              <Route exact path="/explore" element={<Exlpore />} />
              <Route exact path="/collections" element={<Collections />} />
              <Route exact path="/all-collections" element={<AllCollections />} />
              <Route exact path="/how-it-works" element={<RentFaq />} />
              <Route exact path="/profile" element={<UserProfile />} />
              <Route exact path="/edit" element={<EditProfile />} />
              <Route exact path="/refund-policy" element={<RefundPolicy />} />
              <Route exact path="/delivery-returns" element={<DeliveryReturns />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/terms-of-services" element={<TermsOfServices />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/search" element={<ProductSearchGrid />} />
              <Route exact path="/collection/:id" element={<SingleCollection />} />
              <Route exact path="/forgotpassword" element={<ForgotPassword />} />
              <Route exact path="/resetpassword" element={<ResetPassword />} />
              <Route exact path="/products/:id" element={<ProductDetail />} />
              <Route exact path="/product-detail/:url" element={<ProductDetail />} />
              <Route exact path="*" element={<NotFound />} />
              <Route exact path="/userprofile/:id" element={<Profile />} />
              <Route exact path="/checkout" element={isLoggedIn ? <Checkout /> : <Login />} />
              <Route exact path="/payment-successful" element={<PaymentSuccessful />} />
              <Route exact path="/inbox" element={isLoggedIn ? <ChatMessage /> : <Login />} />
              <Route exact path="/wishlist" element={isLoggedIn ? <Wishlist /> : <Login />} />
              <Route exact path="/gift-cards" element={<PurchaseGiftCard />} />
              <Route
                exact
                path="/gift-cards/payment-successful"
                element={<GiftPaymentSuccessfull />}
              />
            </Routes>
          </div>
          <ToastContainer autoClose={2000} />
          <Footer />
        </div>
      </QueryClientProvider>
      <AppLoader display="none" />
    </>
  );
};

const mapStateToProps = ({ app, messaging }) => ({
  isLoggedIn: app.isLoggedIn,
  userId: app.userId,
  email: app.email,
  messaging: messaging,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AppActions: bindActionCreators(AppActions, dispatch),
    MessagingActions: bindActionCreators(MessagingActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
  };
};

App.propTypes = {
  isLoggedIn: PropTypes.bool,
  userId: PropTypes.string,
  email: PropTypes.string,
  messaging: PropTypes.any,
  MessagingActions: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
