import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FloatingLabelInput from "../common/FloatingLabelInput";
import AppButton from "../common/AppButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AppActions from "../../redux/actions/AppActions";
import { logErrorToGrayLogs } from "../../redux/actions/GrayLogActions";
import { sendAmplitudeData } from "../../../utils/amplitude";
import PropTypes from "prop-types";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [forgotPasswordError, setForgotPasswordError] = useState(false);
  const [state, setState] = useState({
    email: "",
  });

  useEffect(() => {
    sendAmplitudeData("Forgot Password", {
      Event: 'User views "forgot password" Screen',
    });
  }, []);

  const onForgotPasswordClick = () => {
    if (!state.email) {
      setForgotPasswordError((state) => ({ ...state, email: { message: "Email is required" } }));
    } else {
      props.AppActions.forgotPassword(state.email).then((response) => {
        setForgotPasswordError("");
        if (!response) {
          setForgotPasswordError((state) => ({
            ...state,
            email: { message: "There isn't a Ownish account registered with this email address" },
          }));
          logErrorToGrayLogs(
            `function: ForgotPassword, Entered Values : Entered email is ${state.email}, platform: Web, client error msg: There isn't a Ownish account registered with this email address`
          );
          sendAmplitudeData("Forgot Password-Enters Email-Fail", {
            "Event": "User enters their email address and clicks reset password",
            "Error message ": "There isn't a Ownish account registered with this email address",
            "Email": state.email,
          });
        } else {
          navigate("/resetpassword", { state: { email: state.email } });
        }
      });

      sendAmplitudeData("Forgot Password-Enters Email", {
        Event: "User enters their email address and clicks reset password",
        Email: state.email,
      });
    }
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  return (
    <div className="signinWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="loginWrapper">
              <h3>Forgot Password</h3>
              <FloatingLabelInput
                type="text"
                label="Email"
                name="email"
                value={state.email}
                onchange={handleChange}
                autoComplete="off"
                error=""
              />
              <p className="formErrorMsg">{forgotPasswordError.email?.message}</p>
              <span className="appBtnWrapperInner">
                <AppButton text="Send code" onclick={() => onForgotPasswordClick()} />
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return { AppActions: bindActionCreators(AppActions, dispatch) };
};

ForgotPassword.propTypes = {
  AppActions: PropTypes.any,
}
export default connect(null, mapDispatchToProps)(ForgotPassword);
