import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CollectionsTile = ({ picture, title, onclick }) => {
  return (
    <Link to={onclick} style={{ textDecoration: "none", color: "black" }}>
      <div className="productTileWrapper">
        <span className="dressPicture" style={{ backgroundImage: `url(${picture})` }} />
        <span className="productTitle pt-2">{title}</span>
      </div>
    </Link>
  );
};

CollectionsTile.propTypes = {
  picture: PropTypes.any,
  title: PropTypes.any,
  onclick: PropTypes.any
};

export default CollectionsTile;
