import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { computeHash, generateRandomString } from "../../../../utils/generateHash";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import PropTypes from "prop-types";

const ReSendOTP = ({ AppActions, userId }) => {
  const [isSending, setIsSending] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  // Function to send OTP
  const sendOTP = () => {
    const hashValue = generateRandomString();
    const hash = computeHash(hashValue);

    let dataObj = {
      userId,
      hash,
      value: hashValue,
    };
    // API call to send OTP goes here
    AppActions.resendOTPCode(dataObj).then((response) => {
      console.log("resend otp", response);
      if (response?.data) {
        toast.success("OTP sent successfully!", {
          className: "toastSuccess",
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(response.message ? response.message : "Something went wrong", {
          className: "toastError",
          position: toast.POSITION.TOP_CENTER,
        });
        logErrorToGrayLogs(`function: ResendOTP, platform: Web, Entered Values : userId ${userId}`);
      }
    });

    // Set a timer if you want to disable the resend button for a while
    setTimeRemaining(60);
  };

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timeRemaining]);

  // This function is called when the user wants to resend the OTP
  const resendOTP = () => {
    if (isSending) return;
    setIsSending(true);
    sendOTP();
    setIsSending(false);
  };

  return (
    <div>
      {timeRemaining > 0 ? (
        <p
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: "500",
            textAlign: "right",
            marginBottom: "1rem",
            cursor: "pointer",
            textUnderlineOffset: "1px",
          }}
        >
          Resend code in {timeRemaining} seconds
        </p>
      ) : (
        <p
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: "500",
            textAlign: "right",
            marginBottom: "1rem",
            cursor: "pointer",
            textUnderlineOffset: "1px",
          }}
          onClick={resendOTP}
        >
          Resend code
        </p>
      )}
    </div>
  );
};

ReSendOTP.propTypes = {
  AppActions: PropTypes.any,
  userId: PropTypes.any,
}
export default ReSendOTP;
