import * as types from "./../actionType";

const INITIAL_STATE = {
  selectedCategories: null,
  selectedColors: null,
  selectedSizes: null,
  selectedSorting: "Position",
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case types.FILTERS_ACTIONS.SET_CATEGORIES: {
      return {
        ...state,
        selectedCategories: action.payload,
      };
    }
    case types.FILTERS_ACTIONS.SET_COLORS: {
      return {
        ...state,
        selectedColors: action.payload,
      };
    }
    case types.FILTERS_ACTIONS.SET_SIZES: {
      return {
        ...state,
        selectedSizes: action.payload,
      };
    }
    case types.FILTERS_ACTIONS.SET_SORT: {
      return {
        ...state,
        selectedSorting: action.payload,
      };
    }
    case types.FILTERS_ACTIONS.CLEAR_FILTERS: {
      return {
        selectedCategories: [],
        selectedColors: [],
        selectedSizes: [],
        selectedSorting: "",
      };
    }
    default:
      return state;
  }
};
