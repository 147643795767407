import axios from 'axios';
import Config from './../../axios/config'
import { APP_ACTIONS } from '../actionType';
import { logErrorToGrayLogs } from "./GrayLogActions";

const { REST_API } = Config;
export const getInstaAccessToken = () => (dispatch) => {
    return axios.get(REST_API.AppActions.GetInstaAccessToken,
        { headers: { 'Content-Type': 'application/json; charset=utf-8' } })
        .then((response) => {
            const baseModel = response.data;
            dispatch({
                type: APP_ACTIONS.INSTA_ACCESSTOKEN,
                payload: baseModel.data
            });
            return baseModel;
        })
        .catch((error) => {
            logErrorToGrayLogs(
                `function: getInstaAccessToken, platform: Web, client error msg: ${error.message}`,
            );
            return {
                success: false,
            };
        });
}