import * as types from "./../actionType";

const INITIAL_STATE = {
  heroProducts: [],
  collections: [],
  singleCollection: [],
  loading: false,
  productsFilters: null,
  hasMoreData: false,
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case types.EXPLORE_ACTIONS.GET_PRODUCTS_ON_SCROLL: {
      return {
        ...state,
        singleCollection: action.payload
          ? state.singleCollection.concat(action.payload.data)
          : state.singleCollection,
        hasMoreData: action.payload ? action.payload.hasMore : false,
      };
    }

    case types.EXPLORE_ACTIONS.HERO_CAROUSEL_PRODUCTS: {
      return {
        ...state,
        heroProducts: action.products,
      };
    }

    case types.EXPLORE_ACTIONS.COLLECTIONS: {
      return {
        ...state,
        collections: action.collections,
      };
    }

    case types.EXPLORE_ACTIONS.SINGLE_COLLECTION: {
      return {
        ...state,
        singleCollection: action.payload?.data?.length > 0 ? action.payload.data : [],
        hasMoreData: action.payload ? action.payload.hasMore : false,
      };
    }

    case types.EXPLORE_ACTIONS.CLEAR_SINGLE_COLLECTION_DATA: {
      return {
        ...state,
        singleCollection: [],
      };
    }

    case types.PRODUCT_ACTIONS.SET_FILTERS: {
      return {
        ...state,
        productsFilters: action.payload,
      };
    }

    default:
      return state;
  }
};
