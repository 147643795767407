import * as actionType from '../actionType';

const INITIAL_STATE = {
  searchString: '',
  cloudSearchResult: [],
  hasMoreData: true,
  params: {
    start: 0,
    size: 20,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case actionType.SEARCH_ACTIONS.SET_SEARCH: {
      //override products if first page, append otherwise
      const updatedSearchList =
        state.params.start === 0
          ? action.payload
          : [...state.cloudSearchResult, ...action.payload];
      return {
        ...state,
        cloudSearchResult: updatedSearchList,
      };
    }
    case actionType.SEARCH_ACTIONS.SET_HAS_MORE_SEARCH_DATA:
      return {
        ...state,
        hasMoreData: action.payload,
      };
    case actionType.SEARCH_ACTIONS.SET_SEARCH_PARAMS:
      return {
        ...state,
        params: { ...action.payload },
      };
    case actionType.SEARCH_ACTIONS.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };
    case actionType.SEARCH_ACTIONS.RESET_CLOUD_SEARCH_STATE:
      return {
        ...state,
        cloudSearchResult: [],
        hasMoreData: true,
        params: {
          start: 0,
          size: 20,
        },
      };
    default:
      return state;
  }
};
