import CryptoJS from "crypto-js";

const EncryptionKey = "eY^aF8Q2oM#0kRsC3TbN*6xgP1wDhGzJlV@9qU5rS4nX7uWpE!iB$dLcYvHjKfA&mO";

export const computeHash = (input) => {
  const hash = CryptoJS.HmacSHA256(input, EncryptionKey);
  return hash.toString(CryptoJS.enc.Base64);
};

export const generateRandomString = () => {
  return Math.random().toString(36).substring(2, 15);
};
